import { useState, useEffect, useRef, useLayoutEffect, createRef } from 'react';
import { TimePicker, Form, message, Row, Col, Grid, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import AutoComplete from 'react-google-autocomplete';
import moment from 'moment';
import Pdf from 'react-to-pdf';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

import { ClinicInput } from '../../commonComponents/ClinicInputField/ClinicInput';
import { patchApiWithAuth, getApiWithAuth } from '../../../utils/api';
import { Url } from '../../../utils/apiUrls';
import { ClinicButton } from '../../commonComponents';
import { useSnackbar } from 'notistack';
import ClinicUploadBox from '../../commonComponents/ClinicUploadBox';
import { useTranslation } from 'react-i18next';
import { disabledDateTime, nameResolver } from '../../../utils/helper';
import QRImage from '../../../assets/images/qrcode.png';
import useUser from '../../../hooks/useUser';
import QRDownload from '../QRDownload/QRDownload';

import './ClinicSettingStyle.css';
import i18next from 'i18next';

const format = 'HH:mm';
const options = {
    orientation: 'portrait',
    unit: 'in',
    format: [7, 10],
};

const letterRegix = /^[A-Za-z0-9ÅÄÖåäö ]*[A-Za-z0-9ÅÄÖåäö][A-Za-z0-9ÅÄÖåäö ]*$/;
const linkRegex =
    /^(https:\/\/)([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
const numberRegix = /^[0-9\b]+$/;
const emailRegex =
    /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
const alphabetsRegex = /^[A-Za-z ]+$/;

const { useBreakpoint } = Grid;

const ClinicSetting = () => {
    const navigate = useNavigate();
    const { state, pathname } = useLocation();
    const { t } = useTranslation();
    const { getUser, setUserData: setPayload, userData: payload } = useUser();
    const { enqueueSnackbar } = useSnackbar();

    const screens = useBreakpoint();

    const ref = createRef();
    const pageRef = useRef(null);
    const downloadRef = useRef(null);

    const [form] = Form.useForm();
    const [clinicData, setClinicData] = useState({});
    const [isTouched, setIsTouched] = useState(true);
    const [errorText, setErrorText] = useState('');
    const [errorText2, seterrorText2] = useState('');
    const [errorText3, seterrorText3] = useState(false);
    const [errorText4, seterrorText4] = useState('');
    const [errorText5, seterrorText5] = useState('');
    const [errorText6, seterrorText6] = useState('');
    const [errorText7, seterrorText7] = useState('');
    const [errorText8, seterrorText8] = useState('');
    const [pdfViewDisplay, setPdfViewDisplay] = useState('none');
    const [imageBlob, setImageBlob] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [imageArray, setImageArray] = useState([]);
    const [hasProfile, setHasProfile] = useState(false);
    const [userData, setUserData] = useState({
        company_name: '',
        profile: null,
        role: 'clinic',
        email: '',
        phone_number: '',
        home_page_link: '',
        city: '',
        postal_code: '',
        company_address: '',
        deal_image: '',
        about: '',
        patients_count: null,
        weekdays: [
            {
                weekday: 'MON',
                from_hour: '',
                to_hour: '',
            },
            {
                weekday: 'TUE',
                from_hour: '',
                to_hour: '',
            },
            {
                weekday: 'WED',
                from_hour: '',
                to_hour: '',
            },
            {
                weekday: 'THU',
                from_hour: '',
                to_hour: '',
            },
            {
                weekday: 'FRI',
                from_hour: '',
                to_hour: '',
            },
            {
                weekday: 'SAT',
                from_hour: '',
                to_hour: '',
            },
            {
                weekday: 'SUN',
                from_hour: '',
                to_hour: '',
            },
        ],
        qr_image: '',
    });

    const weekdayNames = [
        t('mon'),
        t('tue'),
        t('wed'),
        t('thu'),
        t('fri'),
        t('sat'),
        t('sun'),
    ];

    const handleCheckboxChange = (event, weekday) => {
        setUserData((prevUserData) => {
            const updatedWeekdays = [...prevUserData.weekdays];
            updatedWeekdays[weekday - 1]['weekday_status'] =
                event.target.checked;
            return {
                ...prevUserData,
                weekdays: updatedWeekdays,
            };
        });
        setClinicData((prevUserData) => {
            const updatedWeekdays = [...prevUserData.clinic_timings];
            updatedWeekdays[weekday - 1]['weekday_status'] =
                event.target.checked;
            return {
                ...prevUserData,
                clinic_timings: updatedWeekdays,
            };
        });
    };

    const formatTime = (time) => moment(time).format('HH:mm a');

    const handleOnChange = (event) => {
        const { name, value } = event.target;
        setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: value,
        }));
        setClinicData((prevUserData) => ({
            ...prevUserData,
            [name]: value,
        }));
        if (event.target.name === 'email') {
            if (event.target.value.match(emailRegex)) {
                setErrorText('');
            } else {
                setErrorText('invalidformat');
            }
        } else if (event.target.name === 'phone_number') {
            if (event.target.value.match(numberRegix)) {
                seterrorText7('');
            } else {
                seterrorText7('numberError');
            }
        } else if (event.target.name === 'city') {
            if (event.target.value.match(alphabetsRegex)) {
                seterrorText6('');
            } else {
                seterrorText6('nameError');
            }
        } else if (event.target.name === 'company_name') {
            if (event.target.value.match(letterRegix)) {
                seterrorText2('');
            } else {
                seterrorText2('error');
            }
        } else if (event.target.name === 'postal_code') {
            if (event.target.value.match(numberRegix)) {
                seterrorText4('');
            } else {
                seterrorText4('numberError');
            }
        } else if (event.target.name === 'home_page_link') {
            if (event.target.value.match(linkRegex)) {
                seterrorText5('');
            } else {
                seterrorText5(t('hyperLinkError'));
            }
        } else if (event.target.name === 'patients_count') {
            if (event.target.value.match(numberRegix)) {
                seterrorText8('');
            } else {
                seterrorText8('numberError');
            }
        }
    };

    const getData = async () => {
        setIsLoading(true);
        const response = await getApiWithAuth(Url.ME_URL);
        if (response.success) {
            setUserData((prevState) => {
                return {
                    ...prevState,
                    profile: response.data.profile,
                    city: response.data.city,
                    company_name: response.data.company_name,
                    email: response.data.email,
                    phone_number: response.data.phone_number,
                    postal_code: response.data.postal_code,
                    clinic_latitude: response.data.clinic_latitude,
                    clinic_longitude: response.data.clinic_longitude,
                    weekdays: response.data.clinic_timings,
                    qr_image: response.data.qr_image,
                    company_address: response.data.company_address,
                    about: response.data.about,
                    home_page_link: response.data.home_page_link,
                    patients_count: response.data.patients_count,
                };
            });
            setClinicData((prevState) => {
                return {
                    ...prevState,
                    clinic_timings: response.data.clinic_timings,
                };
            });

            if (response.data.profile) {
                setImageArray([
                    {
                        name: 'profile_image.png',
                    },
                ]);
                setHasProfile(true);
            }

            setIsLoading(false);
        }
    };

    const updateData = async (isAllowToMove = true, payload) => {
        setIsLoading((pre) => true);
        let clinic_timings = clinicData?.clinic_timings?.map((item) => {
            let today = moment();
            return {
                ...item,
                from_hour: moment(
                    today.format('YYYY-MM-DD') + ' ' + item.from_hour,
                    'YYYY-MM-DD hh:mm A',
                ).format('hh:mm A'),
                to_hour: moment(
                    today.format('YYYY-MM-DD') + ' ' + item.to_hour,
                    'YYYY-MM-DD hh:mm A',
                ).format('hh:mm A'),
            };
        });

        const response = await patchApiWithAuth(
            Url.UPDATE_URL,
            isAllowToMove ? { ...clinicData, clinic_timings } : payload,
        );
        if (response.success) {
            enqueueSnackbar(t('updateClinic'), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'success',
            });
            // setIsLoading(false);
            if (isAllowToMove) {
                navigate('/dashboard');
                await getData();
                getUser();
            } else {
                window.location.reload();
            }
        } else {
            console.log(response);
            // enqueueSnackbar(response?.message?.data?.error, {
            //     anchorOrigin: {
            //         horizontal: 'right',
            //         vertical: 'top',
            //     },
            //     variant: 'error',
            // });
            setIsLoading(false);
        }
    };

    const handleWeekdayChange = (index, field, value) => {
        setUserData((prevUserData) => {
            const updatedWeekdays = [...prevUserData.weekdays];
            updatedWeekdays[index][field] = value;
            return {
                ...prevUserData,
                weekdays: updatedWeekdays,
            };
        });

        setClinicData((prevState) => {
            return {
                ...prevState,
                clinic_timings: userData.weekdays,
            };
        });
    };

    const selectPlace = (place) => {
        if (place && place.geometry) {
            setUserData((prev) => ({
                ...prev,
                clinic_longitude: place.geometry.location.lng(),
                clinic_latitude: place.geometry.location.lat(),
                company_address: place.formatted_address,
            }));
            setClinicData({
                ...clinicData,
                clinic_longitude: parseFloat(
                    place.geometry.location.lng(),
                ).toFixed(8),
                clinic_latitude: parseFloat(
                    place.geometry.location.lat(),
                ).toFixed(8),
                company_address: place.formatted_address,
            });
            setIsTouched(true);
        }
    };

    const onChangeImage = (info) => {
        try {
            const image = new Image();
            const reader = new FileReader();
            const { file, fileList } = info;
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                const fileSizeInBytes = fileList[0]?.size;

                if (fileSizeInBytes > 1048576 * 2) {
                    message.error(t('imageUploadingError'));
                } else {
                    setImageArray(fileList);
                    reader.onloadend = () => {
                        setUserData({ ...userData, profile: reader.result });
                        setClinicData((prevUserData) => ({
                            ...prevUserData,
                            profile: reader.result,
                        }));
                    };
                    reader.readAsDataURL(file);
                }
            };
        } catch (error) {
            console.log('error', error);
        }
    };

    const onRemoveImage = async () => {
        if (hasProfile) {
            setHasProfile(false);
            await updateData(false, { profile: '' });
        }
        setImageArray([]);
    };

    useLayoutEffect(() => {
        let lng = localStorage.getItem('lng');
        if (lng) i18next.changeLanguage(lng);
        getData();
    }, []);

    useEffect(() => {
        form.setFieldsValue(userData);
    }, [form, userData]);

    useEffect(() => {
        if (isLoading) return;
        if (state && state?.download) {
            downloadRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        } else {
            pageRef.current?.focus();
            window.scrollTo(0, 0);
        }
    }, [state, pathname, isLoading]);

    return (
        <main ref={pageRef} tabIndex={state?.download ? '-1' : '0'}>
            {isLoading ? (
                <div className="spinnerDiv">
                    <Spin size="large" />
                </div>
            ) : (
                <div
                    className={
                        screens.lg
                            ? 'clinicSetingContainer-lg-view'
                            : 'clinicSetingContainer'
                    }
                >
                    <div className="subMainContainerSetting">
                        <h1 className="headingSettings">{t('setting')}</h1>
                        <h1 className="subHeadingSettings">
                            {t('updateClinicSetting')}
                        </h1>
                    </div>
                    <div className="hrLine">
                        <hr className="styleLine" />
                    </div>
                    <div>
                        <Form
                            layout="vertical"
                            onFinish={updateData}
                            form={form}
                            autoComplete="off"
                            initialValues={userData}
                            className="inputSettings"
                        >
                            <Row
                                gutter={[screens.xs ? 0 : 32, 16]}
                                justify="center"
                            >
                                <Col xs={24} md={12} lg={12}>
                                    <div>
                                        <label className="labelSettings">
                                            {t('clicName')}
                                        </label>
                                        <ClinicInput
                                            type="text"
                                            name="company_name"
                                            placeholder={t(
                                                'clicNamePlaceholder',
                                            )}
                                            Value={userData.company_name}
                                            onChange={handleOnChange}
                                            maxLength={30}
                                        />
                                        <div className="errorStyleSetting errorMarginSettings">
                                            {t(errorText2)}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <div>
                                        <label className="labelSettings">
                                            {t('yourAddress')}
                                        </label>
                                        <div className="settingAddressInput">
                                            <AutoComplete
                                                className="adressAutoComplete clinicsetting-address"
                                                apiKey={
                                                    process.env
                                                        .REACT_APP_GOOGLE_API
                                                }
                                                onPlaceSelected={selectPlace}
                                                defaultValue={
                                                    userData.company_address
                                                }
                                                options={{
                                                    types: [
                                                        'geocode',
                                                        'establishment',
                                                    ],
                                                }}
                                                placeholder={t(
                                                    'yourAddressPlaceholder',
                                                )}
                                                onChange={(e) => {
                                                    setUserData(
                                                        (preUserData) => ({
                                                            ...preUserData,
                                                            company_address:
                                                                e.target.value,
                                                        }),
                                                    );
                                                    setIsTouched(false);
                                                    if (e.target.value === '')
                                                        seterrorText3(true);
                                                    else seterrorText3(false);
                                                }}
                                            />
                                        </div>

                                        <span className="errorStyleSetting errorMarginSettings">
                                            {(errorText3 || !isTouched) &&
                                                t('autoCompleteError')}
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <div>
                                        <label className="labelSettings">
                                            {t('postalCode')}
                                        </label>
                                        <ClinicInput
                                            type={'text'}
                                            placeholder={t('postalCode')}
                                            name={'postal_code'}
                                            Value={userData.postal_code}
                                            onChange={handleOnChange}
                                        />
                                        <div className="errorStyleSetting errorMarginSettings">
                                            {t(errorText4)}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <div>
                                        <label className="labelSettings">{`${t(
                                            'city',
                                        )}`}</label>
                                        <ClinicInput
                                            type={'text'}
                                            placeholder={t('cityPlaceholder')}
                                            name={'city'}
                                            Value={userData.city}
                                            onChange={handleOnChange}
                                        />
                                        <div className="errorStyleSetting errorMarginSettings">
                                            {t(errorText6)}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <div>
                                        <label className="labelSettings">
                                            {t('phoneNumber')}
                                        </label>
                                        <ClinicInput
                                            type={'text'}
                                            placeholder={t(
                                                'phoneNumberPlaceholder',
                                            )}
                                            name={'phone_number'}
                                            Value={userData.phone_number}
                                            onChange={handleOnChange}
                                        />
                                        <div className="errorStyleSetting errorMarginSettings">
                                            {t(errorText7)}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <div>
                                        <label className="labelSettings">
                                            {t('eAddress')}
                                        </label>
                                        <ClinicInput
                                            type={'text'}
                                            placeholder={'Email Address'}
                                            name={'email'}
                                            disabled
                                            Value={userData.email}
                                            onChange={handleOnChange}
                                        />
                                        <div className="errorStyleSignup errorMarginSettings">
                                            {' '}
                                            {t(errorText)}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <div>
                                        <label className="labelSettings">
                                            {t('homePage')}
                                        </label>
                                        <ClinicInput
                                            type={'text'}
                                            placeholder={`${t(
                                                'homePagePlaceholer',
                                            )}`}
                                            name={'home_page_link'}
                                            Value={userData.home_page_link}
                                            onChange={handleOnChange}
                                        />
                                        <div className="errorStyleSetting errorMarginSettings">
                                            {errorText5 && t('hyperLinkError')}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <div>
                                        <label className="labelSettings">
                                            {t('noOfPatient')}
                                        </label>
                                        <ClinicInput
                                            type={'text'}
                                            placeholder={`${t('noOfPatient')}`}
                                            name={'patients_count'}
                                            Value={userData.patients_count}
                                            onChange={handleOnChange}
                                        />
                                        <div className="errorStyleSetting errorMarginSettings">
                                            {' '}
                                            {t(errorText8)}
                                        </div>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className="mainsetDot">
                                        <div className="yourname-text">
                                            <div className="yourname-title">
                                                {t('image')}
                                            </div>
                                            <div className="yourname-details">
                                                {t('imageDisplay')}
                                            </div>
                                        </div>
                                        <div className="doted">
                                            <ClinicUploadBox
                                                onChange={onChangeImage}
                                                onRemove={onRemoveImage}
                                                height={174}
                                                imageArray={imageArray}
                                                name="deal_image"
                                            />
                                        </div>
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <div>
                                        <span className="textStyleAbout">
                                            {t('about')}
                                        </span>{' '}
                                        <span className="textStyleAboutGos">
                                            {t('Cli')}
                                        </span>
                                    </div>
                                    <div className="textAreaContainer">
                                        <div className="aboutClinicText">
                                            <div className="textAreaWrapper">
                                                <ClinicInput
                                                    type={'textarea'}
                                                    className="textAreaDeal"
                                                    name={'about'}
                                                    onChange={handleOnChange}
                                                    placeholder={t(
                                                        'dealAboutPlace',
                                                    )}
                                                    maxLength={500}
                                                    width={100}
                                                    borderWidth={0}
                                                    textAreaMaxRow={6}
                                                    height={500}
                                                />
                                            </div>
                                        </div>
                                        <div className="errorStyleTextArea errorMarginSettings">
                                            {t('maxNumberOfChar')}
                                        </div>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div>
                                        <div className="timingContainer">
                                            <h1 className="clinicTimimgs">
                                                {t('cliTime')}
                                            </h1>
                                        </div>
                                        <Row>
                                            {userData?.weekdays?.map(
                                                (weekday, index) => (
                                                    <Col span={24} key={index}>
                                                        <div className="timeFieldWrapper">
                                                            <Row
                                                                gutter={[
                                                                    16, 16,
                                                                ]}
                                                                align={'middle'}
                                                            >
                                                                <Col
                                                                    xs={3}
                                                                    md={3}
                                                                    lg={1}
                                                                >
                                                                    <>
                                                                        <div className="outerCheck">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="checkbox"
                                                                                checked={
                                                                                    weekday?.weekday_status
                                                                                }
                                                                                onChange={(
                                                                                    e,
                                                                                ) =>
                                                                                    handleCheckboxChange(
                                                                                        e,
                                                                                        weekday?.weekday,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </>
                                                                </Col>
                                                                <Col
                                                                    xs={3}
                                                                    md={5}
                                                                    lg={2}
                                                                >
                                                                    <h1 className="weekDays">
                                                                        {
                                                                            weekdayNames[
                                                                                index
                                                                            ]
                                                                        }
                                                                    </h1>
                                                                </Col>
                                                                <Col
                                                                    xs={24}
                                                                    md={5}
                                                                    lg={3}
                                                                >
                                                                    <TimePicker
                                                                        className="time"
                                                                        value={moment(
                                                                            weekday?.from_hour,
                                                                            [
                                                                                'h:mm A',
                                                                            ],
                                                                        )}
                                                                        minuteStep={
                                                                            30
                                                                        }
                                                                        format={
                                                                            format
                                                                        }
                                                                        onSelect={(
                                                                            value,
                                                                        ) => {
                                                                            handleWeekdayChange(
                                                                                index,
                                                                                'from_hour',
                                                                                formatTime(
                                                                                    value,
                                                                                ),
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            weekday?.weekday_status
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        showNow={
                                                                            false
                                                                        }
                                                                    />
                                                                </Col>
                                                                <h6 className="lineDash">
                                                                    ---
                                                                </h6>
                                                                <Col
                                                                    xs={24}
                                                                    md={5}
                                                                    lg={3}
                                                                >
                                                                    <TimePicker
                                                                        className="time"
                                                                        minuteStep={
                                                                            30
                                                                        }
                                                                        value={moment(
                                                                            weekday?.to_hour,
                                                                            [
                                                                                'h:mm A',
                                                                            ],
                                                                        )}
                                                                        format={
                                                                            format
                                                                        }
                                                                        onSelect={(
                                                                            value,
                                                                        ) => {
                                                                            handleWeekdayChange(
                                                                                index,
                                                                                'to_hour',
                                                                                formatTime(
                                                                                    value,
                                                                                ),
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            weekday?.weekday_status
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        disabledTime={(
                                                                            current,
                                                                        ) => {
                                                                            let StartTime =
                                                                                new Date();

                                                                            let convertInto24 =
                                                                                (
                                                                                    time,
                                                                                ) => ({
                                                                                    hour: moment(
                                                                                        time,
                                                                                        [
                                                                                            'h:mm A',
                                                                                        ],
                                                                                    ).hour(),
                                                                                    min: moment(
                                                                                        time,
                                                                                        [
                                                                                            'h:mm A',
                                                                                        ],
                                                                                    ).minute(),
                                                                                });

                                                                            let timeSpliter =
                                                                                (
                                                                                    time,
                                                                                ) =>
                                                                                    String(
                                                                                        time,
                                                                                    )
                                                                                        ?.split(
                                                                                            ' ',
                                                                                        )[0]
                                                                                        .split(
                                                                                            ':',
                                                                                        );

                                                                            StartTime.setHours(
                                                                                timeSpliter(
                                                                                    weekday?.from_hour,
                                                                                )[0],
                                                                            );
                                                                            StartTime.setMinutes(
                                                                                timeSpliter(
                                                                                    weekday?.from_hour,
                                                                                )[1],
                                                                            );

                                                                            let hours =
                                                                                convertInto24(
                                                                                    weekday?.from_hour,
                                                                                ).hour;

                                                                            let mins =
                                                                                convertInto24(
                                                                                    weekday?.to_hour,
                                                                                )
                                                                                    .hour ===
                                                                                convertInto24(
                                                                                    weekday?.from_hour,
                                                                                )
                                                                                    .hour
                                                                                    ? moment(
                                                                                          StartTime,
                                                                                      ).minute() +
                                                                                      1
                                                                                    : 0;

                                                                            return disabledDateTime(
                                                                                hours,
                                                                                mins,
                                                                            );
                                                                        }}
                                                                        showNow={
                                                                            false
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <div className="timeTable-below-line" />
                                                        </div>
                                                    </Col>
                                                ),
                                            )}
                                        </Row>
                                    </div>
                                </Col>
                                <Col
                                    span={24}
                                    style={{
                                        display: 'flex',
                                        justifyContent: screens.xs
                                            ? 'center'
                                            : 'start',
                                    }}
                                >
                                    <div className="buttonSettings">
                                        <ClinicButton
                                            className="saveButton"
                                            title={t('save')}
                                            height={50}
                                            onClick={updateData}
                                            loading={isLoading}
                                            disabled={
                                                errorText !== '' ||
                                                errorText2 !== '' ||
                                                errorText4 !== '' ||
                                                errorText5 !== '' ||
                                                errorText6 !== '' ||
                                                errorText7 !== '' ||
                                                errorText8 !== '' ||
                                                errorText3 ||
                                                !isTouched
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                        <Row>
                            <Col span={24}>
                                <div className="outerQR">
                                    <div className="qrCode">
                                        <Row
                                            justify={'center'}
                                            align={'middle'}
                                            gutter={[16, 16]}
                                        >
                                            <Col xs={24} md={12} lg={6}>
                                                <div className="inerQR">
                                                    <img
                                                        src={QRImage}
                                                        style={{
                                                            width: '100%',
                                                            height: 'auto',
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={9}>
                                                <div className="qrContent">
                                                    <h1 className="yourCode">
                                                        {t('qr')}
                                                    </h1>
                                                    <p className="displayQR">
                                                        {t('imageDisplay')}
                                                    </p>
                                                    <div className="qrButton">
                                                        {
                                                            <div className="d-flex justify-content-center">
                                                                <div
                                                                    className="qrButton"
                                                                    ref={
                                                                        downloadRef
                                                                    }
                                                                >
                                                                    <PDFDownloadLink
                                                                        document={
                                                                            <QRDownload
                                                                                company_name={
                                                                                    payload.company_name
                                                                                }
                                                                                about={
                                                                                    payload.about
                                                                                }
                                                                                profile={
                                                                                    payload.profile
                                                                                }
                                                                                id={
                                                                                    payload.id
                                                                                }
                                                                            />
                                                                        }
                                                                        fileName={nameResolver(
                                                                            payload.company_name,
                                                                        ).concat(
                                                                            '.pdf',
                                                                        )}
                                                                    >
                                                                        {({
                                                                            blob,
                                                                            url,
                                                                            loading,
                                                                            error,
                                                                        }) => (
                                                                            <ClinicButton
                                                                                height={
                                                                                    screens.xs
                                                                                        ? 40
                                                                                        : 50
                                                                                }
                                                                                title={t(
                                                                                    'down',
                                                                                )}
                                                                                disabled={
                                                                                    loading
                                                                                }
                                                                                loading={
                                                                                    loading
                                                                                }
                                                                            />
                                                                        )}
                                                                    </PDFDownloadLink>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
        </main>
    );
};
export default ClinicSetting;
