import { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Input from 'antd/es/input/Input';
import { Url } from '../../../utils/apiUrls';
import { useTranslation } from 'react-i18next';
import { Images } from '../../../assets/images';
import { getApiWithAuth, postApiWithAuth } from '../../../utils/api';
import { ClockCircleOutlined } from '@ant-design/icons';
import { ClinicButton, ClinicInput } from '../../commonComponents';
import {
    Modal,
    Form,
    DatePicker,
    Checkbox,
    message,
    Row,
    Col,
    Rate,
    Grid,
} from 'antd';
import { useSnackbar } from 'notistack';
import './Deal.css';
import './DealModal.css';
import {
    debounce,
    disabledDateTime,
    discountCalculate,
    pickers,
} from '../../../utils/helper';
import userProfile from '../../../assets/images/profile.webp';
import clock from '../../../assets/images/clock.svg';
import location from '../../../assets/images/location.png';
import useUser from '../../../hooks/useUser';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { useCallback } from 'react';

const googleMapUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API}`;

const letterRegix = /^[a-zA-Z0-9åäöÅÄÖ\s%-/]{1,25}$/;
const numberRegix = /^[0-9\b]+$/;

const initialValue = undefined;

const { useBreakpoint } = Grid;

const format = 'YYYY-MM-DD HH:mm';

const DealModal = ({
    isModalOpen,
    setIsModalOpen,
    setNewCardAdd,
    getListing,
}) => {
    DealModal.propTypes = {
        isModalOpen: PropTypes.bool,
        setIsModalOpen: PropTypes.func,
        setNewCardAdd: PropTypes.func,
        getListing: PropTypes.func,
    };

    DealModal.defaultProps = {
        setIsModalOpen: () => {},
        isModalOpen: false,
        setNewCardAdd: () => {},
    };

    const { t, i18n } = useTranslation();
    const { userData } = useUser();

    const [form] = Form.useForm();
    const { enqueueSnackbar } = useSnackbar();
    const screens = useBreakpoint();

    const startdateRef = useRef();
    const enddateRef = useRef();
    const checkboxRef = useRef();

    const [dealData, setDealData] = useState({
        name: '',
        start_time: initialValue,
        end_time: initialValue,
        price: '',
        about: '',
        clinic_call: false,
        count: '',
        last_min_booking: false,
        discount_percentage: null,
    });

    const [imageArray, setImageArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [warnText, setWarnText] = useState('');
    const [errorTextPrice, setErrorTextPrice] = useState('');
    const [errorTextCount, setErrorTextCount] = useState('');

    const [currentPicker, setCurrentPicker] = useState(pickers[0]);
    const [isOpenStartDate, setIsOpenStartDate] = useState(false);
    const [isOpenEndDate, setIsOpenEndDate] = useState(false);
    const [dashboardData, setDashboardData] = useState();

    const onFinish = async () => {
        setIsLoading(true);

        if (!dealData.discount_percentage) {
            delete dealData.discount_percentage;
        }

        const respose = await postApiWithAuth(Url.CREATE_DEAL, dealData);

        if (respose.success) {
            setIsModalOpen(false);
            enqueueSnackbar(t('createSuccess'), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'success',
            });
            getListing();
        } else {
            setIsLoading(false);
            setNewCardAdd(true);
            enqueueSnackbar(respose.message.data.error, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'error',
            });
        }
    };

    const optimizedFunction = useCallback(debounce(onFinish), [dealData]);

    const onChangeNumber = (event) => {
        const { name, value } = event.target;
        const inputValue = parseInt(value);
        if (!isNaN(inputValue) && numberRegix.test(value) && inputValue > 0) {
            // if (inputValue > 100) setDealData({ ...dealData, [name]: 100 });
            // else setDealData({ ...dealData, [name]: inputValue });
            if (
                (inputValue === 0 && dealData[name] === 1) || // Allow 0 only after 1
                (inputValue >= 1 && inputValue <= 100) // Allow values between 1 and 100
            ) {
                setDealData({ ...dealData, [name]: inputValue });
            } else {
                setDealData({ ...dealData });
            }
        } else {
            if (value === '') setDealData({ ...dealData, [name]: value });
            else setDealData({ ...dealData });
        }
    };

    const onChangeInput = (event) => {
        const { name, value } = event.target;
        if (event.target.name === 'name') {
            if (event.target.value.match(letterRegix)) {
                if (dealData.name.length > 24) {
                    setWarnText(t('specialError'));
                } else {
                    setWarnText('');
                }
                setErrorText('');
            } else {
                setErrorText(t('specialError'));
            }
        }
        if (event.target.name === 'price') {
            if (
                event.target.value.match(numberRegix) &&
                event.target.value.length < 10
            ) {
                setErrorTextPrice('');
            } else {
                setErrorTextPrice(t('dealPriceError'));
            }
        }
        if (name === 'price') {
            if (!isNaN(value)) {
                setDealData({ ...dealData, [name]: value });
            }
        } else {
            setDealData({ ...dealData, [name]: value });
        }
        if (name === 'count') setDealData({ ...dealData, count: value });
    };

    const onChangeDate = (
        name,
        value,
        dateString,
        isMobile = false,
        picker,
    ) => {
        setDealData({ ...dealData, [name]: value ? dateString : '' });

        if (isMobile) {
            if (picker === pickers[0]) {
                setCurrentPicker(pickers[1]);
            } else {
                setIsOpenEndDate(false);
                setIsOpenStartDate(false);
                setCurrentPicker(pickers[0]);
            }
        }

        if (startdateRef.current) {
            startdateRef.current.blur();
        }

        if (enddateRef.current) {
            enddateRef.current.blur();
        }
    };

    const onChangeImage = (info) => {
        const file = info.file;
        const blob = new Blob([file]);

        const reader = new FileReader();

        reader.onloadend = () => {
            setImageArray(info.fileList);
            const base64data = reader.result;
            setDealData({ ...dealData, deal_image: base64data });
        };

        reader.readAsDataURL(blob);
    };

    const onChangeDealNumber = (event) => {
        const { name, value } = event.target;
        const inputValue = parseInt(value);

        if (numberRegix.test(value) && value.length < 10) {
            if (!isNaN(inputValue) && inputValue > 0) {
                setErrorTextCount('');
                setDealData({ ...dealData, [name]: inputValue.toString() });
            } else {
                setErrorTextCount(t('dealCountError'));
                setDealData({ ...dealData });
            }
        } else {
            if (value === '') {
                setErrorTextCount(t('dealCountError'));
                setDealData({ ...dealData, [name]: value });
            } else {
                setDealData({ ...dealData });
            }
        }
    };

    const onChangePrice = (event) => {
        const { name, value } = event.target;
        const inputValue = parseInt(value);

        if (numberRegix.test(value) && value.length < 10) {
            if (!isNaN(inputValue) && inputValue > 0) {
                setDealData({ ...dealData, [name]: inputValue.toString() });
                setErrorTextPrice('');
            } else {
                setErrorTextPrice(t('dealPriceError'));
                setDealData({ ...dealData });
            }
        } else {
            if (value === '') {
                setErrorTextPrice(t('dealPriceError'));
                setDealData({ ...dealData, [name]: value });
            } else {
                setDealData({ ...dealData });
            }
        }
    };

    const onChangeName = (event) => {
        const { name, value } = event.target;
        setDealData((preDealData) => {
            if (value.match(letterRegix)) {
                setErrorText('');
            } else {
                setErrorText(t('specialError'));
            }

            if (value.length === 25) {
                setWarnText(t('specialError'));
            } else {
                setWarnText('');
            }
            return { ...preDealData, [name]: value };
        });
    };

    useEffect(() => {
        if (dealData.clinic_call === true)
            setDealData({ ...dealData, start_time: null, end_time: null });
        else setDealData({ ...dealData, start_time: '', end_time: '' });
    }, [dealData.clinic_call]);

    useEffect(() => {
        const getData = async () => {
            const response = await getApiWithAuth(Url.DASHBOARD);
            if (response?.success) {
                setDashboardData(response.data);
            }
        };
        getData();
    }, []);

    const addLineBreaks = (text) => {
        return text.split('\n').map((line) => (
            <>
                {line}
                <br />
            </>
        ));
    };

    return (
        <>
            <div className="modelScroll">
                <Modal
                    open={isModalOpen}
                    footer={false}
                    className="dealModal"
                    onCancel={() => setIsModalOpen(!isModalOpen)}
                    maskClosable={false}
                    centered
                >
                    {/* <div className="modelContantCreate"> */}
                    <h1 className="modalDealHeader">{t('createDeal')}</h1>
                    <hr className="modalDealLine" />
                    <div style={{ margin: '1rem auto' }}>
                        <Form
                            layout="vertical"
                            onFinish={optimizedFunction}
                            form={form}
                            autoComplete="off"
                            fields={[
                                {
                                    name: ['discount_percentage'],
                                    value: dealData.discount_percentage,
                                },
                                {
                                    name: ['count'],
                                    value: dealData.count,
                                },
                                {
                                    name: ['price'],
                                    value: dealData.price,
                                },
                            ]}
                        >
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <div className="inputModalText">
                                        <ClinicInput
                                            placeholder={t('dealName')}
                                            type="text"
                                            height={30}
                                            name="name"
                                            onChange={onChangeName}
                                            value={dealData.name}
                                            maxLength={25}
                                            required
                                            errorMessage={t('dealNameError')}
                                        />
                                        {errorText || warnText ? (
                                            <div className="errorStyleUpdtee">
                                                {errorText || warnText}
                                            </div>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col xs={24}>
                                    <div className="inputModalText">
                                        <ClinicInput
                                            type={'textarea'}
                                            className="textAreaDeal"
                                            name={'about'}
                                            value={dealData.about}
                                            placeholder={t('dealAboutPlace')}
                                            maxLength={250}
                                            textAreaMaxRow={2}
                                            onChange={onChangeInput}
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <div className="inputModalText">
                                        <ClinicInput
                                            placeholder={t('dealPricePlace')}
                                            height={30}
                                            name="price"
                                            onChange={onChangePrice}
                                            Value={dealData.price}
                                            className="pricrUpdate"
                                            required
                                            errorMessage={t('dealPriceError')}
                                        />
                                        {errorTextPrice !== '' ? (
                                            <div className="errorStylePrice">
                                                {errorTextPrice}
                                            </div>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <div className="inputModalText">
                                        <ClinicInput
                                            placeholder={t('dealDiscountDeal')}
                                            type="text"
                                            height={30}
                                            width={100}
                                            name="discount_percentage"
                                            onChange={onChangeNumber}
                                            disabled={
                                                dealData.price == '' ||
                                                dealData.price < 1
                                            }
                                            Value={
                                                dealData?.discount_percentage
                                            }
                                            className="pricrUpdate"
                                            required
                                            max={100}
                                            errorMessage={t('dealPriceError')}
                                        />

                                        {dealData?.discount_percentage > 0 && (
                                            <span
                                                style={{
                                                    color: '#2ed3c5',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {t('notifyLastminDealDiscount')}{' '}
                                                {discountCalculate(
                                                    parseInt(dealData?.price),
                                                    dealData?.discount_percentage,
                                                )}{' '}
                                                (SEK)
                                            </span>
                                        )}
                                    </div>
                                </Col>
                                <Col xs={24}>
                                    <div className="inputModalText">
                                        <ClinicInput
                                            placeholder={t('dealNumber')}
                                            height={30}
                                            name="count"
                                            onChange={onChangeDealNumber}
                                            Value={dealData.count}
                                            required
                                            max={1000}
                                            errorMessage={errorTextCount}
                                        />
                                        {errorTextCount !== '' ? (
                                            <div className="errorStylePrice">
                                                {errorTextCount}
                                            </div>
                                        ) : null}
                                    </div>
                                </Col>

                                <Col xs={24} md={12} lg={12}>
                                    {screens.xs ? (
                                        <div className="inputModalText">
                                            <DatePicker
                                                ref={startdateRef}
                                                className="modalDate"
                                                placeholder={t('dealStart')}
                                                value={
                                                    dealData.start_time
                                                        ? dayjs(
                                                              dealData.start_time,
                                                          )
                                                        : initialValue
                                                }
                                                open={isOpenStartDate}
                                                onClick={() => {
                                                    setIsOpenStartDate(
                                                        !isOpenStartDate,
                                                    );
                                                }}
                                                picker={currentPicker}
                                                minuteStep={30}
                                                allowClear={false}
                                                getPopupContainer={(trigger) =>
                                                    trigger.parentElement
                                                }
                                                format={format}
                                                onChange={(dateString, value) =>
                                                    onChangeDate(
                                                        'start_time',
                                                        dateString,
                                                        value,
                                                        screens.xs,
                                                        currentPicker,
                                                    )
                                                }
                                                onOk={(selectedDate) => {
                                                    if (!selectedDate) return;

                                                    let currentDate = dayjs();

                                                    let minutes =
                                                        currentDate.date() ===
                                                            selectedDate.date() &&
                                                        currentDate.hour() ===
                                                            selectedDate.hour()
                                                            ? currentDate.minute()
                                                            : selectedDate.minute();

                                                    let value =
                                                        selectedDate.minute(
                                                            minutes > 0
                                                                ? 30
                                                                : 0,
                                                        );
                                                    let dateString =
                                                        value.format(
                                                            'YYYY-MM-DD HH:mm',
                                                        );

                                                    return onChangeDate(
                                                        'start_time',
                                                        value,
                                                        dateString,
                                                        screens.xs,
                                                        currentPicker,
                                                    );
                                                }}
                                                suffixIcon={
                                                    <ClockCircleOutlined />
                                                }
                                                disabled={dealData.clinic_call}
                                                name={'start_time'}
                                                inputReadOnly
                                                disabledDate={(current) => {
                                                    let customDate =
                                                        moment().format(
                                                            'YYYY-MM-DD',
                                                        );
                                                    return (
                                                        current &&
                                                        current <
                                                            moment(
                                                                customDate,
                                                                'YYYY-MM-DD',
                                                            )
                                                    );
                                                }}
                                                disabledTime={(current) => {
                                                    let select_date =
                                                        moment().date();
                                                    let hours =
                                                        select_date ===
                                                        current?.date()
                                                            ? moment().hour()
                                                            : 0;

                                                    let mins =
                                                        !current?.isSame(
                                                            moment(),
                                                        ) &&
                                                        current?.isBefore(
                                                            moment(),
                                                        )
                                                            ? moment().minute()
                                                            : 0;
                                                    return disabledDateTime(
                                                        hours,
                                                        mins,
                                                    );
                                                }}
                                                showNow={false}
                                                readonly="readonly"
                                            />
                                        </div>
                                    ) : (
                                        <div className="inputModalText">
                                            <DatePicker
                                                ref={startdateRef}
                                                className="modalDate"
                                                placeholder={t('dealStart')}
                                                value={
                                                    dealData.start_time
                                                        ? dayjs(
                                                              dealData.start_time,
                                                          )
                                                        : initialValue
                                                }
                                                // placement="bottomLeft"
                                                popupStyle={{
                                                    width: screens.xs
                                                        ? 288
                                                        : 'auto',
                                                    height: screens.xs
                                                        ? 250
                                                        : 'auto',
                                                }}
                                                minuteStep={30}
                                                allowClear={false}
                                                getPopupContainer={(trigger) =>
                                                    trigger.parentElement
                                                }
                                                showTime={{
                                                    format: 'HH:mm',
                                                }}
                                                format="YYYY-MM-DD HH:mm"
                                                onChange={(dateString, value) =>
                                                    onChangeDate(
                                                        'start_time',
                                                        dateString,
                                                        value,
                                                    )
                                                }
                                                onOk={(selectedDate) => {
                                                    if (!selectedDate) return;

                                                    let currentDate = dayjs();

                                                    let minutes =
                                                        currentDate.date() ===
                                                            selectedDate.date() &&
                                                        currentDate.hour() ===
                                                            selectedDate.hour()
                                                            ? currentDate.minute()
                                                            : selectedDate.minute();

                                                    let value =
                                                        selectedDate.minute(
                                                            minutes > 0
                                                                ? 30
                                                                : 0,
                                                        );
                                                    let dateString =
                                                        value.format(
                                                            'YYYY-MM-DD HH:mm',
                                                        );

                                                    return onChangeDate(
                                                        'start_time',
                                                        value,
                                                        dateString,
                                                    );
                                                }}
                                                suffixIcon={
                                                    <ClockCircleOutlined />
                                                }
                                                disabled={dealData.clinic_call}
                                                name={'start_time'}
                                                inputReadOnly
                                                disabledDate={(current) => {
                                                    let customDate =
                                                        moment().format(
                                                            'YYYY-MM-DD',
                                                        );
                                                    return (
                                                        current &&
                                                        current <
                                                            moment(
                                                                customDate,
                                                                'YYYY-MM-DD',
                                                            )
                                                    );
                                                }}
                                                disabledTime={(current) => {
                                                    let select_date =
                                                        moment().date();
                                                    let hours =
                                                        select_date ===
                                                        current?.date()
                                                            ? moment().hour()
                                                            : 0;

                                                    let mins =
                                                        !current?.isSame(
                                                            moment(),
                                                        ) &&
                                                        current?.isBefore(
                                                            moment(),
                                                        )
                                                            ? moment().minute()
                                                            : 0;
                                                    return disabledDateTime(
                                                        hours,
                                                        mins,
                                                    );
                                                }}
                                                showNow={false}
                                                readonly="readonly"
                                            />
                                        </div>
                                    )}
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    {screens.xs ? (
                                        <div className="inputModalText">
                                            <DatePicker
                                                ref={enddateRef}
                                                className="modalDate"
                                                readonly="readonly"
                                                value={
                                                    dealData.end_time
                                                        ? dayjs(
                                                              dealData.end_time,
                                                          )
                                                        : initialValue
                                                }
                                                picker={currentPicker}
                                                minuteStep={30}
                                                open={isOpenEndDate}
                                                onClick={() => {
                                                    setIsOpenEndDate(
                                                        !isOpenEndDate,
                                                    );
                                                }}
                                                allowClear={false}
                                                getPopupContainer={(
                                                    trigger,
                                                ) => {
                                                    return trigger.parentElement;
                                                }}
                                                placeholder={t('dealEnd')}
                                                popperModifiers={{
                                                    preventOverflow: {
                                                        enabled: true,
                                                    },
                                                }}
                                                onChange={(dateString, value) =>
                                                    onChangeDate(
                                                        'end_time',
                                                        dateString,
                                                        value,
                                                        screens.xs,
                                                        currentPicker,
                                                    )
                                                }
                                                onOk={(selectedDate) => {
                                                    if (!selectedDate) return;

                                                    let currentDate = dayjs();

                                                    let minutes =
                                                        currentDate.date() ===
                                                            selectedDate.date() &&
                                                        currentDate.hour() ===
                                                            selectedDate.hour()
                                                            ? currentDate.minute()
                                                            : selectedDate.minute();

                                                    let value =
                                                        selectedDate.minute(
                                                            minutes > 0
                                                                ? 30
                                                                : 0,
                                                        );
                                                    let dateString =
                                                        value.format(
                                                            'YYYY-MM-DD HH:mm',
                                                        );

                                                    return onChangeDate(
                                                        'end_time',
                                                        value,
                                                        dateString,
                                                        screens.xs,
                                                        currentPicker,
                                                    );
                                                }}
                                                format="YYYY-MM-DD HH:mm"
                                                suffixIcon={
                                                    <ClockCircleOutlined />
                                                }
                                                disabled={
                                                    dealData.start_time ===
                                                        '' ||
                                                    dealData.clinic_call
                                                }
                                                name={'end_time'}
                                                disabledDate={(current) => {
                                                    let customDate = moment(
                                                        dealData.start_time,
                                                    ).format('YYYY-MM-DD');
                                                    return (
                                                        current &&
                                                        current <
                                                            moment(
                                                                customDate,
                                                                'YYYY-MM-DD',
                                                            )
                                                    );
                                                }}
                                                inputReadOnly
                                                disabledTime={(current) => {
                                                    let select_date = moment(
                                                        dealData.start_time,
                                                    ).date();
                                                    let select_hour = moment(
                                                        dealData.start_time,
                                                    ).hour();
                                                    let hour =
                                                        select_date ===
                                                        current?.date()
                                                            ? moment(
                                                                  dealData.start_time,
                                                              ).hour()
                                                            : 0;
                                                    let mins =
                                                        select_date ===
                                                            current?.date() &&
                                                        select_hour ===
                                                            current?.hour()
                                                            ? moment(
                                                                  dealData.start_time,
                                                              ).minute() + 1
                                                            : 0;

                                                    return disabledDateTime(
                                                        hour,
                                                        mins,
                                                    );
                                                }}
                                                showNow={false}
                                            />
                                        </div>
                                    ) : (
                                        <div className="inputModalText">
                                            <DatePicker
                                                ref={enddateRef}
                                                className="modalDate"
                                                readonly="readonly"
                                                value={
                                                    dealData.end_time
                                                        ? dayjs(
                                                              dealData.end_time,
                                                          )
                                                        : initialValue
                                                }
                                                minuteStep={30}
                                                popupStyle={{
                                                    width: screens.xs
                                                        ? 288
                                                        : 'auto',
                                                    height: screens.xs
                                                        ? 250
                                                        : 'auto',
                                                }}
                                                allowClear={false}
                                                getPopupContainer={(
                                                    trigger,
                                                ) => {
                                                    return trigger.parentElement;
                                                }}
                                                placeholder={t('dealEnd')}
                                                popperModifiers={{
                                                    preventOverflow: {
                                                        enabled: true,
                                                    },
                                                }}
                                                onChange={(dateString, value) =>
                                                    onChangeDate(
                                                        'end_time',
                                                        dateString,
                                                        value,
                                                    )
                                                }
                                                onOk={(selectedDate) => {
                                                    if (!selectedDate) return;

                                                    let currentDate = dayjs();

                                                    let minutes =
                                                        currentDate.date() ===
                                                            selectedDate.date() &&
                                                        currentDate.hour() ===
                                                            selectedDate.hour()
                                                            ? currentDate.minute()
                                                            : selectedDate.minute();

                                                    let value =
                                                        selectedDate.minute(
                                                            minutes > 0
                                                                ? 30
                                                                : 0,
                                                        );
                                                    let dateString =
                                                        value.format(
                                                            'YYYY-MM-DD HH:mm',
                                                        );

                                                    return onChangeDate(
                                                        'end_time',
                                                        value,
                                                        dateString,
                                                    );
                                                }}
                                                showTime={{
                                                    format: 'HH:mm',
                                                }}
                                                format="YYYY-MM-DD HH:mm"
                                                suffixIcon={
                                                    <ClockCircleOutlined />
                                                }
                                                disabled={
                                                    dealData.start_time ===
                                                        '' ||
                                                    dealData.clinic_call
                                                }
                                                name={'end_time'}
                                                disabledDate={(current) => {
                                                    let customDate = moment(
                                                        dealData.start_time,
                                                    ).format('YYYY-MM-DD');
                                                    return (
                                                        current &&
                                                        current <
                                                            moment(
                                                                customDate,
                                                                'YYYY-MM-DD',
                                                            )
                                                    );
                                                }}
                                                inputReadOnly
                                                disabledTime={(current) => {
                                                    let select_date = moment(
                                                        dealData.start_time,
                                                    ).date();
                                                    let select_hour = moment(
                                                        dealData.start_time,
                                                    ).hour();
                                                    let hour =
                                                        select_date ===
                                                        current?.date()
                                                            ? moment(
                                                                  dealData.start_time,
                                                              ).hour()
                                                            : 0;
                                                    let mins =
                                                        select_date ===
                                                            current?.date() &&
                                                        select_hour ===
                                                            current?.hour()
                                                            ? moment(
                                                                  dealData.start_time,
                                                              ).minute() + 1
                                                            : 0;

                                                    return disabledDateTime(
                                                        hour,
                                                        mins,
                                                    );
                                                }}
                                                showNow={false}
                                            />
                                        </div>
                                    )}
                                </Col>
                                <Col xs={24}>
                                    <div className="inputModalCheck">
                                        <Checkbox
                                            onChange={(event) => {
                                                setDealData({
                                                    ...dealData,
                                                    end_time:
                                                        dealData.clinic_call
                                                            ? initialValue
                                                            : dealData.end_time,
                                                    start_time:
                                                        dealData.clinic_call
                                                            ? initialValue
                                                            : dealData.start_time,
                                                    clinic_call:
                                                        !dealData.clinic_call,
                                                });
                                                if (!event.target.checked) {
                                                    if (checkboxRef.current) {
                                                        checkboxRef.current.blur();
                                                        startdateRef.current.focus();
                                                    }
                                                }
                                            }}
                                            ref={checkboxRef}
                                            className="modalDealCheck themeCheckboxColor"
                                            checked={dealData.clinic_call}
                                            name="clinic_call"
                                        />
                                        <div className="modalDealCheck">
                                            {t('dealCallClinic')}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    {/* <div className="modelButton"> */}
                                    <ClinicButton
                                        height={45}
                                        title={t('dealCreateButton')}
                                        loading={isLoading}
                                        disabled={
                                            dealData.name === '' ||
                                            dealData.about === '' ||
                                            dealData.price === '' ||
                                            dealData.count === '' ||
                                            errorText !== '' ||
                                            errorTextCount !== '' ||
                                            errorTextPrice !== '' ||
                                            (dealData.clinic_call === false &&
                                                (dealData.start_time === '' ||
                                                    dealData.end_time === ''))
                                        }
                                        onClick={optimizedFunction}
                                        // buttonType={'formButton'}
                                    />
                                    {/* </div> */}
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                    <ClinicButton
                                        height={45}
                                        title={t('dealPreviewButton')}
                                        loading={false}
                                        onClick={() => {
                                            setIsPreviewModalOpen(
                                                !isPreviewModalOpen,
                                            );
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    {/* </div> */}
                </Modal>

                <Modal
                    open={isPreviewModalOpen}
                    footer={false}
                    className="dealModal"
                    onCancel={() => {
                        setIsPreviewModalOpen(!isPreviewModalOpen);
                    }}
                    maskClosable={false}
                    centered
                    style={{
                        top: 5,
                    }}
                >
                    <h1 className="modalDealHeader">{t('perview')}</h1>
                    <div className="contentContainer">
                        <div className="modelContentWrapper">
                            <div
                                className={
                                    i18n.language === 'eng'
                                        ? 'modelContent'
                                        : 'modelContentSwedish'
                                }
                            >
                                <div className="modelBar">
                                    <div className="modelClinicInformation modelClinicInformationBg">
                                        <div className="modelClinicAvatar">
                                            <img
                                                src={
                                                    userData?.profile ??
                                                    Images.userProfile
                                                }
                                                height={53}
                                                width={53}
                                                alt="user-profile"
                                            />
                                        </div>
                                        <div className="modelClinicDetail">
                                            <p className="modelClinicDetailName">
                                                {dealData?.name ||
                                                    t('previewName')}
                                            </p>
                                            <p className="modelClinicDetailPrice">
                                                {dealData?.price} SEK
                                            </p>
                                            <p className="modelClinicDetailStarContainer">
                                                <Rate
                                                    disabled
                                                    value={
                                                        dashboardData?.clinic
                                                            ?.rating
                                                    }
                                                    className="modelClinicDetailPreviewStar"
                                                />
                                            </p>

                                            {/* <p className="modelClinicDetailLocation">
                                            {userData?.company_address}
                                        </p> */}
                                        </div>
                                    </div>
                                    <div className="modelClinicInformation">
                                        <main>
                                            <h3 className="modelClinicInformationHeading">
                                                {t('previewAbout')}
                                            </h3>
                                            <p className="modelClinicInformationParagraphPreview">
                                                {dealData?.about !== ''
                                                    ? addLineBreaks(
                                                          dealData.about,
                                                      )
                                                    : ''}
                                            </p>
                                        </main>
                                    </div>
                                    <div className="modelClinicInformation">
                                        <main>
                                            <h3 className="modelClinicInformationHeading">
                                                {t('previewTiming')}
                                            </h3>
                                            <div className="modelClinicDealTiming">
                                                {dealData.clinic_call ? (
                                                    t('dealCallClinic')
                                                ) : (
                                                    <>
                                                        <img
                                                            src={clock}
                                                            height={10}
                                                        />{' '}
                                                        {dealData?.start_time +
                                                            ' to ' +
                                                            dealData?.end_time}
                                                    </>
                                                )}
                                            </div>
                                        </main>
                                    </div>
                                    <div className="modelClinicInformation">
                                        <main>
                                            <h3 className="modelClinicInformationHeading">
                                                {t('previewAddress')}
                                            </h3>
                                            <div className="modelClinicDealTiming">
                                                <img
                                                    src={location}
                                                    height={10}
                                                />{' '}
                                                {userData?.company_address}
                                            </div>
                                        </main>
                                    </div>
                                    <div className="modelClinicInformation">
                                        <main>
                                            <h3 className="modelClinicInformationHeading">
                                                {t('previewLocation')}{' '}
                                            </h3>
                                            <div className="modelClinicDealTiming">
                                                <iframe
                                                    width="200"
                                                    height="100"
                                                    style={{
                                                        border: 0,
                                                        borderRadius: '10px',
                                                    }}
                                                    allowFullScreen={false}
                                                    loading="lazy"
                                                    referrerPolicy="no-referrer-when-downgrade"
                                                    src={
                                                        googleMapUrl +
                                                        `&q=${userData?.company_address}&zoom=9&center=${userData?.clinic_latitude},${userData?.clinic_longitude}`
                                                    }
                                                ></iframe>
                                            </div>
                                        </main>
                                    </div>
                                    <div
                                        style={{
                                            margin: '0.5rem',
                                            marginTop: 0,
                                            marginBottom: 0,
                                        }}
                                    >
                                        <div className="modelClinicInformationButton">
                                            {t('previewBookAppointment')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modalButtonContainer">
                        <ClinicButton
                            height={35}
                            title={t('close')}
                            loading={false}
                            onClick={() => {
                                setIsPreviewModalOpen(!isPreviewModalOpen);
                            }}
                        />
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default DealModal;
