import React, { useState, useEffect } from 'react';

function AnimatedNumber({ targetNumber }) {
    const [currentNumber, setCurrentNumber] = useState(0);

    useEffect(() => {
        if (currentNumber < targetNumber) {
            let animationFrameId;

            const animateNumber = () => {
                setCurrentNumber((prevNumber) => {
                    const nextNumber = prevNumber + 1;
                    return nextNumber >= targetNumber
                        ? targetNumber
                        : nextNumber;
                });

                if (currentNumber < targetNumber) {
                    animationFrameId = requestAnimationFrame(animateNumber);
                }
            };

            animationFrameId = requestAnimationFrame(animateNumber);

            return () => {
                cancelAnimationFrame(animationFrameId);
            };
        }
    }, [targetNumber, currentNumber]);

    return <span className="clinic-summary-figure">{currentNumber}</span>;
}

export default AnimatedNumber;
