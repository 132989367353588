import React, { useRef, useState } from 'react';
import Navbar from '../../commonComponents/Navbar';
import { Button, Col, Row, Grid } from 'antd';
import { postApiWithoutAuth } from '../../../utils/api';
import { setToken } from '../../../utils/localStorage';
import { Url } from '../../../utils/apiUrls';
import { useSnackbar } from 'notistack';
import { ClinicButton, PricingCard } from '../../commonComponents';
import { ClinicInput } from '../../commonComponents';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Images } from '../../../assets/images';
import './LoginStyle.css';
import IconsImages from '../../../assets/Icons';

const { useBreakpoint } = Grid;

const Login = () => {
    const screen = useBreakpoint();
    const pricePlanRef = useRef();
    const [userData, setUserData] = useState({
        email: '',
        password: '',
        role: 'clinic',
    });
    const [errorText, setErrorText] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const emailRegex =
        /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const booleanValue = false;

    const handleOnChange = (event) => {
        const { name, value } = event.target;
        setUserData({ ...userData, [name]: value });
        if (event.target.name === 'email') {
            if (event.target.value.match(emailRegex)) {
                setErrorText('');
            } else {
                setErrorText(t('invalidformat'));
            }
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            postData();
        }
    };

    const scrollToPricePlan = () => {
        if (pricePlanRef.current) {
            pricePlanRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    const postData = async () => {
        setIsLoading(true);
        try {
            const response = await postApiWithoutAuth(Url.LOGIN_URL, userData);
            if (response.success) {
                const {
                    data: { is_active, access_token },
                } = response.data;

                if (!is_active) {
                    return navigate('/verifycode', {
                        state: { email: userData.email, previousPath: 'Login' },
                    });
                }

                if (is_active && response?.success) {
                    setToken(access_token);
                    navigate('/dashboard');
                    enqueueSnackbar(t('login'), {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top',
                        },
                        variant: 'success',
                    });
                }
            } else {
                enqueueSnackbar(t('invalidCredentials'), {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top',
                    },
                    variant: 'error',
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onNav = () => {
        navigate('/signup');
    };

    return (
        <div className="loginScreenBackground">
            <div>
                <Navbar />
            </div>
            <section className={screen.md ? 'containerWeb' : ''}>
                <Row
                    align="middle"
                    style={{
                        height: screen.lg ? '90vh' : '100%',
                    }}
                    justify={screen.lg ? 'space-around' : 'space-between'}
                >
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <div className="loginContainer">
                            <span className="textStyle">
                                <strong className="textCustom">
                                    {t('gnizzel')}{' '}
                                </strong>
                                {`${t('isA')} `}{' '}
                                <strong className="textCustom">
                                    {t('marketPlace')}
                                </strong>
                                {` ${t('forClinic')} `}
                                <strong className="textCustom">
                                    {t('gnizzel')}{' '}
                                </strong>
                                {` ${t('monthsFor')} `}
                                <strong className="textCustom">
                                    {t('free')}
                                </strong>
                            </span>
                            <p className="paragraphLogin">{`${t('dummy')}`}</p>
                            <div className="loginButton">
                                <Button
                                    className="loginStartButton"
                                    onClick={onNav}
                                >{`${t('getStarted')}`}</Button>
                                <Button
                                    className="bookDemoButton"
                                    onClick={() => navigate('/contactus')}
                                >{`${t('bookADemo')}`}</Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={10} xl={8}>
                        <div className="loginCard loginContainer">
                            <form onKeyDown={handleKeyPress}>
                                <Row justify={'space-evenly'} gutter={[16, 0]}>
                                    <Col
                                        style={{
                                            marginTop: '2rem',
                                        }}
                                        span={20}
                                    >
                                        <span className="loginCardText">
                                            {`${t('Welcome')}`} {`${t('to')}`}{' '}
                                            <strong className="loginSubText">
                                                Gnizzel
                                            </strong>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <h1 className="signIn">{`${t(
                                            'Sign',
                                        )}`}</h1>
                                    </Col>
                                    <Col span={20}>
                                        <Row gutter={[5, 10]}>
                                            <Col span={24}>
                                                <ClinicInput
                                                    type="text"
                                                    placeholder={t('Email')}
                                                    name="email"
                                                    value={userData.email}
                                                    onChange={handleOnChange}
                                                    height={30}
                                                />
                                            </Col>
                                            <Col span={24}>
                                                <ClinicInput
                                                    placeholder={t('Password')}
                                                    type="password"
                                                    name="password"
                                                    isRequired={
                                                        userData.password === ''
                                                            ? !booleanValue
                                                            : booleanValue
                                                    }
                                                    value={userData.password}
                                                    isRequiredType="password"
                                                    isRequiredTypeMessgae="password"
                                                    onChange={handleOnChange}
                                                    height={30}
                                                />
                                            </Col>
                                            <Col span={24}>
                                                <div
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    <Link
                                                        to="/forgetpassword"
                                                        className="anchortagLogin"
                                                    >
                                                        {`${t('forget')}`}
                                                    </Link>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <ClinicButton
                                                    disabled={
                                                        errorText !== '' ||
                                                        userData.password === ''
                                                    }
                                                    title={`${t('Sign')}`}
                                                    onClick={postData}
                                                    loading={isLoading}
                                                    height={40}
                                                />
                                            </Col>
                                        </Row>
                                        <Col span={24}>
                                            <div className="contactUsContainer">
                                                <p className="needHelp">
                                                    {`${t('needHelp')}`}
                                                    &nbsp;
                                                    <Link
                                                        to="/contactus"
                                                        className="contactUsAnchorTag"
                                                    >
                                                        {`${t('contactUs')}`}
                                                    </Link>
                                                </p>
                                            </div>
                                        </Col>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </Col>
                </Row>
            </section>
            <section
                style={{ marginTop: screen.lg ? 'unset' : '1rem' }}
                className={screen.md ? 'containerWeb' : ''}
            >
                <Row justify={'space-around'} align={'middle'}>
                    <Col lg={9} md={9} xs={24}>
                        <div className="loginScreenMobileWrapper">
                            <img
                                src={
                                    i18n.language === 'eng'
                                        ? Images.englishMobile
                                        : Images.swedishMobile
                                }
                                className="loginScreenMobileImage"
                                alt="englishMobile"
                            />
                            <img
                                src={Images.loginScreenEllips}
                                className="loginScreenMobileEllips"
                                alt="englishMobile"
                            />
                        </div>
                    </Col>
                    <Col
                        lg={11}
                        md={15}
                        xs={24}
                        className="loginScreenTextBox__sectionOne"
                    >
                        <h1 className="loginScreenHeading">
                            {t('clinicJoinGnizzel')}
                        </h1>
                        <ul className="loginScreenUnorderList">
                            <li className="loginScreenListItem">
                                <img
                                    src={IconsImages.listIcon}
                                    alt="ListIcon"
                                />
                                <div className="loginScreenContentWrapper">
                                    <h3 className="loginScreenListHeading">
                                        {t('clinicJoinGnizzelHeadingOne')}
                                    </h3>
                                    <span className="loginScreenListParagraph">
                                        {t('clinicJoinGnizzelParagraphOne')}
                                    </span>
                                </div>
                            </li>
                            <li className="loginScreenListItem">
                                <img
                                    src={IconsImages.listIcon}
                                    alt="ListIcon"
                                />
                                <div className="loginScreenContentWrapper">
                                    <h3 className="loginScreenListHeading">
                                        {t('clinicJoinGnizzelHeadingTwo')}
                                    </h3>
                                    <span className="loginScreenListParagraph">
                                        {t('clinicJoinGnizzelParagraphTwo')}
                                    </span>
                                </div>
                            </li>
                            <li className="loginScreenListItem">
                                <img
                                    src={IconsImages.listIcon}
                                    alt="ListIcon"
                                />
                                <div className="loginScreenContentWrapper">
                                    <h3 className="loginScreenListHeading">
                                        {t('clinicJoinGnizzelHeadingThree')}
                                    </h3>
                                    <span className="loginScreenListParagraph">
                                        {t('clinicJoinGnizzelParagraphThree')}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </section>
            <section
                style={{ marginTop: screen.lg ? '115px' : '1rem' }}
                className={screen.md ? 'containerWeb' : ''}
            >
                <Row justify={'space-around'} align={'middle'}>
                    <Col lg={12} md={15} xs={24}>
                        <h1 className="loginScreenHeading">
                            {t('customerOfGnizzel')}
                        </h1>
                        <ul className="loginScreenUnorderList">
                            <li className="loginScreenListItem">
                                <img
                                    src={IconsImages.listIcon}
                                    alt="ListIcon"
                                />
                                <div className="loginScreenContentWrapper">
                                    <h3 className="loginScreenListHeading">
                                        {t('customerOfGnizzelHeadingOne')}
                                    </h3>
                                    <span className="loginScreenListParagraph">
                                        {t('customerOfGnizzelParagraphOne')}
                                    </span>
                                </div>
                            </li>
                            <li className="loginScreenListItem">
                                <img
                                    src={IconsImages.listIcon}
                                    alt="ListIcon"
                                />
                                <div className="loginScreenContentWrapper">
                                    <h3 className="loginScreenListHeading">
                                        {t('customerOfGnizzelHeadingTwo')}
                                    </h3>
                                    <span className="loginScreenListParagraph">
                                        {t('customerOfGnizzelParagraphTwo')}
                                    </span>
                                </div>
                            </li>
                            <li className="loginScreenListItem">
                                <img
                                    src={IconsImages.listIcon}
                                    alt="ListIcon"
                                />
                                <div className="loginScreenContentWrapper">
                                    <h3 className="loginScreenListHeading">
                                        {t('customerOfGnizzelHeadingThree')}
                                    </h3>
                                    <span className="loginScreenListParagraph">
                                        {t('customerOfGnizzelParagraphThree')}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </Col>
                    <Col lg={9} md={9} xs={24}>
                        <div className="loginScreenMobileWrapper_sectionTwo">
                            <img
                                src={
                                    i18n.language === 'eng'
                                        ? Images.englishMobile
                                        : Images.swedishMobile
                                }
                                className="loginScreenMobileImage_sectionTwo"
                                alt="englishMobile"
                            />
                            <img
                                src={Images.loginScreenEllips}
                                className="loginScreenMobileEllips_sectionTwo"
                                alt="englishMobile"
                            />
                        </div>
                    </Col>
                </Row>
            </section>
            <section
                style={{
                    marginTop: '82px',
                    padding: '2rem 0',
                    background: 'rgba(240, 249, 253, 1)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                ref={pricePlanRef}
            >
                <h1
                    style={{
                        textAlign: 'center',
                        fontSize: '32px',
                        color: 'rgba(77, 77, 77, 1)',
                        margin: 0,
                    }}
                >
                    {t('PricePlan')}{' '}
                    <span
                        style={{
                            fontSize: '32px',
                            color: 'rgba(46, 211, 197, 1)',
                        }}
                    >
                        {t('gnizzelServices')}
                    </span>
                </h1>
                <p
                    style={{
                        textAlign: 'center',
                        color: 'rgba(77, 77, 77, 1)',
                        fontSize: '20px',
                        width: screen.lg ? '65%' : '85%',
                        color: 'rgba(102, 102, 102, 1)',
                    }}
                >
                    {t('pricePlanPageDescription')}
                </p>
                <div className="pricingPlanButtonWrapper">
                    <p className="pricingPlanButton">{t('pricingButton')}</p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Row gutter={[16, 16]} className="pricingGridLayout">
                        <Col xs={24} lg={8}>
                            <PricingCard
                                icon={IconsImages.smallPackage}
                                offPercentage="57% Off"
                                plan={{
                                    title: t('priceCardSmallHeading'),
                                    description: t('priceCardSmallDescription'),
                                    price: t('priceCardSmallPrice'),
                                    discount: '1399',
                                    features: [
                                        t('priceCardSmallFeatureOne'),
                                        t('priceCardSmallFeatureTwo'),
                                        t('priceCardSmallFeatureThree'),
                                        t('priceCardSmallFeatureFour'),
                                    ],
                                }}
                                currency={t('currency')}
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <PricingCard
                                icon={IconsImages.mediumPackage}
                                plan={{
                                    title: t('priceCardMediumHeading'),
                                    description: t(
                                        'priceCardMediumDescription',
                                    ),
                                    price: t('priceCardMediumPrice'),
                                    discount: '1999',
                                    features: [
                                        t('priceCardMediumFeatureOne'),
                                        t('priceCardMediumFeatureTwo'),
                                        t('priceCardMediumFeatureThree'),
                                        t('priceCardMediumFeatureFour'),
                                    ],
                                }}
                                offPercentage="50% Off"
                                active
                                currency={t('currency')}
                            />
                        </Col>
                        <Col xs={24} lg={8}>
                            <PricingCard
                                icon={IconsImages.largePackage}
                                offPercentage="52% Off"
                                plan={{
                                    title: t('priceCardLargeHeading'),
                                    description: t('priceCardLargeDescription'),
                                    price: t('priceCardLargePrice'),
                                    discount: '2899',
                                    features: [
                                        t('priceCardLargeFeatureOne'),
                                        t('priceCardLargeFeatureTwo'),
                                        t('priceCardLargeFeatureThree'),
                                        t('priceCardLargeFeatureFour'),
                                    ],
                                }}
                                currency={t('currency')}
                            />
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    );
};

export default Login;
