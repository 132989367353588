import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TermCondition() {
    const { t } = useTranslation();
    return (
        <div className="mainView">
            <h1>{t('termsOfUses')}</h1>
            <ul>
                <li>
                    <p className="uiParagraph">{t('termPara1')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('termPara2')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('termPara3')}</p>
                </li>
            </ul>
            <h2>{t('overviewTerm')}</h2>
            <ul>
                <li>
                    <p className="uiParagraph">{t('overviewPara1')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('overviewPara2')}</p>
                </li>
            </ul>
            <h2>{t('accountsRegistration')}</h2>
            <ul>
                <li>
                    <p>
                        {t('accountsRegistrationTerm')}{' '}
                        <a href="mailto:info@gnizzel.com" className="uiEmail">
                            info@gnizzel.com
                        </a>
                    </p>
                </li>
            </ul>

            <h2>{t('usage')}</h2>

            <ul>
                <li>
                    <p className="uiParagraph">{t('usagePara1')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('usagePara2')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('usagePara3')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('usagePara4')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('usagePara5')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('usagePara6')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('usagePara7')}</p>
                </li>
            </ul>

            <h2>{t('termPayment')}</h2>
            <ul>
                <li>
                    <p>{t('termPaymentSubscription')}</p>
                </li>
                <ul>
                    <li>
                        <p>{t('termPaymentSubscriptionPara1')}</p>
                    </li>
                    <li>
                        <p>{t('termPaymentSubscriptionPara2')}</p>
                    </li>
                    <li>
                        <p>{t('termPaymentSubscriptionPara3')}</p>
                    </li>
                </ul>
            </ul>
            <ul>
                <li>
                    <p>{t('termPaymentPrice')}</p>
                </li>
                <ul>
                    <li>
                        <p>{t('termPaymentPricePara1')}</p>
                    </li>
                    <li>
                        <p>{t('termPaymentPricePara2')}</p>
                    </li>
                </ul>
            </ul>

            <h2>{t('termination')}</h2>
            <ul>
                <li>
                    <p className="uiParagraph">{t('terminationPara1')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('terminationPara2')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('terminationPara3')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('terminationPara4')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('terminationPara5')}</p>
                    <ol type="a">
                        <li>
                            <p className="uiParagraph">
                                {t('child1TerminationPara5')}
                            </p>
                        </li>
                        <li>
                            <p className="uiParagraph">
                                {t('child2TerminationPara5')}
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p className="uiParagraph">{t('terminationPara6')}</p>
                </li>
                <li>
                    <p className="uiParagraph">
                        {t('terminationPara7')}{' '}
                        <a href="mailto:info@gnizzel.com" className="uiEmail">
                            info@gnizzel.com
                        </a>
                    </p>
                </li>
                <li>
                    <p className="uiParagraph">{t('terminationPara8')}</p>
                </li>
            </ul>

            <h2>{t('usingThePlatform')}</h2>
            <ul>
                <li>
                    <p className="uiParagraph">{t('usingThePlatformPara1')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('usingThePlatformPara2')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('usingThePlatformPara3')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('usingThePlatformPara4')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('usingThePlatformPara5')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('usingThePlatformPara6')}</p>
                    <ol type="a">
                        <li>
                            <p className="uiParagraph">
                                {t('child1usingThePlatformPara6')}
                            </p>
                        </li>
                        <li>
                            <p className="uiParagraph">
                                {t('child2usingThePlatformPara6')}
                            </p>
                        </li>
                        <li>
                            <p className="uiParagraph">
                                {t('child3usingThePlatformPara6')}
                            </p>
                        </li>
                        <li>
                            <p className="uiParagraph">
                                {t('child4usingThePlatformPara6')}
                            </p>
                        </li>
                    </ol>
                </li>
            </ul>

            <h2>{t('dataProtection')}</h2>
            <ul>
                <li>
                    <p className="uiParagraph">{t('dataProtectionPara1')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('dataProtectionPara2')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('dataProtectionPara3')}</p>
                </li>
                <li>
                    <p className="uiParagraph">{t('dataProtectionPara4')}</p>
                </li>
            </ul>

            <h2>{t('ownership')}</h2>
            <ul>
                <li>
                    <p className="uiParagraph">{t('ownershipPara1')}</p>
                </li>
            </ul>
        </div>
    );
}
