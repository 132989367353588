import React from "react";
import PropTypes from "prop-types";
import { PlusCircleOutlined } from "@ant-design/icons";
import "./ClinicCreateBox.css";

function ClinicCreateBox({ height, width, text, onClick }) {
  ClinicCreateBox.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    text: PropTypes.string,
    onClick: PropTypes.func,
  };

  ClinicCreateBox.defaultProps = {
      height: 0,
      width: 100,
      text: '',
      onClick: () => {},
  };

  return (
      <div className="boxContainer">
          <div
              className="boxMainConatiner"
              // style={{ height: 'auto', width: `${width}%` }}
          >
              <PlusCircleOutlined className="boxPlusIcon" onClick={onClick} />
              <p className="boxTextStyle" onClick={onClick}>
                  {text}
              </p>
          </div>
      </div>
  );
}

export default ClinicCreateBox;
