import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Url } from '../../../utils/apiUrls';
import { useTranslation } from 'react-i18next';
import { patchApiWithAuth } from '../../../utils/api';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Modal, Form, DatePicker, Row, Col, Grid } from 'antd';
import { ClinicButton, ClinicInput } from '../../commonComponents';
import { debounce, disabledDateTime, pickers } from '../../../utils/helper';

import './LastMin.css';
import './LastMinModel.css';

const { useBreakpoint } = Grid;

const discountCalculate = (value, discountPercentage) => {
    const discountedvalue = value - value * (discountPercentage / 100);
    return Math.ceil(discountedvalue);
};

const LastMinUpdate = ({
    isModalOpen,
    setIsModalOpen,
    setNewCardAdd,
    selectedCard,
}) => {
    LastMinUpdate.propTypes = {
        isModalOpen: PropTypes.bool,
        setIsModalOpen: PropTypes.func,
        setNewCardAdd: PropTypes.func,
        selectedCard: PropTypes.object,
    };

    LastMinUpdate.defaultProps = {
        setIsModalOpen: () => {},
        isModalOpen: false,
        setNewCardAdd: () => {},
        selectedCard: {},
    };

    const screens = useBreakpoint();
    const { t } = useTranslation();
    const letterRegix = /^[a-zA-Z0-9åäöÅÄÖ\s%-/]{1,25}$/;
    const [form] = Form.useForm();
    const [dealData, setDealData] = useState({
        name: selectedCard.name,
        start_time:
            selectedCard.start_time == null
                ? null
                : dayjs(selectedCard.start_time),
        end_time:
            selectedCard.end_time == null ? null : dayjs(selectedCard.end_time),
        price: selectedCard.price,
        about: selectedCard.about,
        deal_image: selectedCard.deal_image,
        clinic_call: selectedCard.clinic_call,
        last_min_booking: true,
        discount_percentage:
            parseFloat(selectedCard?.discount_percentage) || null,
    });
    const [errorText, setErrorText] = useState('');
    const [errorTextPrice, setErrorTextPrice] = useState('');
    const [warnText, setWarnText] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [currentPicker, setCurrentPicker] = useState(pickers[0]);
    const [isOpenStartDate, setIsOpenStartDate] = useState(false);
    const [isOpenEndDate, setIsOpenEndDate] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const onFinish = async () => {
        setIsLoading(true);
        let cloneDealData = { ...dealData };
        if (!dealData.discount_percentage) {
            cloneDealData.discount_percentage = 0;
        }

        const response = await patchApiWithAuth(
            `${Url.UPDATE_DEAL}${selectedCard.id}/`,
            cloneDealData,
        );
        if (response.success) {
            setIsModalOpen(false);
            enqueueSnackbar(t('updateLastminSuccess'), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'success',
            });
        } else {
            enqueueSnackbar(response.message.data.error, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'error',
            });
        }
        setIsLoading(false);
        setNewCardAdd(true);
    };

    const optimizedFunction = useCallback(debounce(onFinish), [dealData]);

    const onChangeNumber = (event) => {
        const { name, value } = event.target;

        const numberRegix = /^[0-9\b]+$/;

        const inputValue = parseInt(value);
        if (!isNaN(inputValue) && numberRegix.test(value) && inputValue > 0) {
            // if (inputValue > 100) setDealData({ ...dealData, [name]: 100 });
            // else setDealData({ ...dealData, [name]: inputValue });
            if (
                (inputValue === 0 && dealData[name] === 1) || // Allow 0 only after 1
                (inputValue >= 1 && inputValue <= 100) // Allow values between 1 and 100
            ) {
                setDealData({ ...dealData, [name]: inputValue });
            } else {
                setDealData({ ...dealData });
            }
        } else {
            if (value === '') setDealData({ ...dealData, [name]: value });
            else setDealData({ ...dealData });
        }
    };

    const onChangeInput = (event) => {
        const { name, value } = event.target;
        setDealData({ ...dealData, [name]: value });
        if (event.target.name === 'name') {
            if (event.target.value.match(letterRegix)) {
                if (value.length === 25) {
                    setWarnText(t('specialError'));
                } else {
                    setWarnText('');
                }
                setErrorText('');
            } else {
                setErrorText(t('specialError'));
            }
        }
        if (name === 'price') {
            if (!isNaN(value)) {
                setDealData({ ...dealData, [name]: value });
            }
        } else {
            setDealData({ ...dealData, [name]: value });
        }
        if (name === 'count') setDealData({ ...dealData, count: value });
    };

    const onChangeDate = (name, dateString, isMobile = false, picker) => {
        setDealData({ ...dealData, [name]: dateString });

        if (isMobile) {
            if (picker === pickers[0]) {
                setCurrentPicker(pickers[1]);
            } else {
                setIsOpenEndDate(false);
                setIsOpenStartDate(false);
                setCurrentPicker(pickers[0]);
            }
        }
    };

    const onChangeNumberInputPrice = (event) => {
        const { value, name } = event.target;
        const numberRegex = /^[0-9\b]+$/;
        const inputValue = parseInt(value);

        if (numberRegex.test(value) && value.length < 10) {
            if (!isNaN(inputValue) && inputValue > 0) {
                setDealData({ ...dealData, [name]: inputValue.toString() });
                setErrorTextPrice('');
            } else {
                setErrorTextPrice(t('dealPriceError'));
                setDealData({ ...dealData });
            }
        } else {
            if (value === '') {
                setErrorTextPrice(t('dealPriceError'));
                setDealData({ ...dealData, [name]: value });
            } else {
                setDealData({ ...dealData });
            }
        }
    };

    return (
        <>
            <Modal
                open={isModalOpen}
                footer={null}
                closable={true}
                className="dealModal"
                centered
                onCancel={() => setIsModalOpen(false)}
                maskClosable={false}
            >
                <div className="modelContantUpdate">
                    <h1 className="modalDealHeader">{t('updateBooking')}</h1>
                    <hr className="modalDealLine" />
                    <div style={{ margin: '1rem auto' }}>
                        <Form
                            layout="vertical"
                            onFinish={optimizedFunction}
                            form={form}
                            autoComplete="off"
                            initialValues={dealData}
                            fields={[
                                {
                                    name: ['discount_percentage'],
                                    value: dealData.discount_percentage,
                                },

                                {
                                    name: ['price'],
                                    value: dealData.price,
                                },
                            ]}
                        >
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <div className="inputModalText">
                                        <ClinicInput
                                            placeholder={t('bookName')}
                                            type="text"
                                            height={40}
                                            name="name"
                                            onChange={onChangeInput}
                                            maxLength={25}
                                        />
                                    </div>
                                    <div className="errorStyleUpdtee">
                                        {errorText || warnText}
                                    </div>
                                </Col>
                                <Col xs={24}>
                                    <div className="inputModalText">
                                        <ClinicInput
                                            type="textarea"
                                            name="about"
                                            placeholder={t('dealAboutBook')}
                                            onChange={onChangeInput}
                                            textAreaMaxRow={3}
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <div>
                                        <ClinicInput
                                            placeholder={t('dealBookingPlace')}
                                            height={30}
                                            name="price"
                                            Value={dealData.price}
                                            onChange={onChangeNumberInputPrice}
                                        />
                                        {errorTextPrice !== '' ? (
                                            <div className="errorStyleUpdateModel">
                                                {' '}
                                                {errorTextPrice}
                                            </div>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <div className="inputModalText">
                                        <ClinicInput
                                            placeholder={t('dealDiscountDeal')}
                                            height={30}
                                            width={100}
                                            name="discount_percentage"
                                            onChange={onChangeNumber}
                                            disabled={dealData.price === 0}
                                            Value={
                                                dealData?.discount_percentage
                                            }
                                            className="pricrUpdate"
                                            required
                                            errorMessage={t('dealPriceError')}
                                        />

                                        {dealData?.discount_percentage > 0 && (
                                            <span
                                                style={{
                                                    color: '#2ed3c5',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {t('notifyLastminDealDiscount')}{' '}
                                                {discountCalculate(
                                                    parseInt(dealData?.price),
                                                    dealData?.discount_percentage,
                                                )}{' '}
                                                (SEK)
                                            </span>
                                        )}
                                    </div>
                                </Col>
                                <Col xs={24}>
                                    {screens.xs ? (
                                        <div className="modalDateContainer">
                                            <DatePicker
                                                className="modalDateLastMin"
                                                name="start_time"
                                                placeholder={t('dealStart')}
                                                value={
                                                    dealData.start_time !== ''
                                                        ? dayjs(
                                                              dealData.start_time,
                                                          )
                                                        : null
                                                }
                                                minuteStep={30}
                                                picker={currentPicker}
                                                format="YYYY-MM-DD HH:mm"
                                                open={isOpenStartDate}
                                                onClick={() => {
                                                    setIsOpenStartDate(
                                                        !isOpenStartDate,
                                                    );
                                                }}
                                                onChange={(date, dateString) =>
                                                    onChangeDate(
                                                        'start_time',
                                                        dateString,
                                                        screens.xs,
                                                        currentPicker,
                                                    )
                                                }
                                                onOk={(selectedDate) => {
                                                    if (!selectedDate) return;

                                                    let currentDate = dayjs();

                                                    let minutes =
                                                        currentDate.date() ===
                                                            selectedDate.date() &&
                                                        currentDate.hour() ===
                                                            selectedDate.hour()
                                                            ? currentDate.minute()
                                                            : selectedDate.minute();

                                                    let value =
                                                        selectedDate.minute(
                                                            minutes > 0
                                                                ? 30
                                                                : 0,
                                                        );
                                                    let dateString =
                                                        value.format(
                                                            'YYYY-MM-DD HH:mm',
                                                        );

                                                    return onChangeDate(
                                                        'start_time',
                                                        dateString,
                                                        screens.xs,
                                                        currentPicker,
                                                    );
                                                }}
                                                getPopupContainer={(
                                                    trigger,
                                                ) => {
                                                    return trigger.parentElement;
                                                }}
                                                inputReadOnly
                                                suffixIcon={
                                                    <ClockCircleOutlined />
                                                }
                                                disabled={dealData.clinic_call}
                                                disabledDate={(current) =>
                                                    current.isBefore(moment())
                                                }
                                                allowClear={false}
                                                disabledTime={(current) => {
                                                    let selectedDate = moment(
                                                        dealData.start_time,
                                                    ).date();
                                                    let hour =
                                                        selectedDate ===
                                                            current?.date() ||
                                                        moment().date() ===
                                                            current?.date()
                                                            ? moment(
                                                                  dealData.start_time,
                                                              ).hour()
                                                            : 0;
                                                    let mins =
                                                        selectedDate ===
                                                            current?.date() ||
                                                        moment().date() ===
                                                            current?.date()
                                                            ? moment(
                                                                  dealData.start_time,
                                                              ).minute() + 1
                                                            : 0;

                                                    return disabledDateTime(
                                                        hour,
                                                        mins,
                                                    );
                                                }}
                                                showNow={false}
                                            />
                                            {/* </Form.Item> */}
                                        </div>
                                    ) : (
                                        <div className="modalDateContainer">
                                            <DatePicker
                                                className="modalDateLastMin"
                                                name="start_time"
                                                placeholder={t('dealStart')}
                                                value={
                                                    dealData.start_time !== ''
                                                        ? dayjs(
                                                              dealData.start_time,
                                                          )
                                                        : null
                                                }
                                                showTime={{
                                                    format: 'HH:mm',
                                                }}
                                                minuteStep={30}
                                                format="YYYY-MM-DD HH:mm"
                                                onChange={(date, dateString) =>
                                                    onChangeDate(
                                                        'start_time',
                                                        dateString,
                                                    )
                                                }
                                                onOk={(selectedDate) => {
                                                    if (!selectedDate) return;

                                                    let currentDate = dayjs();

                                                    let minutes =
                                                        currentDate.date() ===
                                                            selectedDate.date() &&
                                                        currentDate.hour() ===
                                                            selectedDate.hour()
                                                            ? currentDate.minute()
                                                            : selectedDate.minute();

                                                    let value =
                                                        selectedDate.minute(
                                                            minutes > 0
                                                                ? 30
                                                                : 0,
                                                        );
                                                    let dateString =
                                                        value.format(
                                                            'YYYY-MM-DD HH:mm',
                                                        );

                                                    return onChangeDate(
                                                        'start_time',
                                                        dateString,
                                                    );
                                                }}
                                                popupStyle={{
                                                    width: screens.xs
                                                        ? 250
                                                        : 'auto',
                                                    height: screens.xs
                                                        ? 250
                                                        : 'auto',
                                                }}
                                                getPopupContainer={(
                                                    trigger,
                                                ) => {
                                                    return trigger.parentElement;
                                                }}
                                                inputReadOnly
                                                suffixIcon={
                                                    <ClockCircleOutlined />
                                                }
                                                disabled={dealData.clinic_call}
                                                disabledDate={(current) =>
                                                    current.isBefore(moment())
                                                }
                                                allowClear={false}
                                                disabledTime={(current) => {
                                                    let selectedDate = moment(
                                                        dealData.start_time,
                                                    ).date();
                                                    let hour =
                                                        selectedDate ===
                                                            current?.date() ||
                                                        moment().date() ===
                                                            current?.date()
                                                            ? moment(
                                                                  dealData.start_time,
                                                              ).hour()
                                                            : 0;
                                                    let mins =
                                                        selectedDate ===
                                                            current?.date() ||
                                                        moment().date() ===
                                                            current?.date()
                                                            ? moment(
                                                                  dealData.start_time,
                                                              ).minute() + 1
                                                            : 0;

                                                    return disabledDateTime(
                                                        hour,
                                                        mins,
                                                    );
                                                }}
                                                showNow={false}
                                            />
                                            {/* </Form.Item> */}
                                        </div>
                                    )}
                                </Col>

                                <Col xs={24}>
                                    <div
                                        style={{
                                            // marginRight: '10px',
                                            width: '100%',
                                        }}
                                    >
                                        <ClinicButton
                                            height={45}
                                            title={t('update')}
                                            loading={isLoading}
                                            onClick={optimizedFunction}
                                            disabled={
                                                errorText !== '' ||
                                                errorTextPrice !== '' ||
                                                (dealData.clinic_call ===
                                                    false &&
                                                    (dealData.start_time ===
                                                        '' ||
                                                        dealData.end_time ===
                                                            ''))
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default LastMinUpdate;
