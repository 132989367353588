import React, { useEffect, useState } from 'react';
import logo from '../../../assets/images/forget.png';
import { postApiWithoutAuth } from '../../../utils/api';
import { Url } from '../../../utils/apiUrls';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ClinicButton } from '../../commonComponents';
import VerificationInput from 'react-verification-input';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './VerifyCodeStyle.css';
import { Col, Row } from 'antd';
import { langObj } from '../../../utils/helper';

const VerifyCode = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const { state } = location;

    const [otp, setOtp] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleOnChange = (value) => {
        setOtp(value);
    };

    const otpVerification = async () => {
        setIsLoading(true);
        let language = localStorage.getItem('i18nextLng');
        const response = await postApiWithoutAuth(Url.ACTIVATION_URL, {
            email: state.email,
            otp: otp,
            language: langObj[language ?? 'sew'],
        });
        if (response.success) {
            setIsLoading(false);
            navigate('/login');
            enqueueSnackbar(t('emailVerify'), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'success',
            });
        } else {
            enqueueSnackbar(response.message.data.error, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'error',
            });
            setIsLoading(false);
        }
    };

    const resendOtp = async () => {
        setIsLoading(true);
        const response = await postApiWithoutAuth(Url.RESEND_CODE, {
            email: state.email,
            otp: otp,
        });
        if (response.success) {
            setIsLoading(false);
            enqueueSnackbar('Resend request sent successfully', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'success',
            });
        } else {
            enqueueSnackbar(response.message.data.error, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'error',
            });
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (state?.previousPath === 'Login') {
            resendOtp();
        }
    }, []);

    return (
        <main className="mainContainerCode">
            <Row justify="center" style={{ height: '100%' }} align="middle">
                <Col xs={22} sm={16} md={14} lg={10} xl={8}>
                    <div className="firstContainerCode">
                        <Row justify={'center'}>
                            <Col span={24}>
                                <div className="resetImageContainer2">
                                    <img className="verifyLogo" src={logo} />
                                    <h1 className="codeHeading">Gnizzel</h1>
                                    <h1> {`${t('verify')}`}</h1>
                                </div>
                            </Col>
                            <Col span={20}>
                                <div className="codeBar">
                                    <VerificationInput
                                        length={4}
                                        loading={false}
                                        onChange={(e) => handleOnChange(e)}
                                        name="otp"
                                        values={otp}
                                    />
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="updateCode">
                                    <ClinicButton
                                        title={`${t('verifyCode')}`}
                                        loading={isLoading}
                                        height={40}
                                        onClick={otpVerification}
                                    />
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="outerResend">
                                    <div className="resend" onClick={resendOtp}>
                                        {`${t('resend')}`}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </main>
    );
};

export default VerifyCode;
