import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/Translator/i18n';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <CloseOutlined
            style={{
                fontSize: '12px',
                position: 'absolute',
                top: '1.5em',
                marginLeft: '1em',
                right: '1em',
            }}
            onClick={() => closeSnackbar(snackbarKey)}
        />
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <SnackbarProvider
            action={(snackbarKey) => (
                <SnackbarCloseButton snackbarKey={snackbarKey} />
            )}
        >
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </SnackbarProvider>
    </React.StrictMode>,
);

reportWebVitals();
