import { useContext } from 'react';
import { useState } from 'react';
import { createContext } from 'react';

export const SignupContext = createContext({
    params: {},
});

export const SignupContextProvider = ({ children }) => {
    const [payload, setPayload] = useState({ role: 'clinic' });
    return (
        <SignupContext.Provider value={{ payload, setPayload }}>
            {children}
        </SignupContext.Provider>
    );
};

export const useSignupContext = () => useContext(SignupContext);
