import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';

import { Url } from '../../../utils/apiUrls';
import { ClinicInput } from '../../commonComponents';
import { ClinicButton } from '../../commonComponents';
import { postApiWithoutAuth } from '../../../utils/api';

import logo from '../../../assets/images/forget.png';

import './ResetPassword.css';
import { useEffect } from 'react';

const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,16}$/;

const ResetPassword = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();

    const [errorText2, setErrorText2] = useState('');
    const [errorText, setErrorText] = useState('');
    const [userData, setUserData] = useState({
        code: code,
        email: '',
        password: '',
        confirm_password: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const navigation = () => {
        navigate('/');
    };

    const postData = async () => {
        const response = await postApiWithoutAuth(
            Url.RESET_PASSWORD_URL,
            userData,
        );
        setIsLoading(true);
        if (response.success) {
            navigate('/');
            setIsLoading(false);
            enqueueSnackbar(t('resetSuccess'), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'success',
            });
        } else {
            enqueueSnackbar(response.message.data.error, {
                anchorOrigin: {
                    vertical: 'right',
                    horizontal: 'top',
                },
                variant: 'error',
            });
            setIsLoading(false);
        }
    };

    const handleOnChange = (event) => {
        const { name, value } = event.target;
        setUserData({ ...userData, [name]: value });
        if (event.target.name == 'password') {
            if (event.target.value.match(passwordRegex)) {
                setErrorText2('');
            } else {
                setErrorText2(t('passwordError'));
            }
        } else {
            if (event.target.value.match(passwordRegex)) {
                setErrorText('');
            } else {
                setErrorText(t('passwordError'));
            }
        }
    };

    const booleanValue = false;

    return (
        <>
            <div className="mainContainerResetPassword">
                <Row align="top" justify="space-around" gutter={[0, 32]}>
                    <Col xs={24} md={8} lg={12}>
                        <div className="firstContainerReset">
                            <div className="resetImageContainer">
                                <img
                                    className="resetLogo"
                                    src={logo}
                                    alt="resetLogo"
                                />
                                <h1 className="resetHeading">Gnizzel</h1>
                            </div>
                        </div>
                    </Col>
                    <Col
                        xs={24}
                        lg={12}
                        md={16}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                        }}
                    >
                        <div className="resetCard">
                            <div className="innerCard">
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <h1 className="resetPasswordHeading">
                                            {t('createNew')}
                                        </h1>
                                    </Col>
                                    <Col span={24}>
                                        <ClinicInput
                                            placeholder={`${t('enterPass')}`}
                                            type={'password'}
                                            name={'password'}
                                            isRequired={
                                                userData.password === ''
                                                    ? !booleanValue
                                                    : booleanValue
                                            }
                                            Value={userData.password}
                                            requiredErrorMessage={''}
                                            isRequiredType={'password'}
                                            isRequiredTypeMessgae={'password'}
                                            onChange={handleOnChange}
                                            height={35}
                                        />
                                        <div className="errorStyleReset">
                                            {' '}
                                            {errorText2}
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <ClinicInput
                                            placeholder={`${t('confirmPass')}`}
                                            type={'password'}
                                            name={'confirm_password'}
                                            isRequired={
                                                userData.confirm_password === ''
                                                    ? !booleanValue
                                                    : booleanValue
                                            }
                                            Value={userData.password}
                                            isRequiredType={'confirm_password'}
                                            isRequiredTypeMessgae={
                                                'confirm_password'
                                            }
                                            onChange={handleOnChange}
                                            height={35}
                                        />
                                        <div className="errorStyleReset">
                                            {errorText}
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <ClinicButton
                                            title={`${t('updatePass')}`}
                                            onClick={postData}
                                            isLoading={isLoading}
                                            disabled={
                                                errorText2 !== '' ||
                                                errorText !== '' ||
                                                userData.password === '' ||
                                                userData.confirm_password ===
                                                    '' ||
                                                userData.password !==
                                                    userData.confirm_password
                                            }
                                        />
                                    </Col>
                                    <Col span={24} className="footerArrow">
                                        <h1
                                            className="footerArrowText"
                                            onClick={navigation}
                                        >
                                            {t('backLogin')}
                                        </h1>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default ResetPassword;
