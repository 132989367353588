import React, { useEffect } from 'react';
import { Modal, Form, Spin, Input, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ClinicButton } from '../../commonComponents';
import { useState } from 'react';
import { postApiWithAuth } from '../../../utils/api';
import { getApiWithAuth } from '../../../utils/api';
import { Url } from '../../../utils/apiUrls';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const AttachUserModal = ({
    isModalOpen,
    setIsModalOpen,
    userId,
    setUserId,
    getListing,
    getDealData,
}) => {
    AttachUserModal.propTypes = {
        isModalOpen: PropTypes.bool,
        setIsModalOpen: PropTypes.func,
        setNewCardAdd: PropTypes.func,
        userId: PropTypes.number,
        setUserId: PropTypes.func,
        getListing: PropTypes.func,
        getDealData: PropTypes.func,
    };

    AttachUserModal.defaultProps = {
        setIsModalOpen: () => {},
        isModalOpen: false,
        setNewCardAdd: () => {},
        getListing: () => {},
    };

    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { itemId } = useParams();
    const [userInfoLoading, setUserInfoLoading] = useState(false);
    const [dealData, setDealData] = useState({
        deal_id: itemId,
        user_info: {
            id: '',
            email: '',
            role: 'customer',
            first_name: '',
            last_name: '',
            address: '',
            language: 'english',
            clinic_name: '',
            is_active: true,
            phone_number: '',
            on_boarding: false,
            customer_id: '',
        },
    });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const postData = async () => {
        setIsLoading(true);
        const response = await postApiWithAuth(Url.ATTACH_USER, dealData);
        if (response.success) {
            setIsLoading(false);
            setIsModalOpen(false);
            getListing();
            getDealData();
            setUserId((pre) => undefined);
        } else {
            setIsLoading(false);
            setIsModalOpen(false);
            enqueueSnackbar(response.message.data.error, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'error',
            });
        }
    };

    const getData = async () => {
        setUserInfoLoading(true);
        setError(null);
        const response = await getApiWithAuth(`${Url.USER_INFO}${userId}`);
        if (response.success) {
            setDealData({ ...dealData, user_info: response.data });
        } else {
            setError(true);
        }
        setUserInfoLoading(false);
    };

    useEffect(() => {
        isModalOpen && getData();
    }, [isModalOpen]);

    return (
        <>
            <div className="modelScroll">
                <Modal
                    open={isModalOpen}
                    footer={false}
                    className="dealModal"
                    onCancel={() => setIsModalOpen(!isModalOpen)}
                    maskClosable={false}
                >
                    <div className="modelContantCreate">
                        <h1 className="modalDealHeader">{t('userInfo')}</h1>
                        <hr className="modalDealLine" />
                        {userInfoLoading ? ( // userInfoLoading
                            <div className="loaderContainer">
                                <Spin />
                            </div>
                        ) : error ? ( // error
                            <div className="loaderContainer">
                                <p>{t('userInvalidId')}</p>
                            </div>
                        ) : (
                            <Form
                                layout="vertical"
                                onFinish={postData}
                                form={form}
                                autoComplete="off"
                            >
                                <Row gutter={[8, 8]}>
                                    <Col xs={24}>
                                        <div className="inputModal">
                                            <Input
                                                disabled
                                                style={{ height: 30 }}
                                                className="inputStyle"
                                                placeholder={t('userId')}
                                                name="id"
                                                maxLength={25}
                                                value={
                                                    dealData.user_info
                                                        .customer_id
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className="inputModal">
                                            <Input
                                                disabled
                                                placeholder={t('firstName')}
                                                name="first_name"
                                                maxLength={25}
                                                style={{ height: 30 }}
                                                className="inputStyle"
                                                value={
                                                    dealData.user_info
                                                        .first_name
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className="inputModal">
                                            <Input
                                                disabled
                                                placeholder={t('surName')}
                                                name="last_name"
                                                maxLength={25}
                                                style={{ height: 30 }}
                                                className="inputStyle"
                                                value={
                                                    dealData.user_info.last_name
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className="inputModal">
                                            <Input
                                                disabled
                                                placeholder={t('Email')}
                                                style={{ height: 30 }}
                                                className="inputStyle"
                                                name="email"
                                                value={dealData.user_info.email}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className="inputModal">
                                            <Input
                                                disabled
                                                placeholder={t('phoneNumber')}
                                                name="phone_number"
                                                maxLength={25}
                                                style={{ height: 30 }}
                                                className="inputStyle"
                                                value={
                                                    dealData.user_info
                                                        .phone_number ??
                                                    t('phoneNotAvailable')
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <div className="inputModal">
                                            <ClinicButton
                                                height={35}
                                                title={t('useDeal')}
                                                loading={isLoading}
                                                buttonType={'formButton'}
                                                onClick={postData}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <div className="inputModal">
                                            <ClinicButton
                                                height={35}
                                                title={t('cancel')}
                                                loading={false}
                                                onClick={() => {
                                                    setIsModalOpen(false);
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {/* <div className="inputModal">
                                    <Input
                                        disabled
                                        style={{ height: 30 }}
                                        className="inputStyle"
                                        placeholder={t('userId')}
                                        name="id"
                                        maxLength={25}
                                        value={dealData.user_info.customer_id}
                                    />
                                </div> */}
                                {/* <div className="inputModal">
                                    <Input
                                        disabled
                                        placeholder={t('firstName')}
                                        name="first_name"
                                        maxLength={25}
                                        style={{ height: 30 }}
                                        className="inputStyle"
                                        value={dealData.user_info.first_name}
                                    />
                                </div> */}
                                {/* <div className="inputModal">
                                    <Input
                                        disabled
                                        placeholder={t('surName')}
                                        name="last_name"
                                        maxLength={25}
                                        style={{ height: 30 }}
                                        className="inputStyle"
                                        value={dealData.user_info.last_name}
                                    />
                                </div> */}
                                {/* <div className="inputModal">
                                    <Input
                                        disabled
                                        placeholder={t('Email')}
                                        style={{ height: 30 }}
                                        className="inputStyle"
                                        name="email"
                                        value={dealData.user_info.email}
                                    />
                                </div> */}
                                {/* <div className="inputModal">
                                    <Input
                                        disabled
                                        placeholder={t('phoneNumber')}
                                        name="phone_number"
                                        maxLength={25}
                                        style={{ height: 30 }}
                                        className="inputStyle"
                                        value={
                                            dealData.user_info.phone_number ??
                                            t('phoneNotAvailable')
                                        }
                                    />
                                </div> */}

                                <div className="modalButtonContainer">
                                    {/* <div className="modelButton">
                                        <ClinicButton
                                            height={35}
                                            title={t('useDeal')}
                                            loading={isLoading}
                                            buttonType={'formButton'}
                                            onClick={postData}
                                        />
                                    </div> */}
                                    {/* <div className="modelButton">
                                        <ClinicButton
                                            height={35}
                                            title={t('cancel')}
                                            loading={false}
                                            onClick={() => {
                                                setIsModalOpen(false);
                                            }}
                                        />
                                    </div> */}
                                </div>
                            </Form>
                        )}
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default AttachUserModal;
