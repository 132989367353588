import React, { useState, useEffect } from 'react';
import logo from '../../../assets/images/forget.png';
import { postApiWithoutAuth } from '../../../utils/api';
import { Url } from '../../../utils/apiUrls';
import { useSnackbar } from 'notistack';
import { ClinicInput } from '../../commonComponents';
import { ClinicButton } from '../../commonComponents';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Form, Row, Grid } from 'antd';
import './ForgetPasswordStyle.css';
import { langObj } from '../../../utils/helper';

const { useBreakpoint } = Grid;

const ForgetPassword = () => {
    const navigate = useNavigate();
    const screens = useBreakpoint();
    const { enqueueSnackbar } = useSnackbar();
    const [errorText, setErrorText] = useState('');
    const [form] = Form.useForm();
    const [userData, setUserData] = useState({
        email: '',
        is_mobile_user: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const emailRegex =
        /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\wÅÄÖåäö-]+\.)*\w[\w-ÅÄÖåäö]{0,66})\.([a-zA-Z]{2,6}(?:\.[a-zA-Z-å ]{2})?|\u00E4\u00E5\u00F6\u00C4\u00C5\u00D6)$/i;

    const navigation = () => {
        navigate('/');
    };
    const { t } = useTranslation();

    const handleOnChange = (event) => {
        const { name, value } = event.target;
        setUserData({ ...userData, [name]: value });
        if (event.target.name === 'email') {
            if (event.target.value.match(emailRegex)) {
                setErrorText('');
            } else {
                setErrorText(`${t('emailError')}`);
            }
        }
    };

    const postData = async () => {
        setIsLoading(true);
        let language = localStorage.getItem('i18nextLng');
        try {
            const response = await postApiWithoutAuth(Url.FORGET_PASSWORD_URL, {
                ...userData,
                language: langObj[language ?? 'sew'],
            });
            if (response.success) {
                setIsLoading(false);
                enqueueSnackbar(t('forgetPas'), {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top',
                    },
                    variant: 'success',
                });

                setUserData({
                    email: '',
                    is_mobile_user: false,
                });
            } else {
                enqueueSnackbar(response.message.data.error, {
                    anchorOrigin: {
                        vertical: 'right',
                        horizontal: 'top',
                    },
                    variant: 'error',
                });
                setIsLoading(false);
            }
        } catch (err) {
            console.log('Network error');
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        form.setFieldsValue(userData);
    }, [form, userData]);

    return (
        <>
            <div className="mainContainerForgetPassword">
                <Row align="top" justify="space-around">
                    <Col xs={24} md={10} lg={12}>
                        <div className="signupImageContainer">
                            <div className="logoSignup">
                                <div className="logoContainer">
                                    <Link to={'/'}>
                                        <img src={logo} alt="Gnizzel Logo" />
                                    </Link>
                                </div>
                                <h1 className="signupHeading">Gnizzel</h1>
                            </div>
                        </div>
                    </Col>
                    <Col
                        xs={24}
                        md={14}
                        lg={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: screens.md ? '100vh' : 'unset',
                            margin: '1em auto',
                        }}
                    >
                        <div className="forgotPasswordWrapper">
                            <Row
                                // gutter={[16, 5]}
                                justify={'center'}
                                className="forgetCard"
                            >
                                <Col span={24}>
                                    <h1 className="forgotPasswordHeading">
                                        {t('forgotPass')}
                                    </h1>
                                    <p className="forgetPara">
                                        {t('forgetPara')}
                                    </p>
                                </Col>
                                <Col span={24}>
                                    <Form
                                        layout="vertical"
                                        form={form}
                                        autoComplete="off"
                                        initialValues={userData}
                                    >
                                        <Row
                                            gutter={[
                                                8,
                                                errorText !== '' ? 8 : 16,
                                            ]}
                                        >
                                            <Col span={24}>
                                                <ClinicInput
                                                    type={'text'}
                                                    placeholder={`${t(
                                                        'Email',
                                                    )}`}
                                                    name={'email'}
                                                    value={userData.email}
                                                    onChange={handleOnChange}
                                                    height={35}
                                                />
                                                <span className="errorStyleForget">
                                                    {errorText}
                                                </span>
                                            </Col>
                                            <Col span={24}>
                                                <ClinicButton
                                                    disabled={
                                                        userData.email === '' ||
                                                        errorText !== ''
                                                    }
                                                    title={`${t('request')}`}
                                                    onClick={postData}
                                                    loading={isLoading}
                                                    height={40}
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                                <Col span={24}>
                                    <div
                                        className="footerArrow"
                                        onClick={navigation}
                                    >
                                        <h1 className="footerArrowText">{`${t(
                                            'backLogin',
                                        )}`}</h1>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default ForgetPassword;
