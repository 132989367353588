import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Modal, Rate, Row, Grid } from 'antd';
import FAQ from '../../commonComponents/FAQ';
import { Url } from '../../../utils/apiUrls';
import { useTranslation } from 'react-i18next';
import { getApiWithAuth } from '../../../utils/api';
import ClinicSummary1 from '../../../assets/images/clinic-summary1.svg';
import ClinicSummary2 from '../../../assets/images/clinic-summary2.svg';
import ClinicSummary3 from '../../../assets/images/clinic-summary3.svg';
import { AnimatedNumber } from '../../commonComponents';
import { Images } from '../../../assets/images';
import 'animate.css';
import './DashboardStyle.css';
import { PDFDownloadLink } from '@react-pdf/renderer';
import useUser from '../../../hooks/useUser';
import QRDownload from '../QRDownload/QRDownload';
import { nameResolver } from '../../../utils/helper';

const splitLength = 2;
const len = 122;

const { useBreakpoint } = Grid;

function truncate(str = '', n) {
    return str?.length > n ? str?.slice(0, n - 1) + '...' : str;
}

const Dashboard = () => {
    const { i18n, t } = useTranslation();
    const screen = useBreakpoint();
    const { userData } = useUser();
    const { state, pathname } = useLocation();
    const dashboardRef = useRef(null);

    const summariesRef = useRef(null);
    const faqRef = useRef(null);

    const clinicSteps = [
        {
            key: 1,
            title: t('step1'),
            details: t('dealClick'),
        },
        {
            key: 2,
            title: t('step2'),
            details: t('dealKey'),
        },
        {
            key: 3,
            title: t('step3'),
            details: t('dealSubKey'),
        },
    ];

    const [dashboardData, setDashboardData] = useState();
    const [showModel, setShowModel] = useState(false);
    const [renderNow, setRenderNow] = useState(false);
    const [showShadow, setShowShadow] = useState(false);
    const clinicSummaries = [
        {
            key: 1,
            icon: ClinicSummary1,
            title: t('noOfClinic'),
            figure: dashboardData ? dashboardData['Total clinics'] : '00',
        },
        {
            key: 2,
            icon: ClinicSummary2,
            title: t('cliPat'),
            figure: dashboardData?.patients_count,
        },
        {
            key: 3,
            icon: ClinicSummary3,
            title: t('totalPat'),
            figure: dashboardData?.patients,
        },
    ];

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setRenderNow(true);
                }
            });
        });

        if (summariesRef.current) {
            observer.observe(summariesRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    const textAreaCal = (text = '') =>
        text.length > len ? text?.slice(0, len - 1) : text;

    const getData = async () => {
        const response = await getApiWithAuth(Url.DASHBOARD);
        if (response?.success) {
            setDashboardData(response.data);
        }
    };

    const addLineBreaksWithCal = (text = []) => {
        let convertedText = text;
        let convertedTextLength = 0;
        if (convertedText.length > splitLength) {
            convertedTextLength = text
                .split('\n')
                .splice(0, splitLength).length;
            convertedText = text
                .split('\n')
                .splice(0, splitLength)
                .map((line, index) => (
                    <React.Fragment key={index}>
                        {line}{' '}
                        {index === convertedTextLength - 1 && (
                            <span
                                style={{
                                    color: '#7ce4db',
                                    cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                    setShowModel(true);
                                }}
                            >
                                {' '}
                                {t('readMore')}
                            </span>
                        )}
                        <br />
                    </React.Fragment>
                ));
        }

        return convertedText;
    };

    const addLineBreaks = (text = []) => {
        if (text.length === 0) return;
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    const onMouseHandler = (hasImage = true) => {
        if (hasImage) return;
        setShowShadow(!showShadow);
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        dashboardRef.current.focus();
        window.scrollTo(0, 0);
    }, [state, pathname]);

    useEffect(() => {
        if (state && state?.faq) {
            faqRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [state, pathname]);

    return (
        <main
            id="dashboardView"
            tabIndex="0"
            style={{
                border: 'none',
            }}
            ref={dashboardRef}
        >
            <div className={screen.lg ? 'dashboard-lg-view' : 'dashboard'}>
                <Row gutter={[32, 16]} justify="start">
                    <Col xs={24}>
                        <div className="greeting-name">
                            {t('hi')}{' '}
                            <span>
                                {truncate(
                                    dashboardData?.clinic?.company_name,
                                    20,
                                )}
                            </span>
                        </div>
                        <div className="welcome-text">
                            {t('welcomeBack')} <span className="wave">👋</span>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={18}>
                        <PDFDownloadLink
                            document={
                                <QRDownload
                                    company_name={userData.company_name}
                                    about={userData.about}
                                    profile={userData.profile}
                                    id={userData.id}
                                />
                            }
                            fileName={nameResolver(
                                userData.company_name,
                            ).concat('.pdf')}
                        >
                            {({ blob, url, loading, error }) => (
                                <img
                                    src={
                                        i18n.language === 'eng'
                                            ? Images.englishBanner
                                            : Images.sewdishBanner
                                    }
                                    alt="Clinic Icon"
                                    className="clinic-ratings-icon" //"animate__slow animate__animated animate__fadeIn"
                                />
                            )}
                        </PDFDownloadLink>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6}>
                        <div className="clinic-ratings">
                            <div className="clinic-ratings-cont">
                                <div
                                    className="clinic-ratings-cont-image"
                                    style={{
                                        position: 'relative',
                                    }}
                                    onMouseEnter={(event) =>
                                        onMouseHandler(
                                            dashboardData?.clinic?.profile !==
                                                null,
                                        )
                                    }
                                    onMouseLeave={(event) =>
                                        onMouseHandler(
                                            dashboardData?.clinic?.profile !==
                                                null,
                                        )
                                    }
                                >
                                    <img
                                        src={
                                            dashboardData?.clinic?.profile ??
                                            Images.userProfile
                                        }
                                        alt="Clinic Profile Icon"
                                        className="clinic-ratings-cont-icon"
                                    />
                                    {showShadow ? (
                                        <span className="dashboard_shadow_wrapper">
                                            <p className="dashboard_shadow_wrapper_paragraph">
                                                <Link
                                                    to="/clinicsettings"
                                                    className="dashboard_shadow_wrapper_link"
                                                >
                                                    {t('uploadImage')}
                                                </Link>
                                            </p>
                                        </span>
                                    ) : null}
                                </div>
                                <div className="clinic-ratings-cont-name">
                                    {dashboardData?.clinic?.company_name}
                                </div>
                                <Rate
                                    disabled
                                    className="clinic-ratings-stars star-color"
                                    value={dashboardData?.clinic?.rating}
                                />
                                {/* <div> */}
                                {/* </div> */}
                                <div className="below-line"></div>
                                <div className="clinic-ratings-cont-details">
                                    {/* {textAreaCal(dashboardData?.clinic?.about)} */}
                                    {dashboardData?.clinic?.about == null ||
                                    dashboardData?.clinic?.about.length === 0
                                        ? textAreaCal(
                                              t('dashboardAboutPlaceholder'),
                                          )
                                        : addLineBreaksWithCal(
                                              textAreaCal(
                                                  dashboardData?.clinic?.about,
                                              ),
                                          )}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="clinic-summary-title">
                            {`${t('clinicSummary')}`}
                        </div>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[32, 16]}>
                            {clinicSummaries.map((item, index) => {
                                return (
                                    <Col lg={8} xs={24} md={12} key={index}>
                                        <div className="clinic-summary-item">
                                            {/* <div className="clinic-summary-image"> */}
                                            <img
                                                className="clinic-summary-image"
                                                src={item.icon}
                                                alt="Icons"
                                                height={45}
                                                width={45}
                                            />
                                            {/* </div> */}
                                            <div className="clinic-summary-disc">
                                                <span
                                                    ref={summariesRef}
                                                    className="clinic-summary-figure"
                                                >
                                                    {!isNaN(item.figure) &&
                                                        renderNow && (
                                                            <AnimatedNumber
                                                                targetNumber={
                                                                    item.figure
                                                                }
                                                            />
                                                        )}
                                                </span>
                                                <div className="clinic-summary-details">
                                                    {item.title}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                    <Col span={24}>
                        <div className="clinic-summary-title">
                            {t('clinicStep')}
                        </div>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[32, 16]}>
                            {clinicSteps.map((item) => {
                                return (
                                    <Col
                                        xs={24}
                                        md={12}
                                        lg={8}
                                        xl={8}
                                        xxl={8}
                                        key={item.key}
                                    >
                                        <div className="steps-item">
                                            <div className="steps-item-title">
                                                {item.title}
                                            </div>
                                            <div className="steps-item-details">
                                                {item.details}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
            </div>
            <Row ref={faqRef}>
                <Col span={24} className="faq" style={{ marginTop: '1rem' }}>
                    <div
                        className={
                            screen.lg ? 'dashboard-lg-view' : 'dashboard-faq'
                        }
                    >
                        <div className="faq-title">{`${t('freqAsk')}`}</div>
                        <FAQ />
                    </div>
                </Col>
            </Row>
            <Modal
                open={showModel}
                closable
                footer={null}
                centered
                style={{ margin: '1rem 0' }}
                onCancel={() => setShowModel(false)}
            >
                <div className="modalReviewWidth">
                    {dashboardData?.clinic?.about === null
                        ? 'No data available'
                        : addLineBreaks(dashboardData?.clinic?.about)}
                </div>
            </Modal>
        </main>
    );
};
export default Dashboard;
