import React from "react";
import { Navigate, useSearchParams } from 'react-router-dom';
import { getToken } from './localStorage';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const PublicRoute = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { i18n } = useTranslation();
    const lang = searchParams.get('language');
    useEffect(() => {
        const changeLanguageWithDelay = async () => {
            if (lang) {
                await new Promise((resolve) => {
                    const timeoutId = setTimeout(() => {
                        i18n.changeLanguage(
                            lang.slice(0, 3) === 'eng' ? 'eng' : 'sew',
                        );
                        resolve();
                    }, 1000);

                    return () => clearTimeout(timeoutId);
                });
            }
        };

        changeLanguageWithDelay();
    }, [lang]);
    return getToken() ? <Navigate to="/dashboard" /> : children;
};

export default PublicRoute;
