import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pagination, Rate, Spin, Row, Col, Grid } from 'antd';

import { getApiWithAuth } from '../../../utils/api';
import { Url } from '../../../utils/apiUrls';
import { useTranslation } from 'react-i18next';
import ReviewSVG from '../../../assets/images/review.svg';
import { ClinicButton } from '../../commonComponents';

import './Reviews.css';

const limit = 10;

const { useBreakpoint } = Grid;

const maximun = 0;

const Reviews = () => {
    const screen = useBreakpoint();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const pageRef = useRef(null);

    const [data, setData] = useState([]);
    const [totalNo, setTotalNo] = useState(0);
    const [pageNo, setPageNo] = useState(2);

    const [isLoading, setIsLoading] = useState(true);

    const getData = async (page = 1) => {
        try {
            let urlParams = Url.REVIEW + `page=${page}&page_size=${limit}`;

            const response = await getApiWithAuth(urlParams);
            if (response.success) {
                const { data, total, page } = response.data;
                setData(data);
                setTotalNo(total);
                setPageNo(page);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };

    const addLineBreaks = (text) => {
        if (typeof text !== 'string') return;
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    useEffect(() => {
        getData();
    }, []);

    useLayoutEffect(() => {
        pageRef.current?.focus();
        window.scrollTo(0, 0);
    }, []);

    return (
        <main ref={pageRef} tabIndex="0">
            <div
                className={
                    screen.lg
                        ? 'mainContainerReviews-lg-view'
                        : 'mainContainerReviews'
                }
                tabIndex={4}
            >
                <div className="hrLineReview"></div>
                <div className="reviewContainer">
                    {isLoading ? (
                        <div className="spinnerDiv">
                            <Spin size="large" />
                        </div>
                    ) : Array.isArray(data) && data.length > maximun ? (
                        <div className="reviewListContainer">
                            <div>
                                <Row gutter={[20, 32]} justify={'center'}>
                                    {data.map((item, index) => (
                                        <Col
                                            xs={24}
                                            md={12}
                                            xxl={6}
                                            key={index}
                                        >
                                            <div className="secondReviewContainer">
                                                <div className="reviewOuterBorder">
                                                    <div className="containerReview">
                                                        <div className="headingStar">
                                                            {item.customer_name}
                                                        </div>
                                                        <div>
                                                            <Rate
                                                                disabled
                                                                count={5}
                                                                className="rating"
                                                                value={
                                                                    item.count
                                                                }
                                                            />
                                                        </div>
                                                        <div className="starPara">
                                                            {addLineBreaks(
                                                                item.comment,
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </div>
                    ) : (
                        <Row>
                            <Col span={24}>
                                <div className="reviewImageContainter">
                                    <div className="reviewImageWrapper">
                                        <img
                                            src={ReviewSVG}
                                            onLoadStart={() => {
                                                setIsLoading(true);
                                            }}
                                            onLoadedData={() => {
                                                setIsLoading(false);
                                            }}
                                            className={
                                                screen.sm
                                                    ? 'reviewImage-lg-view'
                                                    : 'reviewImage'
                                            }
                                            alt="Review Not Found"
                                        />
                                    </div>
                                    <div className="headingContainerReview">
                                        <h1 className="customerReview">
                                            {t('NoReviewsAvailable')}
                                        </h1>
                                        <h1 className="customerRev">
                                            {t('clientSay')}
                                        </h1>
                                    </div>
                                    <div className={'goBackBtnRev'}>
                                        <ClinicButton
                                            title={`${t('goback')}`}
                                            onClick={() => {
                                                navigate(-1);
                                            }}
                                            className="goBackBtn"
                                            height={50}
                                            width={200}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '1.5rem 0',
                    }}
                >
                    {Array.isArray(data) && data.length > maximun && (
                        <div className="paginationContainer">
                            <Pagination
                                total={totalNo}
                                showTotal={(total) =>
                                    `${t('total')} ${total} ${t('items')}`
                                }
                                defaultPageSize={limit}
                                pageSize={limit}
                                current={pageNo}
                                defaultCurrent={pageNo}
                                onChange={(page, pageSize) => {
                                    setIsLoading(!isLoading);
                                    getData(page);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </main>
    );
};

export default Reviews;
