function range (start, end) {
    const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

const disabledDateTime = (hour = 24, min = 60) => {
    return {
        disabledHours: () => range(0, hour),
        disabledMinutes: () => range(0, min),
        disabledSeconds: () => [55, 56],
    };
};

const discountCalculate = (value, discountPercentage) => {
    const discountedvalue = value - value * (discountPercentage / 100);
    return parseInt(discountedvalue);
};

const nameResolver = (name) => {
    if (typeof name !== 'string') return 'clinic-name';
    return name.split(' ').join('-');
};

export const pickers = ['date', 'time'];
const langObj = {
    sew: 'sweden',
    eng: 'english',
};

const debounce = (func, time = 250) => {
    let timer;
    return function (...args) {
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
        }, time);
    };
};

export { disabledDateTime, discountCalculate, nameResolver, langObj, debounce };