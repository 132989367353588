import { Collapse } from "antd";
import { useTranslation } from 'react-i18next';
import "./FAQ.css";

const { Panel } = Collapse;

const FAQ = () => {
  const onChange = (key) => {};
    const { t } = useTranslation();

    const textOne = t('ansFaq')
    const textTwo = t('ansTwo')
    const textThree = t('ansThree')
    const textFour = t('ansFour')
    const textFive = t('ansFive')
    const textSix = t('ansSix')
    const textSeven = t('ansSeven')
    const textEight = t('ansEight')
    const textNine = t('ansNine')
    const textTen = t('ansTen')
    const textEleven = t('ansEleven')
    const textTweleve = t('ansTwelve')
    const textThirteen = t('ansThirteen')
    const textFourteen = t('ansFourteen');
    const textFifteen = t('ansFifteen');
    const textSixteen = t('ansSixteen');
    const textSeventeen = t('ansSeventeen');
    const textEighteen = t('ansEighteen');
    const textNineteen = t('ansNineteen');
    const textTwenty = t('ansTwenty');

    const items = [
        {
            key: '1',
            label: t('faq'),
            children: textOne,
        },
        {
            key: '2',
            label: t('secondFaq'),
            children: textTwo,
        },
        {
            key: '3',
            label: t('thirdFaq'),
            children: textThree,
        },
        {
            key: '4',
            label: t('forthFaq'),
            children: textFour,
        },
        {
            key: '5',
            label: t('fifthFaq'),
            children: textFive,
        },
        {
            key: '6',
            label: t('sixFaq'),
            children: textSix,
        },
        {
            key: '7',
            label: t('sevenFaq'),
            children: textSeven,
        },
        {
            key: '8',
            label: t('eiFaq'),
            children: textEight,
        },
        {
            key: '9',
            label: t('nineFaq'),
            children: textNine,
        },
        {
            key: '10',
            label: t('tenFaq'),
            children: textTen,
        },
        {
            key: '11',
            label: t('elevenFaq'),
            children: textEleven,
        },
        {
            key: '12',
            label: t('twelveFaq'),
            children: textTweleve,
        },
        {
            key: '13',
            label: t('thirteenFaq'),
            children: textThirteen,
        },
        {
            key: '14',
            label: t('fourteenFaq'),
            children: textFourteen,
        },
        {
            key: '15',
            label: t('fifteenFaq'),
            children: textFifteen,
        },
        {
            key: '16',
            label: t('sixteenFaq'),
            children: textSixteen,
        },
        {
            key: '17',
            label: t('seventeenFaq'),
            children: textSeventeen,
        },
        {
            key: '18',
            label: t('eighteenFaq'),
            children: textEighteen,
        },
        {
            key: '19',
            label: t('nineteenFaq'),
            children: textNineteen,
        },
        {
            key: '20',
            label: t('twentyFaq'),
            children: textTwenty,
        },
    ];
  return (
      <>
          <Collapse
              onChange={onChange}
              expandIconPosition={'end'}
              className="dashboard-faq"
          >
              {items.map((item, index) => (
                  <Panel header={item.label} key={index}>
                      <p>{item.children}</p>
                  </Panel>
              ))}
          </Collapse>
      </>
  );
};
export default FAQ;
