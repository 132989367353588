import React from 'react';
import { Button, Grid } from 'antd';
import './ClinicButtonStyle.css';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

const { useBreakpoint } = Grid;

const ClinicButton = ({
    loading,
    onClick,
    title,
    height,
    width,
    buttonType,
    disabled,
    fontSize,
    fontWeight,
    backgroundColor,
}) => {
    const screens = useBreakpoint();
    ClinicButton.propTypes = {
        loading: PropTypes.bool,
        onClick: PropTypes.func,
        title: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
        fontSize: PropTypes.number,
        fontWeight: PropTypes.number,
        buttonType: PropTypes.string,
        disabled: PropTypes.bool,
        backgroundColor: PropTypes.string,
    };

    ClinicButton.defaultProps = {
        loading: false,
        onClick: () => {},
        title: '',
        height: 40,
        fontSize: screens.xs ? 16 : 20,
        buttonType: '',
        disabled: false,
        fontWeight: 400,
        backgroundColor: 'common',
    };
    return (
        <>
            {buttonType === 'formButton' ? (
                <Button
                    className={`commonButton ${
                        backgroundColor === 'white'
                            ? 'button-With-White-Background'
                            : backgroundColor === 'lightGreen'
                            ? 'button-With-lightGreen-Background'
                            : ''
                    }
          }`}
                    type="primary"
                    htmlType="submit"
                    defaultShadow={''}
                    style={{
                        height: `${screens.xs ? 40 : height}px`,
                        fontSize: `${fontSize}px`,
                        fontWeight: { fontWeight },
                    }}
                    disabled={disabled}
                    primaryShadow={''}
                >
                    {loading ? <Spin className="button" /> : <>{title}</>}
                </Button>
            ) : (
                <Button
                    className={`commonButton ${
                        backgroundColor === 'white'
                            ? 'button-With-White-Background'
                            : backgroundColor === 'lightGreen'
                            ? 'button-With-lightGreen-Background'
                            : ''
                    }
        }`}
                    type="primary"
                    onClick={onClick}
                    primaryShadow={''}
                    defaultShadow={''}
                    style={{
                        height: `${height}px`,
                        width: width ? width : '100%',
                        fontSize: `${fontSize}px`,
                        fontWeight: { fontWeight },
                    }}
                    disabled={disabled}
                >
                    {loading ? <Spin className="button" /> : <>{title}</>}
                </Button>
            )}
        </>
    );
};
export default ClinicButton;
