import {
    BrowserRouter,
    Routes,
    Route,
    useSearchParams,
} from 'react-router-dom';
import {
    Login,
    SignUp,
    ForgetPassword,
    ResetPassword,
    VerifyCode,
} from './components/authComponents';
import {
    Dashboard,
    Deal,
    Reviews,
    AttachUser,
    ContactUS,
    LastMinBooking,
} from './components/mainComponents';
import PublicRoute from './utils/publicRouting';
import PrivateRoute from './utils/privateRouting';
import DealCard from './components/mainComponents/Deal/DealCard';

import QRDownload from './components/mainComponents/QRDownload/QRDownload';
import LastMinCard from './components/mainComponents/LastMinBooking/LastMinCard/LastMinCard';
import ClinicSetting from './components/mainComponents/ClininSettings';
import { SignupContextProvider } from './context/SignupContext';
import Pricing from './components/mainComponents/Pricing';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PrivacyPolicy from './components/mainComponents/Privacy/PrivacyPolicy';
import { getToken } from './utils/localStorage';
function App() {
    const { i18n } = useTranslation();

    useEffect(() => {
        const localLang = localStorage.getItem('lng');
        if (localLang) i18n.changeLanguage(localLang);
    }, []);
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <PublicRoute>
                                <Login />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/login"
                        element={
                            <PublicRoute>
                                <Login />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/signup"
                        element={
                            <PublicRoute>
                                <SignupContextProvider>
                                    <SignUp />
                                </SignupContextProvider>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/forgetpassword"
                        element={
                            <PublicRoute>
                                <ForgetPassword />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/resetpassword"
                        element={
                            <PublicRoute>
                                <ResetPassword />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/verifycode"
                        element={
                            <PublicRoute>
                                <VerifyCode />
                            </PublicRoute>
                        }
                    />

                    <Route
                        path="/clinicsettings"
                        element={
                            <PrivateRoute>
                                <ClinicSetting />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/reviews"
                        element={
                            <PrivateRoute>
                                <Reviews />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/deals"
                        element={
                            <PrivateRoute>
                                <Deal />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/lastMin"
                        element={
                            <PrivateRoute>
                                <LastMinBooking />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/deals"
                        element={
                            <PrivateRoute>
                                <Deal />
                            </PrivateRoute>
                        }
                    />
                    <Route path="/" element={<DealCard />} />
                    <Route path="/qr" element={<QRDownload />} />
                    <Route path="/" element={<LastMinCard />} />
                    <Route
                        path="/:from/adduser/:itemId"
                        element={
                            <PrivateRoute>
                                <AttachUser />
                            </PrivateRoute>
                        }
                    />

                    <Route path="/contactus" element={<ContactUS />} />

                    <Route
                        path="/pricing"
                        element={
                            <PrivateRoute>
                                <Pricing />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/privacy-policy"
                        element={
                            <PublicRoute>
                                <PrivacyPolicy />
                            </PublicRoute>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
