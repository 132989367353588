import React, { useEffect, useState } from 'react';
import { Card, Col, Modal, Row } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { ClinicButton } from '../../../commonComponents';
import { patchApiWithAuth } from '../../../../utils/api';
import { Url } from '../../../../utils/apiUrls';
import { Images } from '../../../../assets/images';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './LastMinCard.css';
import moment from 'moment';
import { discountCalculate } from '../../../../utils/helper';

const LastMinCard = ({
    item,
    setNewCardAdd,
    setIsUpdateModalOpen,
    isUpdateModalOpen,
    setSelectedCard,
}) => {
    const { i18n, t } = useTranslation();
    const [time, setTime] = useState('');
    const [cardStatus, setCardStatus] = useState('active');
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [language, setLanguage] = useState(i18n.language);

    const [selectedItemId, setSelectedItemId] = useState(null);
    const navigate = useNavigate();

    const onDisableCard = async () => {
        setIsLoading(true);
        let status = 'active';
        if (item.deal_status === 'active') status = 'inactive';
        const response = await patchApiWithAuth(
            `${Url.UPDATE_DEAL}${item.id}/`,
            {
                deal_status: status,
            },
        );
        if (response.success) {
            setNewCardAdd(true);
            setCardStatus(item.deal_status);
        }
        setIsModalOpen(!isModalOpen);
        setIsLoading(false);
    };

    const onUpdateCard = () => {
        setIsUpdateModalOpen(!isUpdateModalOpen);
        setSelectedCard(item);
    };

    useEffect(() => {
        let time = `${item?.start_time?.split(' ')[0]} ${t('timeTo')} ${
            item?.end_time?.split(' ')[0]
        }`;
        setTime(time);
        setIsExpired(moment().isAfter(item?.start_time));
        setCardStatus(item.deal_status);
    }, [item.deal_status]);

    const navigateToAttachUser = (itemId) => {
        setSelectedItemId(itemId);
        navigate(`/lastMin/adduser/${itemId}`);
    };

    return (
        <>
            <div>
                <Card className="cardStyleDeal">
                    <div className="cardContentContainer">
                        <div className="nullImageDeal">
                            <div className="cardUserButtonContainer">
                                <ClinicButton
                                    backgroundColor={'white'}
                                    title={t('attachUser')}
                                    fontSize={9}
                                    height={26}
                                    fontWeight={400}
                                    onClick={() =>
                                        cardStatus === 'active'
                                            ? navigateToAttachUser(item.id)
                                            : null
                                    }
                                    disabled={
                                        cardStatus !== 'active' || isExpired
                                    }
                                    loading={false}
                                />
                            </div>
                            <img
                                src={
                                    item.clinic_detail.profile ??
                                    Images.userProfile
                                }
                                alt={`last-min-${item.name}`}
                                width={'100%'}
                                height={185}
                                style={{
                                    objectFit: 'cover',
                                    borderRadius: 15,
                                }}
                            />
                        </div>
                        <div className="cardContent">
                            <h1 className="cardHeading">{item.name}</h1>
                            {!item.clinic_call ? (
                                <p className="cardTime">
                                    <ClockCircleOutlined
                                        style={{ marginRight: '5px' }}
                                    />
                                    {`${moment(item?.start_time).format(
                                        'YYYY-MM-DD HH:mm',
                                    )}`}
                                </p>
                            ) : (
                                <p className="cardTime">
                                    {t('dealCallClinic')}
                                </p>
                            )}

                            <div
                                style={{
                                    display: 'flex',
                                    gap: '1rem',
                                    alignItems: 'baseline',
                                }}
                            >
                                {parseFloat(item?.discount_percentage) > 0 ? (
                                    <>
                                        <span className="cardPrice">{`${discountCalculate(
                                            item.price,
                                            parseFloat(
                                                item.discount_percentage,
                                            ),
                                        )} SEK`}</span>
                                        <span className="cardDiscount">
                                            <del>{`${item.price} SEK`}</del>
                                        </span>
                                    </>
                                ) : (
                                    <span className="cardPrice">{`${item.price} SEK`}</span>
                                )}
                            </div>
                            <Row align="middle" gutter={[8, 8]}>
                                <Col xs={24} md={12}>
                                    <div className="cardButton">
                                        <ClinicButton
                                            title={t('cardUpdate')}
                                            fontSize={14}
                                            height={41}
                                            fontWeight={400}
                                            disabled={
                                                isExpired ||
                                                cardStatus !== 'active' ||
                                                item.count === 0
                                            }
                                            loading={false}
                                            onClick={onUpdateCard}
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} md={12}>
                                    <div className="cardButton">
                                        <ClinicButton
                                            title={
                                                isExpired
                                                    ? t('cardExpired')
                                                    : item.count === 0
                                                    ? t('cardBooked')
                                                    : cardStatus === 'active'
                                                    ? t('cardDisable')
                                                    : t('cardEnable')
                                            }
                                            backgroundColor={
                                                isExpired || item.count === 0
                                                    ? ''
                                                    : 'lightGreen'
                                            }
                                            fontSize={14}
                                            height={41}
                                            fontWeight={400}
                                            loading={false}
                                            disabled={
                                                isExpired || item.count === 0
                                            }
                                            onClick={() =>
                                                setIsModalOpen(!isModalOpen)
                                            }
                                        />
                                    </div>
                                </Col>
                                {/* <div className="cardButton">
                                    <ClinicButton
                                        title={t('cardUpdate')}
                                        fontSize={14}
                                        height={41}
                                        fontWeight={400}
                                        disabled={
                                            isExpired ||
                                            cardStatus !== 'active' ||
                                            item.count === 0
                                        }
                                        loading={false}
                                        onClick={onUpdateCard}
                                    />
                                </div>
                                <div
                                    className="cardButton"
                                    style={{ marginLeft: '12px' }}
                                >
                                    <ClinicButton
                                        title={
                                            isExpired
                                                ? t('cardExpired')
                                                : item.count === 0
                                                ? t('cardBooked')
                                                : cardStatus === 'active'
                                                ? t('cardDisable')
                                                : t('cardEnable')
                                        }
                                        backgroundColor={
                                            isExpired || item.count === 0
                                                ? ''
                                                : 'lightGreen'
                                        }
                                        fontSize={14}
                                        height={41}
                                        fontWeight={400}
                                        loading={false}
                                        disabled={isExpired || item.count === 0}
                                        onClick={() =>
                                            setIsModalOpen(!isModalOpen)
                                        }
                                    />
                                </div> */}
                            </Row>
                        </div>
                    </div>
                </Card>
            </div>
            <Modal
                open={isModalOpen}
                footer={null}
                closable={true}
                centered
                onCancel={() => {
                    setIsModalOpen(!isModalOpen);
                }}
            >
                <div>
                    <Row justify="center" gutter={[16, 16]}>
                        <Col span={24}>
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                <RiErrorWarningLine className="cardModalIcon" />
                            </div>
                            <p className="cardModalText">
                                {cardStatus === 'active' && !isExpired
                                    ? t('cardDisableModalTextBook')
                                    : t('cardEnableModalText')}
                            </p>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[16, 8]}>
                                <Col xs={24} md={12}>
                                    <div className="cardModalButton">
                                        <ClinicButton
                                            title={t('yesSure')}
                                            ze={9}
                                            fontSize={14}
                                            height={41}
                                            fontWeight={400}
                                            loading={isLoading}
                                            onClick={onDisableCard}
                                        />
                                    </div>
                                </Col>
                                <Col xs={24} md={12}>
                                    <div className="cardModalButton">
                                        <ClinicButton
                                            title={t('noCancel')}
                                            backgroundColor={'lightGreen'}
                                            fontSize={14}
                                            height={37}
                                            fontWeight={400}
                                            loading={false}
                                            onClick={() =>
                                                setIsModalOpen(!isModalOpen)
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    );
};

LastMinCard.propTypes = {
    item: PropTypes.object,
    setNewCardAdd: PropTypes.func,
    setIsUpdateModalOpen: PropTypes.func,
    setSelectedCard: PropTypes.func,
    isUpdateModalOpen: PropTypes.bool,
};

LastMinCard.defaultProps = {
    item: {},
    setNewCardAdd: () => {},
    setIsUpdateModalOpen: () => {},
    setSelectedCard: () => {},
    isUpdateModalOpen: false,
};

export default LastMinCard;
