import React, {
    useState,
    useEffect,
    useMemo,
    useLayoutEffect,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import ClinicInput from '../../commonComponents/ClinicInputField';
import { Button, Col, Pagination, Row, Select, Grid, Form } from 'antd';
import { Table } from 'antd';
import { getApiWithAuth, patchApiWithAuth } from '../../../utils/api';
import { Url } from '../../../utils/apiUrls';
import AttachUserModal from './AttachUserModel';
import { useParams } from 'react-router-dom';
import './AttachUser.css';
import { Option } from 'antd/es/mentions';
import moment from 'moment';

const { useBreakpoint } = Grid;

const AttachUser = () => {
    const { t } = useTranslation();
    const { itemId } = useParams();
    const screen = useBreakpoint();
    const pageRef = useRef(null);
    const [dataSource, setdataSouce] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userId, setUserId] = useState();
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [isExpired, setIsExpired] = useState(false);
    const [dealData, setDealdata] = useState({
        count: 0,
        name: '',
    });

    const handleBookingStatusChange = async (id, value) => {
        const payload = {
            booking_status: value,
        };
        const response = await patchApiWithAuth(
            Url.BOOKING_STATUS + id,
            payload,
        );
        if (!response.success) {
            alert('Something went wrong');
        }
    };

    const columns = useMemo(
        () => [
            {
                title: `${t('user_id')}`,
                dataIndex: 'user_id',
                key: 'user_id',
                className: 'center-aligned-header',
            },
            {
                title: `${t('nameText')}`,
                dataIndex: 'name',
                key: 'name',
                className: 'center-aligned-header',
                render: (text, record) =>
                    `${record.first_name} ${record.last_name}`,
            },
            {
                title: `${t('EMAIL')}`,
                dataIndex: 'email',
                key: 'email',
                className: 'center-aligned-header',
            },
            {
                title: `${t('phoneText')}`,
                dataIndex: 'phone_number',
                key: 'phone_number',
                className: 'center-aligned-header',
            },
            {
                title: `${t('statusText')}`,
                dataIndex: 'booking_status',
                key: 'booking_status',
                className: 'center-aligned-header',
                render: (text, record) => (
                    <Select
                        defaultValue={text}
                        className="custom-dropdown"
                        disabled={record?.booking_status === 'canceled'}
                        onChange={(value) =>
                            handleBookingStatusChange(record.id, value)
                        }
                    >
                        {record?.booking_status === 'canceled' ? (
                            <Option value="canceled">{t('canceled')}</Option>
                        ) : (
                            <>
                                <Option value="completed">
                                    {t('completed')}
                                </Option>
                                <Option value="inprogress">
                                    {t('inProgress')}
                                </Option>
                            </>
                        )}
                    </Select>
                ),
            },
        ],
        [t],
    );
    const getData = async (page = 1) => {
        const response = await getApiWithAuth(
            `deal/${itemId}/bookings?page=${page}`,
        );
        if (response.success) {
            const {
                data: { data },
                page,
                page_size,
                total: totalNo,
            } = response.data;
            setdataSouce(data);
            setTotal(totalNo);
            setPage(page);
            setLimit(page_size);
        }
    };

    const getDealData = async (page = 1) => {
        const response = await getApiWithAuth(`deals/${itemId}`);
        if (response.success) {
            setDealdata(response.data);

            if (response.data?.clinic_call) return;
            if (response.data?.last_min_booking) {
                setIsExpired(moment().isAfter(response.data?.start_time));
            } else {
                setIsExpired(moment().isAfter(response.data?.end_time));
            }
        }
    };

    useLayoutEffect(() => {
        getData();
        getDealData();
    }, []);

    useEffect(() => {
        pageRef.current.focus();
        window.scrollTo(0, 0);
    }, []);

    return (
        <main tabIndex="0" ref={pageRef}>
            <div
                className={
                    screen.lg
                        ? 'attachUserContainer-lg-view'
                        : 'attachUserContainer'
                }
            >
                <div className="mainAttachUser">
                    <div className="headingAttachUser">
                        {`${t('attachUserWith')} ${dealData.name}`}
                    </div>
                    <div className="subHeadingAttachUser">
                        {`${t('subAttach')}`}
                    </div>
                </div>
                <div className="hrContainer">
                    <hr className="hrAttachUser" />
                </div>
                <Row gutter={[16, 16]} justify="space-between" align="middle">
                    <Col xs={24} md={12}>
                        <Row align={'middle'} gutter={[16, 16]}>
                            <Col xs={24} sm={16} style={{ height: '5rem' }}>
                                <Form
                                    fields={[
                                        {
                                            name: ['ID'],
                                            value: userId,
                                        },
                                    ]}
                                >
                                    <Form.Item name="ID">
                                        <div className="spanStyleUser">
                                            <span className="spanUserId">{`${t(
                                                'iD',
                                            )}`}</span>
                                            <div className="idMail">
                                                <ClinicInput
                                                    height={37}
                                                    type={'text'}
                                                    disabled={
                                                        isExpired ||
                                                        dealData.count === 0
                                                    }
                                                    placeholder={`${t(
                                                        'userId',
                                                    )}`}
                                                    name={'ID'}
                                                    Value={userId}
                                                    onChange={(event) => {
                                                        setUserId(
                                                            event.target.value,
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <span
                                            style={{
                                                color: '#2ed3c5',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {t('notifyAttachUser')}
                                        </span>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col xs={24} sm={24 - 16}>
                                <Button
                                    className="attachButton"
                                    onClick={() =>
                                        userId?.length > 0 &&
                                        setIsModalOpen(!isModalOpen)
                                    }
                                    disabled={isExpired || dealData.count === 0}
                                >
                                    {`${t('attachUser')}`}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={7} lg={6}>
                        <Button
                            className="attachButtonDeal"
                            disabled
                            style={{
                                cursor: 'context-menu',
                            }}
                        >{`${dealData.count}  ${t('dealTen')}`}</Button>
                    </Col>
                    <Col xs={24}>
                        <div className="thirdConainerAttachUser">
                            <div className="listUser">{`${t('userList')}`}</div>
                        </div>
                    </Col>
                    <Col xs={24}>
                        <div className="tableAttachUser">
                            <Table
                                dataSource={dataSource.map((data) => ({
                                    ...data,
                                    phone_number: data?.phone_number ?? 'N/A',
                                }))}
                                pagination={false}
                                scroll={{ x: 400 }}
                                columns={columns.map((column) => ({
                                    ...column,
                                    title: `${t(column.title)}`,
                                }))}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <Row justify={'center'}>
                <Col xs={24}>
                    {dataSource?.length > 0 && (
                        <div className="tablePaginator">
                            <Pagination
                                total={total}
                                showTotal={(total) =>
                                    `${t('total')} ${total} ${t('items')}`
                                }
                                defaultPageSize={limit}
                                defaultCurrent={page}
                                current={page}
                                onChange={(page, pageSize) => {
                                    getData(page);
                                }}
                            />
                        </div>
                    )}
                </Col>
            </Row>
            <AttachUserModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                userId={userId}
                setUserId={setUserId}
                getListing={getData}
                getDealData={getDealData}
            />
        </main>
    );
};

export default AttachUser;
