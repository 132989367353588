import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
    useNavigate,
    useLocation,
    Link,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Drawer, Dropdown, Menu, Row, Grid, Badge } from 'antd';
import {
    CaretDownOutlined,
    DownOutlined,
    MenuOutlined,
    QrcodeOutlined,
} from '@ant-design/icons';
import { FiLogOut } from 'react-icons/fi';

import Logo from '../../../assets/images/logo.png';
import { getApiWithAuth, patchApiWithAuth } from '../../../utils/api';
import { Url } from '../../../utils/apiUrls';
import useUser from '../../../hooks/useUser';

import { Images } from '../../../assets/images';
import { PDFDownloadLink } from '@react-pdf/renderer';
import QRDownload from '../../mainComponents/QRDownload/QRDownload';
import { nameResolver } from '../../../utils/helper';
import './NavbarStyle.css';

function truncate(str, n) {
    return str?.length > n ? str?.slice(0, n - 1) + '...' : str;
}

const { useBreakpoint } = Grid;

const langObj = {
    sew: 'sweden',
    eng: 'english',
};

const Navbar = () => {
    const screen = useBreakpoint();
    const { i18n, t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const view = searchParams.get('view');

    const myItems = [
        {
            label: t('Dashboard'),
            key: '/dashboard',
        },
        {
            label: t('Deals'),
            key: '/deals',
        },
        {
            label: t('lastMinNav'),
            key: '/lastMin',
        },
        {
            label: t('rev'),
            key: '/reviews',
        },
    ];

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const params = useParams();
    const { userData, getUser } = useUser();
    const [isLogin, setIsLogin] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isDropOpen, setIsDropOpen] = useState(false);
    const [itemKey, setItemKey] = useState(i18n.language);
    const [isLang, setIslang] = useState(1);
    const [clinicData, setClinicData] = useState({});
    const [show, setShow] = useState(false);
    const [showHeadings, setShowHeadings] = useState(false);
    const [currentPath, setCurrentPath] = useState(pathname);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [openDropDown, setOpenDropDown] = useState(false);
    const [openMobileMenu, setMobileMenu] = useState(false);

    // Update currentPath whenever the location changes
    useEffect(() => {
        params && params?.from
            ? setCurrentPath(`/${params?.from}`)
            : setCurrentPath(pathname);
    }, [pathname]);

    const onClick = (e) => {
        setCurrentPath(e.key);
        setIsOpenDrawer(false);
        setMobileMenu(false);
        navigate(e.key);
    };

    const userMenuHandler = () => {
        // this function only call in drawer.
        setIslang(0);
        setMobileMenu(!openMobileMenu);
    };

    const itemsList = () => {
        if (isLang === 0)
            return [
                // {
                //     type: 'divider',
                // },
                {
                    key: '1',
                    label: (
                        <div
                            className={
                                pathname === '/clinicsettings'
                                    ? 'dropDownLabelSelected'
                                    : 'dropDownLabelUnselect'
                            }
                            onClick={() => {
                                setItemKey('clinicsettings');
                                navigate('/clinicsettings', {
                                    state: { download: false },
                                });
                                setMobileMenu(false);
                                setIsOpenDrawer(false);
                                setIsOpen(false);
                            }}
                        >
                            <svg
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ marginRight: '5px' }}
                            >
                                <path
                                    d="M7.17925 8.99205C4.7427 8.99205 2.75964 7.00899 2.75964 4.57244C2.75964 2.13589 4.7427 0.152832 7.17925 0.152832C9.6158 0.152832 11.5989 2.13589 11.5989 4.57244C11.5989 7.00899 9.6158 8.99205 7.17925 8.99205ZM7.17925 1.30577C5.38066 1.30577 3.91258 2.77385 3.91258 4.57244C3.91258 6.37103 5.38066 7.83911 7.17925 7.83911C8.97784 7.83911 10.4459 6.37103 10.4459 4.57244C10.4459 2.77385 8.97784 1.30577 7.17925 1.30577ZM10.1154 16.6783C9.82333 16.6783 9.54662 16.5707 9.34678 16.3709C9.10851 16.1326 9.0009 15.7867 9.0547 15.4255L9.20074 14.3878C9.23917 14.1188 9.40059 13.8037 9.59274 13.6038L12.3137 10.8829C13.4051 9.79142 14.3659 10.414 14.8348 10.8829C15.2345 11.2826 15.442 11.713 15.442 12.1434C15.442 12.5815 15.2422 12.9889 14.8348 13.3963L12.1138 16.1172C11.9217 16.3094 11.5989 16.4708 11.3298 16.5092L10.2922 16.6553C10.2307 16.6706 10.1769 16.6783 10.1154 16.6783ZM13.5665 11.4286C13.4282 11.4286 13.3052 11.5208 13.1284 11.6976L10.4075 14.4186C10.3764 14.4563 10.3552 14.5012 10.346 14.5492L10.2076 15.51L11.1684 15.3717C11.1992 15.364 11.276 15.3255 11.2991 15.3025L14.02 12.5815C14.143 12.4586 14.2891 12.2818 14.2891 12.1434C14.2891 12.0281 14.1968 11.8667 14.02 11.6976C13.8356 11.5131 13.6972 11.4286 13.5665 11.4286Z"
                                    fill={
                                        pathname === '/clinicsettings'
                                            ? '#2ED3C5'
                                            : '#888c94'
                                    }
                                />
                                <path
                                    d="M14.0351 13.9651C13.9813 13.9651 13.9275 13.9574 13.8814 13.942C13.3799 13.7997 12.9232 13.5315 12.5545 13.1629C12.1859 12.7943 11.9177 12.3375 11.7754 11.836C11.6908 11.5285 11.8676 11.2134 12.1751 11.1289C12.4825 11.0443 12.7976 11.2211 12.8822 11.5285C13.059 12.1588 13.5586 12.6584 14.1889 12.8352C14.3225 12.8728 14.4381 12.9575 14.5143 13.0736C14.5905 13.1897 14.6221 13.3294 14.6034 13.467C14.5847 13.6046 14.5168 13.7308 14.4124 13.8223C14.308 13.9139 14.174 13.9646 14.0351 13.9651ZM0.576471 16.6784C0.261333 16.6784 0 16.417 0 16.1019C0 12.8198 3.22055 10.145 7.17898 10.145C8.01678 10.145 8.8469 10.268 9.62322 10.4986C9.93067 10.5908 10.0998 10.9136 10.0075 11.2134C9.91529 11.5209 9.59247 11.69 9.29271 11.5977C8.61631 11.3979 7.90918 11.2903 7.17898 11.2903C3.85851 11.2903 1.15294 13.4424 1.15294 16.0942C1.15294 16.417 0.891608 16.6784 0.576471 16.6784Z"
                                    fill={
                                        pathname === '/clinicsettings'
                                            ? '#2ED3C5'
                                            : '#888c94'
                                    }
                                />
                            </svg>

                            {/* <UserOutlined style={{ marginRight: '5px' }} /> */}
                            <p className="settingDrop">
                                {t('clinicSettings')}{' '}
                                {userData && (
                                    <Badge
                                        count={
                                            userData?.about &&
                                            String(userData?.about).length > 0
                                                ? 0
                                                : 1
                                        }
                                        size="small"
                                    />
                                )}
                            </p>
                        </div>
                    ),
                },
                {
                    type: 'divider',
                },
                {
                    key: '2',
                    label: (
                        <div
                            className={
                                pathname === '/contactus'
                                    ? 'dropDownLabelSelected'
                                    : 'dropDownLabelUnselect'
                            }
                            onClick={() => {
                                setItemKey('contactus');
                                navigate('/contactus');
                                setMobileMenu(false);
                                setIsOpenDrawer(false);
                                setIsOpen(false);
                            }}
                        >
                            <svg
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ marginRight: '5px' }}
                            >
                                <path
                                    d="M15.7091 11.4275C15.681 11.4042 12.5079 9.1435 11.6512 9.2848C11.2378 9.35783 11.0018 9.63989 10.5282 10.204C10.3972 10.3611 10.2633 10.5156 10.1265 10.6676C9.82717 10.5701 9.53519 10.4512 9.25282 10.312C7.79515 9.60232 6.6174 8.42458 5.90775 6.96691C5.7685 6.68454 5.64966 6.39255 5.55213 6.0932C5.70771 5.95085 5.92574 5.76721 6.01888 5.68889C6.58036 5.2179 6.8619 4.98135 6.93493 4.56752C7.08469 3.71074 4.81548 0.538725 4.7922 0.510148C4.68886 0.36359 4.55427 0.241804 4.39814 0.153583C4.24201 0.065361 4.06825 0.0129046 3.88938 0C2.96964 0 0.34375 3.40593 0.34375 3.98011C0.34375 4.01345 0.391907 7.40244 4.57099 11.6535C8.81729 15.8278 12.2063 15.876 12.2396 15.876C12.8133 15.876 16.2197 13.2501 16.2197 12.3303C16.2067 12.1515 16.1541 11.9777 16.0658 11.8216C15.9775 11.6654 15.8557 11.5309 15.7091 11.4275ZM12.1809 14.8144C11.7215 14.7752 8.87444 14.4 5.31822 10.9068C1.80804 7.33311 1.44237 4.48126 1.40585 4.03938C2.09952 2.95063 2.93725 1.96069 3.89626 1.0965C3.91743 1.11767 3.94548 1.14942 3.98147 1.1907C4.71696 2.19471 5.35054 3.26954 5.87282 4.39923C5.70298 4.5701 5.52346 4.73106 5.33516 4.88133C5.04314 5.10384 4.77499 5.35603 4.53501 5.63385C4.4944 5.69083 4.4655 5.75529 4.44996 5.82351C4.43443 5.89173 4.43258 5.96235 4.44451 6.03128C4.55651 6.51642 4.72803 6.98585 4.95519 7.4289C5.76904 9.10012 7.11949 10.4504 8.79083 11.264C9.23379 11.4915 9.70324 11.6632 10.1884 11.7752C10.2574 11.7871 10.328 11.7853 10.3962 11.7698C10.4644 11.7542 10.5289 11.7253 10.5859 11.6847C10.8647 11.4438 11.1178 11.1745 11.341 10.8814C11.5072 10.6835 11.7289 10.4194 11.8131 10.3448C12.9457 10.8666 14.0229 11.5009 15.0285 12.2383C15.0724 12.2753 15.1036 12.3039 15.1243 12.3224C14.26 13.2817 13.2699 14.1197 12.1809 14.8133V14.8144Z"
                                    fill={
                                        pathname === '/contactus'
                                            ? '#2ED3C5'
                                            : '#888c94'
                                    }
                                />
                                <path
                                    d="M8.81045 4.23418C9.65231 4.23502 10.4594 4.56982 11.0547 5.1651C11.65 5.76038 11.9848 6.56752 11.9856 7.40938C11.9856 7.54973 12.0414 7.68433 12.1406 7.78358C12.2399 7.88282 12.3745 7.93857 12.5148 7.93857C12.6552 7.93857 12.7898 7.88282 12.889 7.78358C12.9883 7.68433 13.044 7.54973 13.044 7.40938C13.0428 6.28694 12.5963 5.21084 11.8027 4.41716C11.009 3.62348 9.93288 3.17704 8.81045 3.17578C8.6701 3.17578 8.53549 3.23154 8.43625 3.33078C8.337 3.43002 8.28125 3.56463 8.28125 3.70498C8.28125 3.84533 8.337 3.97994 8.43625 4.07918C8.53549 4.17842 8.6701 4.23418 8.81045 4.23418Z"
                                    fill={
                                        pathname === '/contactus'
                                            ? '#2ED3C5'
                                            : '#888c94'
                                    }
                                />
                                <path
                                    d="M8.81045 1.5877C10.3538 1.58952 11.8334 2.2034 12.9246 3.29469C14.0159 4.38598 14.6298 5.86557 14.6316 7.40889C14.6316 7.54924 14.6874 7.68384 14.7866 7.78309C14.8859 7.88233 15.0205 7.93809 15.1608 7.93809C15.3012 7.93809 15.4358 7.88233 15.535 7.78309C15.6343 7.68384 15.69 7.54924 15.69 7.40889C15.6879 5.58495 14.9625 3.83632 13.6727 2.5466C12.383 1.25688 10.6344 0.531398 8.81045 0.529297C8.6701 0.529297 8.53549 0.585052 8.43625 0.684296C8.337 0.78354 8.28125 0.918144 8.28125 1.0585C8.28125 1.19885 8.337 1.33345 8.43625 1.4327C8.53549 1.53194 8.6701 1.5877 8.81045 1.5877Z"
                                    fill={
                                        pathname === '/contactus'
                                            ? '#2ED3C5'
                                            : '#888c94'
                                    }
                                />
                            </svg>

                            <p className="settingDrop">{t('contactUs')}</p>
                        </div>
                    ),
                },
                {
                    type: 'divider',
                },
                {
                    key: '3',
                    label: (
                        <div
                            className={
                                itemKey === 'login'
                                    ? 'dropDownLabelSelected'
                                    : 'dropDownLabelUnselect'
                            }
                            onClick={() => {
                                localStorage.setItem('faqs', 'open');
                                navigate('/dashboard', {
                                    state: { faq: true },
                                });
                                setMobileMenu(false);
                                setIsOpenDrawer(false);
                                setIsOpen(false);
                            }}
                        >
                            <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ marginRight: '5px' }}
                            >
                                <path
                                    d="M8.28065 11.1704C7.91335 11.1704 7.66849 10.9255 7.66849 10.5582C7.72971 9.76241 8.15821 9.08904 8.77037 8.59932C9.13766 8.23203 9.50495 7.86473 9.50495 7.49744C9.50495 6.82407 8.95401 6.27314 8.28065 6.27314C7.60728 6.27314 7.05634 6.82407 7.05634 7.49744C7.05634 7.86473 6.81148 8.1096 6.44418 8.1096C6.07689 8.1096 5.83203 7.86473 5.83203 7.49744C5.83203 6.1507 6.93391 5.04883 8.28065 5.04883C9.62738 5.04883 10.7293 6.1507 10.7293 7.49744C10.668 8.29324 10.2395 8.96661 9.62738 9.45633C9.26009 9.82363 8.8928 10.1909 8.8928 10.5582C8.8928 10.9255 8.64794 11.1704 8.28065 11.1704Z"
                                    fill="#888C94"
                                />
                                <path
                                    d="M8.28012 13.0065C8.61821 13.0065 8.89228 12.7325 8.89228 12.3944C8.89228 12.0563 8.61821 11.7822 8.28012 11.7822C7.94204 11.7822 7.66797 12.0563 7.66797 12.3944C7.66797 12.7325 7.94204 13.0065 8.28012 13.0065Z"
                                    fill="#888C94"
                                />
                                <path
                                    d="M1.18027 16.5567C0.996623 16.4343 0.935408 16.1894 0.935408 15.9446L1.48635 12.8226C-0.778622 9.08845 0.445685 4.19122 4.17982 1.98747C7.91396 -0.216282 12.8112 0.946809 15.0762 4.68095C17.3411 8.41508 16.1168 13.3123 12.3827 15.5773C9.93406 17.0464 6.81208 17.1077 4.36347 15.6385L1.85364 16.6792C1.54756 16.7404 1.36392 16.6792 1.18027 16.5567ZM4.36347 14.353C4.4859 14.353 4.60833 14.4142 4.66954 14.4754C7.85274 16.4955 12.0154 15.5161 13.9743 12.3941C15.9332 9.2721 15.0149 5.04824 11.893 3.08935C8.77097 1.13046 4.60833 1.92626 2.58822 5.10945C1.18027 7.31321 1.18027 10.1903 2.58822 12.3941C2.64944 12.5165 2.71065 12.7002 2.64944 12.8226L2.28215 15.0876L4.05739 14.353H4.36347Z"
                                    fill="#888C94"
                                />
                            </svg>

                            <p className="settingDrop">{t('faqs')}</p>
                        </div>
                    ),
                },
                {
                    type: 'divider',
                },
                {
                    key: '4',
                    label: (
                        <PDFDownloadLink
                            document={
                                <QRDownload
                                    company_name={userData.company_name}
                                    about={userData.about}
                                    profile={userData.profile}
                                    id={userData.id}
                                />
                            }
                            onClick={() => {
                                setMobileMenu(false);
                                setIsOpenDrawer(false);
                                setIsOpen(false);
                            }}
                            fileName={nameResolver(
                                userData.company_name,
                            ).concat('.pdf')}
                        >
                            {({ blob, url, loading, error }) => (
                                <div
                                    className={
                                        itemKey === 'login'
                                            ? 'dropDownLabelSelected'
                                            : 'dropDownLabelUnselect'
                                    }
                                >
                                    <QrcodeOutlined
                                        style={{ marginRight: '5px' }}
                                    />

                                    <p className="settingDrop">
                                        {t('printQr')}
                                    </p>
                                </div>
                            )}
                        </PDFDownloadLink>
                    ),
                },
                {
                    type: 'divider',
                },
                {
                    key: '5',
                    label: (
                        <div
                            className={
                                itemKey === 'login'
                                    ? 'dropDownLabelSelected'
                                    : 'dropDownLabelUnselect'
                            }
                            onClick={() => {
                                setItemKey('login');
                                localStorage.removeItem('access_token');
                                navigate('/login');
                                setMobileMenu(false);
                                setIsOpenDrawer(false);
                                setIsOpen(false);
                            }}
                        >
                            <FiLogOut style={{ marginRight: '5px' }} />
                            <p className="settingDrop">{t('logout')}</p>
                        </div>
                    ),
                },
            ];
        else
            return [
                {
                    key: '1',
                    label: (
                        <div
                            className={
                                i18n.language === 'eng'
                                    ? 'dropDownLabelSelected'
                                    : 'dropDownLabelUnselect'
                            }
                            onClick={() => {
                                setItemKey('eng');
                                changeLanguage('eng');
                                setIsDropOpen(false);
                                setOpenDropDown(false);
                            }}
                        >
                            <p className="elang">English</p>
                        </div>
                    ),
                },
                {
                    key: '3',
                    type: 'divider',
                },
                {
                    key: '2',
                    label: (
                        <div
                            className={
                                i18n.language === 'sew'
                                    ? 'dropDownLabelSelected'
                                    : 'dropDownLabelUnselect'
                            }
                            onClick={() => {
                                setItemKey('sew');
                                changeLanguage('sew');
                                setOpenDropDown(false);
                                setIsDropOpen(false);
                            }}
                        >
                            <p className="swee">Swedish</p>
                        </div>
                    ),
                },
            ];
    };

    const items = itemsList();

    const drawerHandler = () => {
        setIsOpenDrawer((previous) => !previous);
    };

    const dropDownHandler = () => {
        setOpenDropDown((previous) => !previous);
        setIslang(1);
    };

    const changeLanguage = async (lng) => {
        localStorage.setItem('lng', lng);
        i18n.changeLanguage(lng);

        if (isLogin) {
            await patchApiWithAuth(Url.UPDATE_URL, {
                language: langObj[lng],
            });
        }
    };

    const isLoginCheck = async () => {
        try {
            if (localStorage.getItem('access_token') !== null) {
                const response = await getApiWithAuth(Url.ME_URL);
                if (response.success) {
                    setClinicData(response.data);
                    setIsLogin(true);
                    setShowHeadings(true);
                } else {
                    setShowHeadings(false);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShow(true);
        }
    };

    const onClickLangDropdown = () => {
        if (isOpen === true) setIsOpen(false);
        setIsDropOpen(!isDropOpen);
        setIslang(1);
    };

    const onClickDropdown = () => {
        if (isDropOpen === true) setIsDropOpen(false);
        // setIsDropOpen((value) => !value);
        setIsOpen(!isOpen);
        setIslang(0);
    };

    useLayoutEffect(() => {
        (async () => await isLoginCheck())();
    }, [pathname]);

    useEffect(() => {
        getUser();
    }, [pathname]);

    useEffect(() => {
        let currentLang = localStorage.getItem('lng');

        if (!currentLang) return;
        i18n.changeLanguage(currentLang);
    }, []);

    return (
        <>
            {screen.lg ? (
                <nav className="mainContainerNavbar">
                    <div style={{ width: '100%' }}>
                        <Row
                            justify={'center'}
                            style={{
                                padding: `0  ${screen.lg ? '5em' : '2em'}`,
                            }}
                            align={'middle'}
                        >
                            <Col
                                span={3}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                }}
                            >
                                <Link
                                    to="/"
                                    className="secondContainerNavbar"
                                    style={{
                                        border: 'none',
                                        textDecoration: 'none',
                                        padding: 0,
                                        margin: 0,
                                    }}
                                >
                                    <img
                                        src={Logo}
                                        className="navbarLogo"
                                        alt="navbar logo"
                                    />
                                    <span className="headingNavbar">
                                        Gnizzel
                                    </span>
                                </Link>
                            </Col>
                            <Col span={17}>
                                {showHeadings && (
                                    <Menu
                                        onClick={onClick}
                                        selectedKeys={[currentPath]}
                                        mode="horizontal"
                                        items={myItems}
                                        className="custom-menu"
                                    />
                                )}
                            </Col>
                            <Col span={4}>
                                {show && (
                                    <div className="thirdContainerNavbar">
                                        <div className="subMainDropDown">
                                            <div className="langDrop">
                                                <Dropdown
                                                    trigger={['click']}
                                                    open={isDropOpen}
                                                    className={
                                                        !isDropOpen
                                                            ? `dropDownLangNavbar ${
                                                                  isLogin &&
                                                                  'dropDownLangNavbarHeight'
                                                              }`
                                                            : `dropDownLangNavbar ${
                                                                  isDropOpen
                                                                      ? 'dropDownLangNavbarOpen'
                                                                      : 'dropDownLangNavbarLessHeight'
                                                              }`
                                                    }
                                                    menu={{
                                                        items,
                                                    }}
                                                    placement="bottomLeft"
                                                    onOpenChange={
                                                        onClickLangDropdown
                                                    }
                                                    getPopupContainer={(
                                                        trigger,
                                                    ) => trigger.parentElement}
                                                >
                                                    <Button>
                                                        <div className="dropDownNavbarContentDiv">
                                                            <div
                                                                className="dropDownLanguageText"
                                                                style={{
                                                                    marginLeft:
                                                                        '5px',
                                                                }}
                                                            >
                                                                {t('language')}
                                                            </div>
                                                            <CaretDownOutlined className="dropDownLanguageIcon" />
                                                        </div>
                                                    </Button>
                                                </Dropdown>
                                            </div>

                                            {isLogin ? (
                                                <>
                                                    <Dropdown
                                                        open={isOpen}
                                                        trigger={['click']}
                                                        onOpenChange={
                                                            onClickDropdown
                                                        }
                                                        className={
                                                            isOpen
                                                                ? 'dropDownNavbarOpen dropDownNavbar'
                                                                : 'dropDownNavbar'
                                                        }
                                                        menu={{
                                                            items,
                                                        }}
                                                        placement="bottomLeft"
                                                        getPopupContainer={(
                                                            trigger,
                                                        ) =>
                                                            trigger.parentElement
                                                        }
                                                    >
                                                        <Button>
                                                            <div className="dropDownNavbarContentDiv-outer">
                                                                <div className="dropDownNavbarContentDiv">
                                                                    <div className="dropDownNavbarImage">
                                                                        <img
                                                                            src={
                                                                                userData?.profile ??
                                                                                Images.userProfile
                                                                            }
                                                                            width={
                                                                                44
                                                                            }
                                                                            height={
                                                                                44
                                                                            }
                                                                            alt="Profile Icon"
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <p className="dropDownNavbarText">
                                                                            {truncate(
                                                                                userData?.company_name,
                                                                                10,
                                                                            )}{' '}
                                                                            <Badge
                                                                                count={
                                                                                    userData?.about &&
                                                                                    String(
                                                                                        userData?.about,
                                                                                    )
                                                                                        .length >
                                                                                        0
                                                                                        ? 0
                                                                                        : 1
                                                                                }
                                                                                size="small"
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <CaretDownOutlined
                                                                    style={{
                                                                        color: '#2ED3C5',
                                                                    }}
                                                                />
                                                            </div>
                                                        </Button>
                                                    </Dropdown>
                                                </>
                                            ) : (
                                                <div className="buttonDropdown">
                                                    <Button
                                                        onClick={() =>
                                                            navigate('/signup')
                                                        }
                                                        className="navbarButton"
                                                    >{`${t('signUp')}`}</Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </nav>
            ) : (
                <nav
                    className={`mainContainerNavbar_mobile ${
                        isLogin && 'scrollableNavbar'
                    }`}
                >
                    <Row justify="space-around" align="middle">
                        <Col span={5}>
                            <div className="justify-center">
                                <span style={{ color: '#fff' }}>
                                    <Dropdown
                                        open={openDropDown}
                                        trigger={['click']}
                                        onOpenChange={dropDownHandler}
                                        menu={{
                                            items,
                                        }}
                                        placement="bottom"
                                    >
                                        <span
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '.3rem',
                                            }}
                                        >
                                            {i18n.language === 'eng'
                                                ? 'ENG'
                                                : 'SWE'}{' '}
                                            <DownOutlined
                                                style={{ fontSize: '10px' }}
                                            />
                                        </span>
                                    </Dropdown>
                                </span>
                            </div>
                        </Col>
                        <Col
                            span={
                                isLogin ? 14 : i18n.language === 'sew' ? 3 : 8
                            }
                        >
                            <Link
                                to={
                                    view === 'mobile'
                                        ? '/contactus?view=mobile'
                                        : '/'
                                }
                                style={{
                                    border: 'none',
                                    textDecoration: 'none',
                                }}
                            >
                                <div className="secondContainerNavbar_mobile">
                                    <img
                                        src={Logo}
                                        className="navbarLogo_mobile"
                                        alt="navbar logo"
                                    />
                                    <span className="headingNavbar">
                                        Gnizzel
                                    </span>
                                </div>
                            </Link>
                        </Col>
                        <Col
                            span={5}
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            {view !== 'mobile' &&
                                (isLogin ? (
                                    <Badge
                                        count={
                                            userData?.about &&
                                            String(userData?.about).length > 0
                                                ? 0
                                                : 1
                                        }
                                        size="small"
                                    >
                                        <MenuOutlined
                                            style={{
                                                color: '#fff',
                                                fontSize: '1.5rem',
                                            }}
                                            onClick={drawerHandler}
                                        />
                                    </Badge>
                                ) : (
                                    <div className="justify-center">
                                        <Button
                                            onClick={() => navigate('/signup')}
                                            className="navbarButton_mobile"
                                        >{`${t('signUp')}`}</Button>
                                    </div>
                                ))}
                        </Col>
                    </Row>
                </nav>
            )}

            <Drawer
                title="Gnizzel"
                open={isOpenDrawer}
                placement="right"
                onClose={drawerHandler}
                forceRender
                closable
                footer={
                    <Dropdown
                        open={openMobileMenu}
                        trigger={['click']}
                        onOpenChange={userMenuHandler}
                        menu={{ items }}
                        placement="top"
                        getPopupContainer={(trigger) => {
                            return trigger.parentElement;
                        }}
                    >
                        <Button
                            style={{
                                height: '100%',
                                width: '100%',
                                borderRadius: '2rem',
                            }}
                        >
                            <Row align="middle" justify="space-between">
                                <Col
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="dropDownNavbarImage_mobile">
                                        <img
                                            src={
                                                userData?.profile ??
                                                Images.userProfile
                                            }
                                            width={44}
                                            height={44}
                                            alt="Profile Icon"
                                        />
                                    </div>
                                    <div>
                                        <p className="dropDownNavbarText">
                                            {truncate(
                                                userData?.company_name,
                                                20,
                                            )}{' '}
                                            <Badge
                                                count={
                                                    userData?.about &&
                                                    String(userData?.about)
                                                        .length > 0
                                                        ? 0
                                                        : 1
                                                }
                                                size="small"
                                            />
                                        </p>
                                    </div>
                                </Col>
                                <Col>
                                    <CaretDownOutlined
                                        style={{
                                            color: '#2ED3C5',
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Button>
                    </Dropdown>
                }
            >
                {showHeadings && (
                    <Menu
                        onClick={onClick}
                        selectedKeys={[currentPath]}
                        mode="vertical"
                        items={myItems}
                        // className="custom-menu"
                    />
                )}
            </Drawer>
        </>
    );
};

export default Navbar;
