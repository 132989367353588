import React from "react";
import IconsImages from "../../../assets/Icons/index";
import { Upload } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./ClinicUploadBox.css";

const ClinicUploadBox = ({
  height,
  width,
  onChange,
  imageArray,
  name,
  onRemove,
}) => {
  ClinicUploadBox.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    onChange: PropTypes.func,
    imageArray: PropTypes.array,
    name: PropTypes.string,
    onRemove: PropTypes.func,
  };

  ClinicUploadBox.defaultProps = {
    height: 0,
    width: 0,
    onChange: () => {},
    imageArray: [],
    name: "",
    onRemove: () => {},
  };

  const { Dragger } = Upload;
  const { t } = useTranslation();


  return (
      <>
          <div
              className="boxUploadMainConatiner"
              style={{
                  height: `${height}px`,
                  width: width === 0 ? '100%' : `${width}px`,
              }}
          >
              <Dragger
                  className="boxUploadButton buttonWidthArticle"
                  beforeUpload={() => false}
                  onChange={onChange}
                  name={name}
                  maxCount={1}
                  onRemove={onRemove}
                  accept="image/*"
                  fileList={imageArray?.length > 0 ? imageArray : []}
                  showUploadList={imageArray?.length > 0 ? true : false}
              >
                  <img src={IconsImages.imageUpload} alt="Upload logo" />
                  <p className={'boxUploadTextStyle'}>
                      {t('clickUpload')}
                      <br />
                  </p>
              </Dragger>
          </div>
      </>
  );
};

export default ClinicUploadBox;

// For working this Component
// const [imageArray, setImageArray] = useState([]);
// const onChangeImage = (info) => {
//   let img = new Image();
//   var binaryData = [];
//   binaryData.push(info.file);
//   img.src = window.URL.createObjectURL(new Blob(binaryData));
//   img.onload = () => {
//     if (img.width <= 800 && img.height <= 400) {
//       setImageArray(info.fileList);
//     } else alert("Please Confirm the image size 800*400");
//   };
// };
