import dealImage from './dealImage.svg';
import noImage from './no-photo.svg';
import nullImage from './empty.jpg';
import userProfile from './default_profile.png';
import sewdishBanner from './swedishBanner.png';
import englishBanner from './englishBanner.png';
import englishMobile from './englishLoginScreen.png';
import swedishMobile from './swedishLoginScreen.png';
import loginScreenEllips from './ellipseLoginScreen.png';
import ellipse_2090 from './Ellipse_2090.png';

export const Images = {
    dealImage,
    noImage,
    nullImage,
    userProfile,
    englishBanner,
    sewdishBanner,
    loginScreenEllips,
    englishMobile,
    swedishMobile,
    ellipse_2090,
};
