import React from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font,
} from '@react-pdf/renderer';

import QRImage from '../../../assets/images/qrcode.png';
import LogoWithText from '../../../assets/images/logoWithText.png';
import fontSrc from './Poppins-Bold.ttf';
import { getApiWithoutAuth } from '../../../utils/api';
import { Images } from '../../../assets/images';

Font.register({
    family: 'Poppins',
    src: fontSrc,
    fonts: [
        {
            src: fontSrc,
        },
    ],
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
    },
    sctionContainer: {
        backgroundColor: '#f0f9fd',
        margin: '30',
        width: '100%',
        borderRadius: 20,
        padding: 30,
        gap: 10,
    },

    heading: {
        textAlign: 'center',
        color: 'rgba(146, 146, 146, 1)',
        fontSize: '24',
        fontFamily: 'Poppins',
    },
    text: {
        fontSize: 12,
        marginBottom: 5,
    },

    profileContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    profileWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 84,
        height: 80,
        borderRadius: '50%',
        borderWidth: 2,
        borderColor: '#2ed3c5',
    },
    profile: {
        width: 80,
        height: 80,
        borderRadius: '50%',
    },

    scanQrHeadingContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
    },
    scanQrHeading: {
        fontSize: '40px',
    },
    justifyContentCenter: {
        justifyContent: 'center',
    },

    qrCodeImage: {
        width: 200,
        height: 200,
        alignSelf: 'center',
        backgroundColor: '#fff',
        padding: 30,
        borderRadius: 20,
    },
    logoImage: {
        width: 132,
        height: 89,
        marginRight: 20,
        marginTop: 20,
        alignSelf: 'flex-end',
    },
    bottomRight: {
        position: 'absolute',
        bottom: 25,
        right: 25,
    },
    qrCodeImageWrapper: {
        textAlign: 'center',
    },
});

function ellipsText(text) {
    if (typeof text !== 'string') return;

    const lines = text.split('\n');

    const maxLines = 5; // Maximum number of lines

    return lines.slice(0, maxLines).map((line, index) => (
        <Text key={index} style={styles.text}>
            {line} {'\n'}
        </Text>
    ));
}

function QRDownload({ company_name, about, profile, id }) {
    const [imageBlob, setImageBlob] = React.useState(null);
    function base64ToBlob(base64) {
        const byteCharacters = window.atob(base64);
        const byteArrays = [];

        for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
        }

        return new Blob([new Uint8Array(byteArrays)], { type: 'image/jpeg' }); // Adjust the 'type' as needed
    }

    async function fetchImage() {
        const imageUrl = profile;
        if (!id || !imageUrl) return;
        try {
            const response = await getApiWithoutAuth(
                `profile_image_base64/${id}`,
            );

            if (response) {
                if (!response?.success) {
                    throw new Error(
                        `Image fetch failed with status: ${response.status}`,
                    );
                }

                const { data } = response.data;
                const { profile_base64 } = data;
                const blob = base64ToBlob(profile_base64);
                const blogToLocalURL = URL.createObjectURL(blob);
                setImageBlob(blogToLocalURL);
            }
        } catch (error) {
            console.error('Error fetching pdf image:', error);
        }
    }

    React.useEffect(() => {
        fetchImage();
    }, [id]);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.sctionContainer}>
                    <View style={styles.profileContainer}>
                        <View style={styles.profileWrapper}>
                            <Image
                                src={
                                    typeof imageBlob === 'string'
                                        ? imageBlob
                                        : Images.userProfile
                                }
                                alt={company_name}
                                style={styles.profile}
                            />
                        </View>
                    </View>

                    <View>
                        <Text style={styles.heading}>{company_name}</Text>
                    </View>

                    {/* <View style={styles.justifyContentCenter}> */}
                    <Text style={{ ...styles.heading, textAlign: 'left' }}>
                        {ellipsText(about)}
                    </Text>
                    {/* </View> */}

                    <View style={styles.scanQrHeadingContainer}>
                        <Text
                            style={{
                                ...styles.scanQrHeading,
                                color: 'rgba(24, 23, 30, 1)',
                            }}
                        >
                            Scan{' '}
                        </Text>
                        <Text
                            style={{
                                ...styles.scanQrHeading,
                                color: 'rgba(46, 211, 197, 1)',
                            }}
                        >
                            QR Code
                        </Text>
                    </View>

                    <View style={styles.qrCodeImageWrapper}>
                        <Image source={QRImage} style={styles.qrCodeImage} />
                    </View>

                    <View style={styles.bottomRight}>
                        <Image source={LogoWithText} style={styles.logoImage} />
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default QRDownload;
