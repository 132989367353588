let eng = {
    translations: {
        gnizzel: 'Gnizzel',
        isA: 'is a',
        forClinic: 'for dental clinics. Join us and find new patients. Try',
        marketPlace: 'marketplace',
        monthsFor: '3 months for',
        free: 'free.',
        noDealHeader: 'You don’t have a deal yet',
        noBookingHeader: 'You don’t have a Last Minute Booking yet',
        noDealSubHeader: 'Create your deals here.',
        dealNotFound: 'Deal not found for the keyword.',
        lastMinDealNotFound: 'Last min deal not found for the keyword.',
        searchKeyword: 'Search keyword:',
        noBookingSubHeader: 'Create your last min booking',
        createDeal: 'Create Deal',
        createBooking: 'Create',
        createLastMinCard: 'Create Last Minute Booking',
        booking: 'Last minute bookings',
        dealsDetail: 'Your deals have been shown here.',
        bookingDetail: 'Your deals have been shown here',
        createNewDeal: 'Create New Deal',
        createNewBook: 'Create New Last Minute Booking', // Create New Last Minute Booking
        filter: 'Search',
        updateDeal: 'Update Deal',
        updateBooking: 'Update Last Minute Booking',
        dealName: 'Deal name',
        dealAbout: 'About deal',
        dealAboutPlace: 'About deal text...',
        dealAboutBook: 'About last min booking...',
        dealPrice: 'Price of Deal',
        dealPricePlace: 'Price of deal (SEK)',
        dealBookingPlace: 'Price of booking (SEK)',
        dealDiscountDeal: 'Discount On Deal (%)',
        dealOffer: 'Number of offers',
        dealNumber: 'Number of deals',
        dealNumberBooking: 'gs',
        dealNameError:
            'Only alphabets are allowed and length must not be more than 25 characters',
        dealPriceError: 'Only numbers upto 10 digits are allowed',
        dealCountError: 'Only digits are allowed',
        dealDuration: 'Duration of Deals',
        dealEnd: 'Ending time',
        dealStart: 'Starting time',
        dealCallClinic: 'No time restrictions',
        dealCreateButton: 'Create ',
        dealPreviewButton: 'Preview',
        dealUpdateButton: 'Update',
        cardUpdate: 'Update',
        cardDisable: 'Disable',
        cardExpired: 'Expired',
        cardEnable: 'Enable',
        perview: 'Preview',
        previewAppointment: 'Deal Duration',
        close: 'Close',
        logout: 'Logout',
        clinicSettings: 'Clinic Settings',
        cardDisableModalText: 'Are you sure you want to disable this deal?',
        cardDisableModalTextBook:
            'Are you sure you want to disable this Last Min Booking?',
        cardEnableModalText: 'Are you sure you want to enable this deal?',
        language: 'Language',
        engLang: 'English',
        getStarted: 'Get Started',
        bookADemo: 'Book a Demo',
        consultA: 'Consult a',
        dental: 'Dental',
        anyTimeAnyWhere: 'any time, anywhere by',
        joiningUs: 'Joining Us',
        signUp: 'Sign up',
        clinicName: 'Clinic Name',
        yourName: 'Enter Your Name',
        fullName: 'Full Name',
        yourAddress: 'Your Address',
        yourAddressPlaceholder: 'Please enter your address',
        potalcode: 'Postal Code',
        city: 'Post Area',
        cityPlaceholder: 'Please enter post address',
        Email: 'Email',
        Pssword: 'Your Password',
        noOfPatient: 'No of Patients',
        homePage: 'Web Address Link Here (Optional)',
        homePagePlaceholer: 'Please enter web address',
        alreadyAccount: 'Already have an Account?',
        signIn: 'Log In',
        forget: 'Forgot Password?',
        forgetPara:
            'Enter the email associated with your account and we’ll send an email with instructions to reset your password.',
        request: 'Send Request',
        createNew: 'Create New Password',
        newPass:
            '  Your new password must be different from previous used password.',
        enterPass: 'Enter your password',
        confirmPass: 'Enter Confirm Password',
        pasReg: 'Must be atleast 8 Characters',
        emailReg: 'Invalid Format',
        backLogin: 'Back to Login',
        updatePass: 'UPDATE PASSWORD',
        deals: 'Deals',
        lastMin: 'Last Mins Bookings',
        lastMinNav: 'Last Minute',
        Deals: 'Deals',
        Dashboard: 'Home',
        rev: 'Reviews',
        SignUppp: 'Signup',
        Sig: 'Sign Up',
        clicName: 'Clinic Name',
        clicNamePlaceholder: 'Please enter clinic name',
        postalCode: 'Postal Code',
        postalCodePlaceholder: 'Please enter postal code',
        si: 'Sign Up',
        home: 'Home',
        clickUpload:
            'Click to upload or drag and drop SVG, PNG, JPG, or GIF files (maximum size 2MB).',
        attachUser: 'Add User',
        noReview: 'There are no reviews yet',
        swe: 'Swedish',
        dummy: 'Find new patients and get your old customers to visit you more often. Post a deal and post last minute appointments in an easy way.',
        // dummy: "Gnizzel is a marketplace for dental clinics. Join us and make yourself more available. Find new patients by being visible on Gnizzel's marketplace. We help you increase visibility and bring in more patients.",
        customerReview: 'Customer Reviews',
        NoReviewsAvailable: 'No Reviews Available',
        goback: 'Go Back',
        admin: 'Admin',
        clientSay: ' What Client Says About Our Clinic Services.',
        needHelp: 'Need Help?',
        Sign: 'Sign In',
        yesSure: "Yes, I'm Sure",
        phoneNumber: 'Phone Number',
        phoneNumberPlaceholder: 'Please enter phone number',
        attachUserWith: 'Add user with',
        subAttach: 'Please enter user ID to add users.',
        fetchUser: 'Fetch User',
        userId: 'Enter User Account ID',

        iD: 'User Account ID',
        dealTen: 'Deals Available',
        userList: 'User Lists',
        contactUs: 'Contact Us',
        feelFree:
            'Feel Free To Contact Us And We Will Get Back To You As Soon As We Can.',
        enterClinic: 'Enter Clinic Name',
        enterEmail: 'Enter Your Email',
        des: 'Description',
        enterText: 'Enter Text Here...',
        submit: 'Submit Now',
        userInfo: 'User Information',
        firstName: 'First Name',
        surName: 'Surname',
        useDeal: 'Use Deal',
        cancel: 'Cancel',
        user_id: 'USER ID',
        update: 'Update',
        bookName: 'Booking name',
        invalidformat: 'Invalid format',
        previewLastMin: 'Preview',
        nameText: 'NAME',
        phoneText: 'PHONE',
        statusText: 'STATUS',
        noName: 'No Name',
        noText: 'No text',
        pleaseSelectTime: 'Please Select Time',
        noCancel: 'No, Cancel',
        nameError: 'Only alphabets are allowed',
        userInvalidId: 'User ID is not valid.',
        alphaNumericError: 'Only alphanumeric are allowed',
        numberError: 'Only numbers are allowed',
        hyperLinkError:
            'Please enter a valid URL (e.g., https://example.com/).',
        passwordError:
            'Must contain 8 characters, one uppercase, one lowercase, one digit, and one special character',
        phoneNumberError: 'Only Digits are allowed',
        emailError: 'Enter valid email address',
        hi: 'Hi',
        welcomeBack: 'Welcome Back',
        clinicSummary: 'Clinic Summary',
        clinicStep: 'Clinic Steps',
        freqAsk: 'Frequently Asked Questions',
        faqs: 'FAQs',
        forgotPass: 'Forgot Password?',
        loginSuccess: 'Login Successfully',
        verify: 'Verify Email',
        resend: 'Resend Code',
        verifyCode: 'Verify Code',
        faq: 'What is Gnizzel?',
        secondFaq: 'How do I change information about my clinic?',
        thirdFaq: 'What is a deal?',
        forthFaq: 'How do I create a deal?',
        fifthFaq: 'How do I make changes on an existing deal?',
        sixFaq: 'How can I place a time-limit on my deal?',
        sevenFaq:
            'I only want to limit my deal to a certain number of patients.',
        eiFaq: 'What is a "Last Minute" offer?',
        nineFaq: 'How do I create a "Last Minute" offer?',
        tenFaq: 'How do I make changes in an existing "Last Minute" offer?',
        elevenFaq: 'Can I create many last minute offers?',
        twelveFaq:
            'How can I upload my logo to my deal or "Last Minute" bookings?',
        completed: 'Completed',
        inProgress: 'In Progress',
        canceled: 'Canceled',
        thirteenFaq:
            'How many "Deals" or "Last Minute" booking offers can I have at the same time?',
        fourteenFaq: 'How can I remove a "Deal" or "Last Minute" offer?',
        fifteenFaq: 'How do I mark a deal or last minute so it have been used?',
        sixteenFaq: 'What is account user ID?',
        seventeenFaq: 'Where do I enter the account user ID?',
        eighteenFaq:
            'I have discounted price on my offer, How can I show that to the user/patient?',
        nineteenFaq:
            'How do I make sure that my patients can see my deals and last minute offers?',
        twentyFaq: 'What is reviews and how does it work?',
        ansFaq: 'Gnizzel is a marketplace for clinics to find new patients. Clinics can offer deals and available times including last minute appointments.',
        ansTwo: 'Go to settings which you can find on left hand corner of the drop-down menu.',
        ansThree: 'A deal is special offer for new or existing patients.',
        ansFour:
            'Click on "Deals". Select "Create New Deal". Fill in the information and create the deal. The user will see your deal on the mobile app immediately.',
        ansFive:
            'Go to "Deals" and select "Update" button on the deal you want to change. After you have done the changes, just click on the "Save" button.',
        ansSix: 'When creating the deal, you can choose options for "Starting time" and "Ending time" for time-limited deals. You can set time limits by using the calender.',
        ansSeven:
            'Add number of deals in the "Number of Deals" box. Remember to tick the box for "No time restrictions".',
        ansEight:
            'When a clinic has a sudden or unplanned availability, it can show this in Gnizzel by using  the "Last Minute" offer functionality.',
        ansNine:
            'Click on "Last Minute". Fill in the information and create the offer. The user will see your last minute offer on the mobile app immediately.',
        ansTen: 'Go to "Last Minute".  Select "Last Minute Offers"  and click on the offer you wish to change. After you have made the changes, just click on the "Save" button.',
        ansEleven:
            'Yes, we have added a field when creating Last minute offer. You can create up to 10 offers at the same time by adding numbers of offers you want to create. Instead of creating one offer at a time, this will help you to create many offers at the same time if you have many different time available in the day or week.',
        ansTwelve:
            'Go to settings which you can find on the left hand corner drop-down menu. Under settings, you will be able to upload your logo which will show up on all your deals and last-minute offers.',
        ansThirteen:
            'You can have as many "Deals" and "Last Minute" offers as you want. We don\'t have any restrictions.',
        ansFourteen:
            'You can\'t remove a "Deal" or "Last Minute" offer but you can disable it by selecting "Disable". This means that the deal is no longer active and will not be shown on the mobile app.',
        ansFifteen:
            'On the "Deal" and "Last minute" page there is a button call "Add user". When you click on the button you will be able to add account user ID of the caller on the field. By adding the user to the "Deal" or "Last minute" offer will be substracted from the number you given when creating the offers.',
        ansSixteen:
            'Account user ID is a unique number each user or patient get from Gnizzel. User can find the ID on top in the mobile app.',
        ansSeventeen:
            'On the "Deal" and "Last minute" page there is a button call "Add user". When you click on the button you will be able to add account user ID of the caller on the field. By adding the user to the "Deal" or "Last minute" offer will be substracted from the number you given when creating the offers.',
        ansEighteen:
            'We have added a field for you to write you orginal price and discount percentage when creating "Deal" or "Last minute" offers. After adding discounted percentage you will also see show the discounted price. On the mobile app we will show the orginal price, discounted price and discount in percentage.',
        ansNineteen:
            'After creating the account you can printout the QR code under drop down menu or on settings page. You can display the QR code for the customer to download the app.',
        ansTwenty:
            'Each user/patients can give review to the clinic. The reviews are given on the mobile app.',

        invalid: 'Invalid Format',
        setting: 'Clinic Setting',
        updateClinicSetting: 'Update your photo and personal details here',
        postAdd: 'Postadress ',
        eAddress: 'Your Email Address ',
        image: 'Your Image',
        imageDisplay: 'This will be displayed on your Profile. ',
        about: 'About ',
        Cli: 'Clinic',
        cliTime: 'Clinic Timings',
        mon: 'MON',
        tue: 'TUE',
        wed: 'WED',
        thu: 'THU',
        fri: 'FRI',
        sat: 'SAT',
        sun: 'SUN',
        dealClick: 'Click on Deal or Last Minute Booking in the menu',
        dealKey: 'Click on Create deal or Create Last Minute Booking',
        dealSubKey:
            'Fill in the information about the Deal and Last Minute Booking',
        step1: 'Step 1',
        step2: 'Step 2',
        step3: 'Step 3',
        cliPat: 'My Clinic Patients',
        totalPat: 'Total No. of Patients',
        noOfClinic: 'Number of Clinics',
        qr: 'Your QR Code',
        down: 'DOWNLOAD',
        save: 'SAVE',
        error: 'Only alphanumeric are allowed',
        bookCapital: 'Last Minute Bookings',
        timeTo: 'to',
        noTime: 'No Time Restrictions',
        autoCompleteError: 'Please select an option from the list',
        items: 'items',
        total: 'Total',
        lastSuccess: 'Last minute booking created successfully.',
        emailVerify: 'Email verified successfully.',
        resetSuccess: 'Password is reset successfully.',
        myDeal: 'My Deals',
        myLastMinDeal: 'My Last Minute Bookings',
        login: 'Login successfully',
        verSign: 'Account verification code has been sent to your email.',
        forgetPas: 'Email is sent successfully.',
        updateSuccess: 'Deal is updated successfully.',
        updateLastminSuccess: 'Last Minute Booking is updated successfully.',
        createSuccess: 'Deal is created successfully.',
        invalidCredentials: 'Invalid credentials.',
        updateClinic: 'Your data is updated successfully.',
        maxNumberOfChar: 'MAX NUMBER OF CHARACTERS ARE 500.',
        readMore: 'Read more',
        phoneNotAvailable: 'Phone number not available',
        dashboardAboutPlaceholder:
            'Update your information about your clinic under settings.',
        imageUploadingError:
            'The selected image is too large. Please choose an image that is 2MB or smaller in size.',
        cardBooked: 'Booked',
        previewName: 'Name',
        previewAbout: 'About Deal',
        previewTiming: 'Deal Timings',
        previewLocation: 'Location Map',
        previewAddress: 'Clinic Address',
        previewBookAppointment: 'Book Appointment',
        scan: 'Scan',
        qrCode: 'QR Code',
        successContactUs: 'Message has been sent successfully.',
        notifyAttachUser: '*Enter Mobile Account ID',
        notifyLastminDealDiscount: '*Price with discount will be',
        notifyLastminDealCount: '*Create up to 10 last minute bookings',
        printQr: 'Print QR',
        iAgree: 'I agree to',
        termsAndCondition: 'Terms & Conditions',
        termsOfUses: 'Terms of Use (“Terms”)',
        termPara1:
            'Please read these Terms of Use (“Terms”,“Terms of Use”) carefully before using the Gnizzel (or Gnizzle) platform (the “Online Platform”) operated by Woodbury AB 559108-3901, Sweden (“us”, “we”, or “our’).',
        termPara2:
            'Your access to and use of this Online Platform is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all Sellers, participants, visitors, sponsors and others who gain access or use the Online Platform.',
        termPara3:
            'By accessing or using the Online Platform you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Online Platform.',
        overviewTerm: 'Overview',
        overviewPara1:
            'Gnizzel provides a online platform service where consumers and industry businesses can research, locate and shop for various dental services consisting of service ranging from worldwide suppliers, wide range of prices and different brands to respond to Buyers needs.',
        overviewPara2:
            'The main purpose of this Online Platform is to provide an easy access to all users of the healthcare industry to a variety of dental services and allied facilities. The service displayed on the platform are introduced by third parties which have an inclination of doing commerce within the dental industry with its global peers and business networks.',
        accountsRegistration: 'Accounts and Registration',
        accountsRegistrationTerm: `You can browse the service without registering, but in order to utilize the services of the Online Platform you must register for an account. When you register for an account, you may be required to provide us with some information about yourself, such as your email address or other contact information. You agree that the information you provide to us is accurate and that you will keep it accurate and up-to-date at all times. When you register, you will be asked to create a password. You are solely responsible for maintaining the confidentiality of your account and password, and you accept responsibility for all activities that occur under your account. If you have reason to believe that your account is no longer secure, then you must immediately notify us at`,
        acceptContinue: 'Accept & Continue',
        deny: 'Deny',
        usage: 'USAGE',
        usagePara1:
            'The usage of the Online Platform is restricted to those above 18 years of age and who are in a position so as to be fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms & Conditions, and to abide by and comply with these Terms & Conditions.',
        usagePara2:
            'You are solely responsible for protecting the confidentiality of your username and password and any activity under the account will be deemed to have been done by you. In the case that you provide us with false and inaccurate details, we hold the rights to suspend your account and/or terminate this Agreement without being held liable for the same.',
        usagePara3:
            'You are solely responsible for the personal information, and other related/required data you provide on the Online Platform.',
        usagePara4:
            "User discretion is advised while submitting and/or uploading your required personal information on the specified secured place on the Online. This information shall include user's details including contact details, card holder name and other relevant details.",
        usagePara5:
            'If the information provided by you is false/deceptive/inaccurate or misleading, we reserve the right to delete your account without assuming any liability whatsoever.',
        usagePara6:
            "Gnizzel don't take any responsibility information provided by healthcare industry or dentist. All agreement for timings, treatment and misconduct is between user and the healthcare industry or dentist where Gnizzel can not be liable.",
        usagePara7:
            'User can in under no circumstances hold Gnizzel responsible for wrong doing by healthcare or dentist.',
        termination: 'Termination',
        terminationPara1:
            'We may terminate or suspend access to your Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.',
        terminationPara2:
            'All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations liability.',
        terminationPara3:
            'The following terms and conditions govern all the transactions with respect to the access and usage of Gnizzel through the Online Platform, and/or through its authorised third-party websites. These Terms & Conditions form an integral part of Gnizzel and anything that is directly related to the Gnizzel including and unlimited to all the promotional platforms i.e. prints, digitals, online and other related platforms.',
        terminationPara4:
            'The users are advised to make independent assessment in respect of the service quality, fitness of purpose, accuracy, usefulness and/or suitability prior to making any decision.',
        terminationPara5:
            'For the purpose of these Terms & Conditions, wherever the context so require the terms:',
        child1TerminationPara5:
            "'user' / 'you' / 'your' hereafter refers to the user both registered and unregistered who is using and/or browsing through the Online Platform and/or through its authorised third-party websites.",
        child2TerminationPara5:
            "'Gnizzel' or 'services' hereafter refers to the Online Platform using audio, visual and proprietary data communications through the Online Platform.",
        terminationPara6:
            'By using, browsing, accessing the Online Platform, you are bound by these Terms & Conditions and any other terms or policies (which may be amended from time to time) as decided and published by Gnizzel. Any services provided by the Online Platform which do not require registration do not absolve you of the contractual relationship established by these Terms of Use.',
        terminationPara7:
            'The Terms of Use herein form an agreement. Please read this Terms of Use carefully and if you are not agreeable to any terms and condition please do not proceed further. By clicking on “I Agree” and proceeding further you signify that you read through, understood and accepted aforementioned Terms & Conditions. You must agree to the above terms and conditions in order to avail the services through the website. If you have any queries or need any clarification or assistance, please contact us at ',
        terminationPara8:
            'Gnizzel may at any time, without any prior notification to its Users, modify these Terms of Use. Please review the latest version of the Terms and Conditions before proceeding to avail the service of Gnizzel. If you continue to use the service, it shall be deemed that you agree and abide by the modified terms and conditions.',
        usingThePlatform: 'Using the Platform',
        usingThePlatformPara1:
            'Access to the Platform is permitted on a temporary basis and we reserve the right to withdraw or amend the service we provide on the platform or any part of the platform without notice. We will not be liable or responsible if for any reason the platform is unavailable at any time or for any period.',
        usingThePlatformPara2:
            'The information, services on the platform are not intended to amount to an advice on which decision should be made by the user. As such, we disclaim all liability and responsibility arising from any reliance placed on such information and materials by any visitor to the platform, or by any person who may be informed of any of its content.',
        usingThePlatformPara3:
            'You may view (and, where applicable, listen to) the content and applications available on the platform for your own private non-commercial use. You must not use or allow others to access or use, all or any part of our platform or its available contents and/or applications on it for commercial purposes without our permission. Use of all or any part of our platform or the contents and/or applications on it for commercial purposes shall be subject to separate terms and conditions and may be subject to charges.',

        usingThePlatformPara4:
            'You may occasionally print individual webpages from the platform for your private non-commercial use, provided that such printing is not substantial or systematic and our trademarks, copyright notices and trademark notices are not removed.',
        usingThePlatformPara5:
            'From time to time we may restrict access to some parts of the Online Platform or the entire platform to users who have registered with us. Registered users may customise some of their product features to create personalised areas within the platform for their private non-commercial /commercial use. Users must not otherwise alter, adapt reverse engineer any part of the platform.',
        usingThePlatformPara6:
            'Unless otherwise stated in these Terms of Use, you must not (whether directly or indirectly):',
        child1usingThePlatformPara6:
            'distribute, transmit, syndicate, sell or offer to sell or otherwise make available all or any part of the platform or any content, files, feeds or data from the platform, whether publicly available or not; or',

        child2usingThePlatformPara6:
            'copy, download, or store any content, files, feeds or data from the platform, whether publicly available or not, to make or populate a database or publication of any kind whatsoever, provided',
        child3usingThePlatformPara6:
            'You may only play video or audio files using the media player on the Platform or on the website of one of our licensees who is displaying such material with our authorisation',
        child4usingThePlatformPara6:
            'Users should be aware that content and resources may be removed from the Platform with or without notice at any time and we accept no responsibility or liability for any reliance by the user on the continued availability of any content or resources on the platform.',
        dataProtection: 'Data Protection and Use',
        dataProtectionPara1:
            'Gnizzel shall, in providing the Platform set out in these Terms of Use, comply with and all applicable laws and its Privacy Policy relating to the privacy and security of the any personal data (“Data”) and such document may be amended from time to time by Gnizzel in its sole discretion.',
        dataProtectionPara2:
            'The User agrees to process the Data in accordance with these Terms of Use, the Privacy Policy and any lawful instructions reasonably given by Gnizzel from time to time.',
        dataProtectionPara3:
            'Gnizzel shall take appropriate technical and organisational measures against unauthorised or unlawful processing of the Data or its accidental loss, destruction or damage.',
        dataProtectionPara4:
            'In the course of providing the Online Platform, Gnizzel collects data about the User. This data is used to deliver and improve the Platform and the services set out in these Terms of Use. In addition, where this data is, or can be converted into, anonymised data, Gnizzel may use such anonymised data for other purposes including but not limited to analytics and benchmarking. For the avoidance of doubt, Gnizzel will not disclose any data obtained under these Terms of Use which is not anonymised to any third party, as required by law, or to parties involved in the fulfilment of Gnizzel’s obligations under these Terms of Use.',
        ownership: 'Ownership; Proprietary Rights',
        ownershipPara1:
            'The Online Platform is owned and operated by Woodbury AB. The visual interfaces, graphics, design, compilation, information, data, computer codes (including source code or object code), serviceand its specifications, software, services, and all other elements of the Online Platform (“Materials”) provided by Gnizzel are protected by intellectual property and other laws. All Materials included on the online platform are the property of Gnizzel or our third-party licensors. Except as expressly authorised by Woodbury AB, you may not make use of the Materials. Woodbury AB reserves all rights to the Materials not granted expressly in these Terms. Woodbury AB owns and where applicable the license for the Website and Online Platform as well the intellectual property contained thereon including all programs, processes, designs, software, technologies, trademark, trade names, inventions and materials therein. You shall not use the content available on the Website or the Online Platform without our prior written permission.',
        specialError:
            'Only alphanumeric and - / % are allowed and length must not be more than 25 characters',
        clickHere: 'Click Here',
        pricePlanPageHeading: 'Price plan for using the Gnizzel services',
        pricePlanPageDescription:
            'The Gnizzel marketplace is a marketing and sales channel for dental practices. Increase your turnover and let new customers find you by being visible on Gnizzel.',
        priceCardSmallHeading: 'Small',
        priceCardSmallPrice: '399',
        priceCardMediumPrice: '899',
        priceCardLargePrice: '1299',
        priceCardSmallDescription:
            'This price plan is for clinic that have up to 2 dentist',
        priceCardMediumHeading: 'Medium',
        priceCardMediumDescription:
            'This price plan is for clinic that have between 3 and 5 dentist',
        priceCardLargeHeading: 'Large',
        priceCardLargeDescription:
            'This price plan is for clinic that have 6 or more dentist',
        whatsIncluded: "What's Included",
        priceCardSmallFeatureOne: 'For clinics with up to 2 dentist',
        priceCardSmallFeatureTwo: 'Free usage of Deals functionality',
        priceCardSmallFeatureThree: 'Free usage of Last Minute functionality',
        priceCardSmallFeatureFour:
            'Free unique QR code for the clinic to print and display',

        priceCardMediumFeatureOne: 'For clinics with 3 to 5 dentist',
        priceCardMediumFeatureTwo: 'Free usage of Deals functionality',
        priceCardMediumFeatureThree: 'Free usage of Last Minute functionality',
        priceCardMediumFeatureFour:
            'Free unique QR code for the clinic to print and display',

        priceCardLargeFeatureOne: 'For clinics with 6 or more dentist',
        priceCardLargeFeatureTwo: 'Free usage of Deals functionality',
        priceCardLargeFeatureThree: 'Free usage of Last Minute functionality',
        priceCardLargeFeatureFour:
            'Free unique QR code for the clinic to print and display',
        month: 'Month',
        uploadImage: 'Upload Image',
        pricingButton: 'Try Gnizzel 3 months for free',
        gnizzelServices: 'Gnizzel services',
        PricePlan: 'Price plan for using the ',
        clinicJoinGnizzel: 'Why should your clinic join Gnizzel?',
        clinicJoinGnizzelHeadingOne:
            'Increase the visibility and influx of new patients!',
        clinicJoinGnizzelHeadingTwo: 'Reach more patients!',
        clinicJoinGnizzelHeadingThree: 'Help improve dental health!',
        clinicJoinGnizzelParagraphOne:
            'Gnizzel contributes to more patients coming and visiting the clinic more often.',
        clinicJoinGnizzelParagraphTwo:
            'Through Gnizzel marketplace, you can reach old and new patients with our mobile app.',
        clinicJoinGnizzelParagraphThree:
            'As more people get older and need help and support with their daily care, the need for support for oral health also increases.',
        customerOfGnizzel: 'This is what you get as a customer of Gnizzel.',
        customerOfGnizzelHeadingOne:
            'Create your own deals to attract new customers to your clinic',
        customerOfGnizzelHeadingTwo:
            'Use our Last Minute service to fill your free times',
        customerOfGnizzelHeadingThree: 'Have direct contact with the patient',
        customerOfGnizzelParagraphOne:
            'By adding new deal from your account, Gnizzel mobile app can reach many new and existing customers.',
        customerOfGnizzelParagraphTwo:
            'If you have any available timings then you can also show them to new and existing customers by going to the "Last Minute" service.',
        customerOfGnizzelParagraphThree:
            'Patients can call you to make an appointment.',
        termPayment: 'Payment',
        termPaymentSubscription: 'Subscription terms and billing',
        termPaymentSubscriptionPara1: `The subscription starts to run from the date on which registration takes place on the service and runs until it is terminated upon termination or closed account. The notice period is three months.`,
        termPaymentSubscriptionPara2: `The first billing period runs from the order date to the last day of the current month. Invoicing takes
        place in advance, unless otherwise stated in a
        separate agreement between the parties or otherwise
        stated in the terms and conditions relating to the
        specific service.`,
        termPaymentSubscriptionPara3: `The service is made available via the internet and
        constitutes software as a service. The customer does
        not acquire the Service or a copy or part of it and
        is not given a license to utilize the Service in any
        way other than as software as a service.`,
        termPaymentPrice: 'Prices and Payment terms',
        termPaymentPricePara1: `Payment must be made no later than 10 days after the
        invoice date, or in case of short payment, the
        amount will be withdrawn no later than the end of
        the current month. If the invoice is not paid on the
        invoice date, Gnizzel has the right to block the
        service for the Customer. The service will be
        reactivated when payment has been made unless
        Gnizzel has canceled the subscription.`,
        termPaymentPricePara2: `Current prices and subscription forms may change.
        The customer must be informed of the change no later
        than one month before it takes effect, except when
        there are discounted prices or promotions, in which
        case Gnizzel has the right to make the changes
        immediately. All prices are stated excluding value
        added tax.`,
        currency: 'AED',
    },
};

export default eng;
