import imageUpload from "./imageUpload.svg";
import searchIcon from "../images/Vector.svg"
import smallPackage from './smallPackage.svg';
import mediumPackage from './mediumPackage.svg';
import largePackage from './largePackage.svg';
import checkMarkActive from './checkmark.svg';
import checkMark from './checkmarkGrey.svg';
import listIcon from './listIcon.svg';

const IconsImages = {
    imageUpload: imageUpload,
    searchIcon: searchIcon,
    largePackage,
    smallPackage,
    mediumPackage,
    checkMark,
    checkMarkActive,
    listIcon,
};

export default IconsImages;
