import React from 'react';
import { PricingCard } from '../../commonComponents';
import { Col, Row } from 'antd';
import IconsImages from '../../../assets/Icons';
import { useTranslation } from 'react-i18next';
import './Pricing.css';

export default function Pricing() {
    const { t } = useTranslation();
    return (
        <section id="pricingPlan">
            <div className="headingWrapper">
                <h1 className="pricingHeading">{t('pricePlanPageHeading')}</h1>
                <span className="pricingParagraph">
                    {t('pricePlanPageDescription')}
                </span>
                <hr className="pricingHr" />
            </div>
            <div className="pricingPlanButtonWrapper">
                <p className="pricingPlanButton">{t('pricingButton')}</p>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Row gutter={[16, 16]} className="pricingGridLayout">
                    <Col xs={24} lg={8}>
                        <PricingCard
                            icon={IconsImages.smallPackage}
                            offPercentage="57% Off"
                            plan={{
                                title: t('priceCardSmallHeading'),
                                description: t('priceCardSmallDescription'),
                                price: t('priceCardSmallPrice'),
                                discount: '1399',
                                features: [
                                    t('priceCardSmallFeatureOne'),
                                    t('priceCardSmallFeatureTwo'),
                                    t('priceCardSmallFeatureThree'),
                                    t('priceCardSmallFeatureFour'),
                                ],
                            }}
                            currency={t('currency')}
                        />
                    </Col>
                    <Col xs={24} lg={8}>
                        <PricingCard
                            icon={IconsImages.mediumPackage}
                            plan={{
                                title: t('priceCardMediumHeading'),
                                description: t('priceCardMediumDescription'),
                                price: t('priceCardMediumPrice'),
                                discount: '1999',
                                features: [
                                    t('priceCardMediumFeatureOne'),
                                    t('priceCardMediumFeatureTwo'),
                                    t('priceCardMediumFeatureThree'),
                                    t('priceCardMediumFeatureFour'),
                                ],
                            }}
                            offPercentage="50% Off"
                            active
                            currency={t('currency')}
                        />
                    </Col>
                    <Col xs={24} lg={8}>
                        <PricingCard
                            icon={IconsImages.largePackage}
                            offPercentage="52% Off"
                            plan={{
                                title: t('priceCardLargeHeading'),
                                description: t('priceCardLargeDescription'),
                                price: t('priceCardLargePrice'),
                                discount: '2899',
                                features: [
                                    t('priceCardLargeFeatureOne'),
                                    t('priceCardLargeFeatureTwo'),
                                    t('priceCardLargeFeatureThree'),
                                    t('priceCardLargeFeatureFour'),
                                ],
                            }}
                            currency={t('currency')}
                        />
                    </Col>
                </Row>
            </div>
            <br />
        </section>
    );
}
