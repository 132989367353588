import React from 'react';
import './PricingCard.css';
import IconsImages from '../../../assets/Icons';
import { useTranslation } from 'react-i18next';
export default function PricingCard({
    icon,
    active = false,
    offPercentage = '29% Off',
    plan,
    currency,
}) {
    const { t } = useTranslation();
    return (
        <div
            className={
                active ? 'pricingCardWrapperActive' : 'pricingCardWrapper'
            }
        >
            <div className="pricingCardWrapperOuter">
                <div className="pricingCardIcon">
                    <img src={icon} alt={icon} />
                    <span
                        className={
                            active
                                ? 'pricingCardOffPercentageActive'
                                : 'pricingCardOffPercentage'
                        }
                    >
                        {offPercentage}
                    </span>
                </div>
                <h1
                    className={
                        active
                            ? 'pricingCardHeadingActive'
                            : 'pricingCardHeading'
                    }
                >
                    {plan?.title}
                </h1>
                <p className="pricingCardParagraph">{plan?.description}</p>

                <h3 className="pricingCardOfferHeading">
                    {t('whatsIncluded')}:
                </h3>
                {Array.isArray(plan.features) &&
                    plan.features.map((item) => (
                        <p
                            className={
                                active
                                    ? 'pricingCardActiveParagraph'
                                    : 'pricingCardParagraph'
                            }
                        >
                            <img
                                src={
                                    active
                                        ? IconsImages.checkMarkActive
                                        : IconsImages.checkMark
                                }
                                alt={active ? 'checkMarkActive' : 'checkMark'}
                            />
                            {item}
                        </p>
                    ))}

                <h1 className="pricingCardAmountHeading">
                    <span
                        className={
                            active
                                ? 'pricingCardAmountActive'
                                : 'pricingCardAmount'
                        }
                    >
                        {plan.price} {currency}{' '}
                        <del className="pricingCardDiscountAmount">
                            {plan.discount} {currency}
                        </del>
                    </span>{' '}
                    / {t('month')}
                </h1>
            </div>
        </div>
    );
}
