import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ClinicButton, ClinicCreateBox } from '../../commonComponents';
import { Spin, Input, Pagination, Row, Col, Grid } from 'antd';
import IconsImages from '../../../assets/Icons';
import LastMinModal from './LastMinModel';
import { getApiWithAuth } from '../../../utils/api';
import { Url } from '../../../utils/apiUrls';
import LastMinModelUpdate from './LastMinModelUpdate';
import { CloseOutlined } from '@ant-design/icons';
import LastMinCard from './LastMinCard/LastMinCard';
import { useLocation } from 'react-router-dom';
import './LastMin.css';

const { useBreakpoint } = Grid;
const minimum = 0;

const CreateDeal = ({ setIsModalOpen, isModalOpen }) => {
    const { t } = useTranslation();
    const screen = useBreakpoint();
    return (
        <Row
            justify="center"
            className={
                screen.lg
                    ? 'lastmin-dealContainer-lg-view'
                    : 'lastminDealContainer'
            }
        >
            <Col span={24}>
                <h1 className="dealHeader">{t('noBookingHeader')}</h1>
                <h3 className="dealSubHeader">{t('noBookingSubHeader')}</h3>
                <hr className="dealLine" />
            </Col>
            <Col span={24}>
                <div className="dealCreateBox">
                    <ClinicCreateBox
                        text={t('createLastMinCard')}
                        onClick={() => setIsModalOpen(!isModalOpen)}
                    />
                </div>
            </Col>
        </Row>
    );
};

const LastMin = () => {
    const screen = useBreakpoint();
    const { t } = useTranslation();
    const { state } = useLocation();
    const pageRef = useRef(null);
    const [dealCardArray, setDealCardArray] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [newCardAdd, setNewCardAdd] = useState(true);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState({});
    const [pageNo, setPageNo] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalNo, setTotalNo] = useState(0);
    const [search, setSearch] = useState('');
    const [searchLoader, setSearchLoader] = useState(false);

    const apiHandle = async (page = 1) => {
        if (search === '') setIsLoading(true);

        let urlParams =
            Url.LAST_MIN_BOOKING_LIST_URL + `&page=${page}&page_size=${12}`;

        if (search !== '') {
            setSearchLoader(true);
            urlParams = urlParams + `&search=${search}`;
        }

        try {
            const response = await getApiWithAuth(urlParams);
            if (response.success) {
                const { data, page, page_size, total } = response.data;

                setPageNo(page);
                setLimit(page_size);
                setTotalNo(total);
                setDealCardArray(data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
            setSearchLoader(false);
            setNewCardAdd(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            apiHandle(1);
        }
    };

    const onClickFilterClose = () => {
        setSearch('');
        apiHandle(1);
    };

    const cardDealsFun = () => {
        return (
            <>
                <div
                    className={
                        screen.lg
                            ? 'lastmin-dealContainer-lg-view'
                            : 'lastminDealContainer'
                    }
                    tabIndex="3"
                >
                    <div>
                        <Row
                            justify="space-between" // center
                            align="middle"
                            gutter={[8, 16]}
                        >
                            <Col
                                xs={24}
                                md={12}
                                sm={12}
                                lg={7}
                                xl={7}
                                order={!screen.lg ? 2 : 1}
                            >
                                <div className="dealSelect">
                                    <Input
                                        placeholder={t('filter')}
                                        className="dealFilter"
                                        value={search}
                                        name="filter"
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setSearch(value);
                                        }}
                                        onKeyDown={handleKeyDown}
                                        suffix={
                                            search === '' ? (
                                                <img
                                                    src={IconsImages.searchIcon}
                                                    className="dealFilterIcon"
                                                    alt="search-icon"
                                                />
                                            ) : (
                                                <div
                                                    className="dealFilterIcon"
                                                    onClick={onClickFilterClose}
                                                >
                                                    <CloseOutlined />
                                                </div>
                                            )
                                        }
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={24}
                                lg={10}
                                xl={10}
                                order={!screen.lg ? 1 : 2}
                            >
                                <div className="dealHeadingContainer">
                                    <h1 className="dealHeader">
                                        {t('bookCapital')}
                                    </h1>
                                    <h3 className="dealSubHeader">
                                        {t('dealsDetail')}
                                    </h3>
                                </div>
                            </Col>
                            <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={7}
                                xl={7}
                                order={3}
                            >
                                <div className="dealNewButton">
                                    <ClinicButton
                                        title={t('createNewBook')}
                                        height={50}
                                        fontSize={16}
                                        onClick={() =>
                                            setIsModalOpen(!isModalOpen)
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <hr className="dealLine" />

                    <div>
                        <Row gutter={[16, 16]}>
                            {searchLoader ? (
                                <div className="spinnerDiv">
                                    <Spin size="large" />
                                </div>
                            ) : dealCardArray.length > minimum ? (
                                dealCardArray.map((item, index) => {
                                    return (
                                        <Col
                                            xs={24}
                                            sm={24}
                                            md={12}
                                            lg={8}
                                            xxl={6}
                                            key={index}
                                        >
                                            <LastMinCard
                                                item={item}
                                                setNewCardAdd={setNewCardAdd}
                                                setIsUpdateModalOpen={
                                                    setIsUpdateModalOpen
                                                }
                                                isUpdateModalOpen={
                                                    isUpdateModalOpen
                                                }
                                                setSelectedCard={
                                                    setSelectedCard
                                                }
                                            />
                                        </Col>
                                    );
                                })
                            ) : (
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    <h1 className="dealHeader">
                                        {t('lastMinDealNotFound')}
                                    </h1>
                                    <h3 className="dealSubHeader">
                                        {t('searchKeyword')} "{search}"
                                    </h3>
                                </div>
                            )}
                        </Row>
                    </div>
                    {dealCardArray?.length > minimum && (
                        <div className="paginationContainer">
                            <Row justify="center">
                                <Col>
                                    <Pagination
                                        total={totalNo}
                                        showTotal={(total) =>
                                            `${t('total')} ${total}  ${t(
                                                'items',
                                            )}`
                                        }
                                        defaultPageSize={limit}
                                        current={pageNo}
                                        defaultCurrent={pageNo}
                                        onChange={(page, pageSize) => {
                                            setIsLoading(!isLoading);
                                            apiHandle(page);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                    <br />
                </div>
            </>
        );
    };

    useEffect(() => {
        if (newCardAdd === true) apiHandle(1);
    }, [newCardAdd]);

    useEffect(() => {
        apiHandle(1);
    }, [search]);

    const isVisited = useMemo(
        () => !!localStorage.getItem('visit'),
        [localStorage],
    );

    useEffect(() => {
        if (state?.value === 'true' && !isVisited) {
            setIsModalOpen(true);
        }
        return function cleanup() {
            localStorage.setItem('visit', 'closed');
        };
    }, []);

    useEffect(() => {
        pageRef.current?.focus();
        window.scrollTo(0, 0);
    }, []);

    return (
        <main ref={pageRef} tabIndex="0">
            {isLoading ? (
                <div className="spinnerDiv">
                    <Spin size="large" />
                </div>
            ) : dealCardArray?.length > minimum || search !== '' ? (
                cardDealsFun()
            ) : (
                <CreateDeal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
            )}

            {isModalOpen ? (
                <LastMinModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    setNewCardAdd={setNewCardAdd}
                    getListing={apiHandle}
                />
            ) : isUpdateModalOpen ? (
                <LastMinModelUpdate
                    isModalOpen={isUpdateModalOpen}
                    setIsModalOpen={setIsUpdateModalOpen}
                    setNewCardAdd={setNewCardAdd}
                    selectedCard={selectedCard}
                />
            ) : (
                ''
            )}
        </main>
    );
};

export default LastMin;
