import { Modal, Form, DatePicker, Rate, Row, Col, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ClinicButton, ClinicInput } from '../../commonComponents';
import { ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getApiWithAuth, postApiWithAuth } from '../../../utils/api';
import { Url } from '../../../utils/apiUrls';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { debounce, disabledDateTime, pickers } from '../../../utils/helper';
import dayjs from 'dayjs';
import useUser from '../../../hooks/useUser';
import clock from '../../../assets/images/clock.svg';
import location from '../../../assets/images/location.png';

import './LastMin.css';
import { Images } from '../../../assets/images';

const googleMapUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API}`;

const initialValue = undefined;

const { useBreakpoint } = Grid;

const removeButtonStyles = {
    color: 'red',
    fontSize: '16px',
    cursor: 'pointer',
};

const discountCalculate = (value, discountPercentage) => {
    const discountedvalue = value - value * (discountPercentage / 100);
    return Math.ceil(discountedvalue);
};

const LastMinModal = ({
    isModalOpen,
    setIsModalOpen,
    setNewCardAdd,
    getListing,
}) => {
    LastMinModal.propTypes = {
        isModalOpen: PropTypes.bool,
        setIsModalOpen: PropTypes.func,
        setNewCardAdd: PropTypes.func,
        getListing: PropTypes.func,
    };

    LastMinModal.defaultProps = {
        setIsModalOpen: () => {},
        isModalOpen: false,
        setNewCardAdd: () => {},
    };

    const screens = useBreakpoint();

    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [form] = Form.useForm();
    const { userData } = useUser();

    const [dealData, setDealData] = useState({
        name: '',
        price: '',
        about: '',
        clinic_call: false,
        last_min_booking: true,
        discount_percentage: null,
        last_min_booking_count: 1,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [errorTextPrice, setErrorTextPrice] = useState('');
    const [warnText, setWarnText] = useState('');
    const [DateObjects, setDateObjects] = useState({});
    const [currentPicker, setCurrentPicker] = useState(pickers[0]);
    const [isOpenStartDate, setIsOpenStartDate] = useState(null);
    const [isOpenEndDate, setIsOpenEndDate] = useState(false);
    const [dashboardData, setDashboardData] = useState();

    const letterRegix = /^[a-zA-Z0-9åäöÅÄÖ\s%-/]{1,25}$/;
    const numberRegix = /^[0-9\b]+$/;

    const onChangeInput = (event) => {
        const { name, value } = event.target;
        if (name === 'name') {
            if (value.match(letterRegix)) {
                if (value.length === 25) {
                    setWarnText(t('specialError'));
                } else {
                    setWarnText('');
                }
                setErrorText('');
            } else {
                setErrorText(t('specialError'));
            }
        }

        if (name === 'price') {
            const { name, value } = event.target;
            const inputValue = parseInt(value);

            if (numberRegix.test(value) && value.length < 10) {
                if (!isNaN(inputValue) && inputValue > 0) {
                    setDealData({ ...dealData, [name]: inputValue.toString() });
                    setErrorTextPrice('');
                } else {
                    setErrorTextPrice(t('dealPriceError'));
                    setDealData({ ...dealData });
                }
            } else {
                if (value === '') {
                    setErrorTextPrice(t('dealPriceError'));
                    setDealData({ ...dealData, [name]: value });
                } else {
                    setDealData({ ...dealData });
                }
            }
        } else {
            setDealData({ ...dealData, [name]: value });
        }
        if (name === 'last_min_booking_count')
            setDealData({ ...dealData, last_min_booking_count: value });
    };

    const onChangeNumber = (event) => {
        const { name, value } = event.target;
        const inputValue = parseInt(value);
        if (!isNaN(inputValue) && numberRegix.test(value) && inputValue > 0) {
            // if (inputValue > 100) setDealData({ ...dealData, [name]: 100 });
            // else setDealData({ ...dealData, [name]: inputValue });
            if (
                (inputValue === 0 && dealData[name] === 1) || // Allow 0 only after 1
                (inputValue >= 1 && inputValue <= 100) // Allow values between 1 and 100
            ) {
                setDealData({ ...dealData, [name]: inputValue });
            } else {
                setDealData({ ...dealData });
            }
        } else {
            if (value === '') setDealData({ ...dealData, [name]: value });
            else setDealData({ ...dealData });
        }
    };

    const onChangeOffer = (event) => {
        const { name, value } = event.target;
        const inputValue = parseInt(value);

        if (!isNaN(inputValue) && numberRegix.test(value) && inputValue > 0) {
            // if (inputValue > 10) setDealData({ ...dealData, [name]: 10 });
            // else setDealData({ ...dealData, [name]: inputValue });
            if (
                (inputValue === 0 && dealData[name] === 1) || // Allow 0 only after 1
                (inputValue >= 1 && inputValue <= 10) // Allow values between 1 and 10
            ) {
                setDealData({ ...dealData, [name]: inputValue });
            } else {
                setDealData({ ...dealData });
            }
        } else {
            if (value === '') setDealData({ ...dealData, [name]: value });
            else setDealData({ ...dealData });
        }
    };

    const offerHelper = useCallback(() => {
        let tempObject = {};
        for (const key in DateObjects) {
            if (parseInt(key) + 1 <= dealData.last_min_booking_count) {
                tempObject[key] = DateObjects[key];
            }
        }
        setDateObjects(tempObject);
    }, [dealData.last_min_booking_count]);

    useEffect(() => {
        offerHelper();
    }, [offerHelper]);

    const onChangeDate = (
        name,
        value,
        dateString,
        index,
        isMobile = false,
        picker,
    ) => {
        // setDealData({ ...dealData, [name]: value ? dateString : '' });
        setDateObjects({ ...DateObjects, [index]: value ? dateString : '' });
        if (isMobile) {
            if (picker === pickers[0]) {
                setCurrentPicker(pickers[1]);
            } else {
                setIsOpenEndDate(false);
                setIsOpenStartDate(null);
                setCurrentPicker(pickers[0]);
            }
        }
    };

    useEffect(() => {
        if (dealData.clinic_call === true) setDealData({ ...dealData });
        else setDateObjects({});
    }, [dealData.clinic_call]);

    const addLineBreaks = (text) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    useEffect(() => {
        const getData = async () => {
            const response = await getApiWithAuth(Url.DASHBOARD);
            if (response?.success) {
                setDashboardData(response.data);
            }
        };
        getData();
    }, []);

    const onFinish = async () => {
        setIsLoading(true);

        // Check if end_time is an empty string, and if so, remove it from postData
        const { end_time, ...postData } = dealData;
        if (end_time === '') {
            postData.end_time = null; // Set it to null before making the API call
        }

        if (!postData.discount_percentage) {
            delete postData.discount_percentage;
        }

        const date_array = Array.from.call(DateObjects, {
            length: dealData.last_min_booking_count,
            ...DateObjects,
        });
        const start_time_array = date_array?.map((date) => ({
            start_time: date,
        }));

        delete postData.start_time;
        const response = await postApiWithAuth(Url.CREATE_DEAL, {
            ...postData,
            start_time_array,
        });

        if (response.success) {
            setIsModalOpen(false);
            enqueueSnackbar(t('lastSuccess'), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'success',
            });
            getListing();
        } else {
            setIsLoading(false);
            setNewCardAdd(true);
            enqueueSnackbar(response.message.data.error, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'error',
            });
        }
    };

    const optimizedFunction = useCallback(debounce(onFinish), [
        dealData,
        DateObjects,
    ]);

    const onRemoveDate = (index) => {
        const arrayOfDates = Array.from.call(DateObjects, {
            length: dealData.last_min_booking_count,
            ...DateObjects,
        });

        if (!Array.isArray(arrayOfDates)) return;
        arrayOfDates.splice(index, 1);

        setDateObjects([...arrayOfDates, initialValue]);
        setDealData({
            ...dealData,
            last_min_booking_count: dealData.last_min_booking_count - 1,
        });
    };

    return (
        <>
            <div className="modelScroll">
                <Modal
                    open={isModalOpen}
                    footer={false}
                    className="dealModal"
                    onCancel={() => setIsModalOpen(false)}
                    centered
                    maskClosable={false}
                >
                    <div className="modelContantCreate">
                        <h1 className="modalDealHeader">
                            {t('createLastMinCard')}
                        </h1>
                        <hr className="modalDealLine" />
                        <div style={{ margin: '1rem auto' }}>
                            <Form
                                layout="vertical"
                                onFinish={optimizedFunction}
                                form={form}
                                initialValues={dealData}
                                autoComplete="off"
                                fields={[
                                    {
                                        name: ['discount_percentage'],
                                        value: dealData.discount_percentage,
                                    },

                                    {
                                        name: ['price'],
                                        value: dealData.price,
                                    },
                                    {
                                        name: ['last_min_booking_count'],
                                        value: dealData.last_min_booking_count,
                                    },
                                ]}
                            >
                                <Row gutter={[16, 16]}>
                                    <Col xs={24}>
                                        <div className="inputModalText">
                                            <ClinicInput
                                                placeholder={t('bookName')}
                                                type="text"
                                                height={30}
                                                name="name"
                                                onChange={onChangeInput}
                                                value={dealData.name}
                                                maxLength={25}
                                                required
                                                errorMessage={t(
                                                    'dealNameError',
                                                )}
                                            />
                                            <div className="errorStyleUpdtee">
                                                {errorText || warnText}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <div className="inputModalText">
                                            <ClinicInput
                                                className="textAreaDeal"
                                                name={'about'}
                                                type={'textarea'}
                                                value={dealData.about}
                                                placeholder={t('dealAboutBook')}
                                                maxLength={250}
                                                textAreaMaxRow={2}
                                                onChange={onChangeInput}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <div className="inputModalText">
                                            <ClinicInput
                                                placeholder={t(
                                                    'dealBookingPlace',
                                                )}
                                                type="text"
                                                height={30}
                                                width={100}
                                                name="price"
                                                onChange={onChangeInput}
                                                Value={dealData?.price}
                                                className="pricrUpdate"
                                                required
                                                errorMessage={t(
                                                    'dealPriceError',
                                                )}
                                            />
                                            {errorTextPrice !== '' ? (
                                                <span className="errorStylePrice">
                                                    {errorTextPrice}
                                                </span>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <div className="inputModalText">
                                            <ClinicInput
                                                placeholder={t(
                                                    'dealDiscountDeal',
                                                )}
                                                height={30}
                                                width={100}
                                                name="discount_percentage"
                                                onChange={onChangeNumber}
                                                disabled={
                                                    dealData.price == '' ||
                                                    dealData.price < 1
                                                }
                                                Value={
                                                    dealData?.discount_percentage
                                                }
                                                className="pricrUpdate"
                                                required
                                                errorMessage={t(
                                                    'dealPriceError',
                                                )}
                                            />

                                            {dealData?.discount_percentage >
                                            0 ? (
                                                <span
                                                    style={{
                                                        color: '#2ed3c5',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    {t(
                                                        'notifyLastminDealDiscount',
                                                    )}{' '}
                                                    {discountCalculate(
                                                        parseInt(
                                                            dealData?.price,
                                                        ),
                                                        dealData?.discount_percentage,
                                                    )}{' '}
                                                    (SEK)
                                                </span>
                                            ) : null}
                                        </div>
                                    </Col>

                                    <Col xs={24}>
                                        <div className="inputModalText">
                                            <ClinicInput
                                                placeholder={t('dealOffer')}
                                                height={30}
                                                name="last_min_booking_count"
                                                onChange={onChangeOffer}
                                                Value={
                                                    dealData?.last_min_booking_count
                                                }
                                                className="pricrUpdate"
                                                required
                                                errorMessage={t(
                                                    'dealPriceError',
                                                )}
                                                min={1}
                                                max={10}
                                            />
                                            <span
                                                style={{
                                                    color: '#2ed3c5',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {t('notifyLastminDealCount')}
                                            </span>
                                        </div>
                                    </Col>

                                    {dealData?.count !== 0 &&
                                        Array.from({
                                            length: dealData.last_min_booking_count,
                                        }).map((item, index) => (
                                            <Col xs={24} key={index}>
                                                <Row
                                                    align={'middle'}
                                                    gutter={[8, 8]}
                                                >
                                                    <Col xs={24} key={index}>
                                                        {screens.xs ? (
                                                            <div className="modalDateContainer">
                                                                <DatePicker
                                                                    className="modalDateLastMin"
                                                                    placeholder={t(
                                                                        'dealStart',
                                                                    )}
                                                                    allowClear={
                                                                        false
                                                                    }
                                                                    minuteStep={
                                                                        30
                                                                    }
                                                                    picker={
                                                                        currentPicker
                                                                    }
                                                                    open={
                                                                        index ===
                                                                        isOpenStartDate
                                                                    }
                                                                    onClick={() => {
                                                                        setIsOpenStartDate(
                                                                            index,
                                                                        );
                                                                    }}
                                                                    inputReadOnly
                                                                    format="YYYY-MM-DD HH:mm"
                                                                    value={
                                                                        DateObjects[
                                                                            index
                                                                        ] !==
                                                                        initialValue
                                                                            ? dayjs(
                                                                                  DateObjects[
                                                                                      index
                                                                                  ],
                                                                              )
                                                                            : initialValue
                                                                    }
                                                                    getPopupContainer={(
                                                                        trigger,
                                                                    ) => {
                                                                        return trigger.parentElement;
                                                                    }}
                                                                    onChange={(
                                                                        date,
                                                                        dateString,
                                                                    ) =>
                                                                        onChangeDate(
                                                                            'start_time',
                                                                            date,
                                                                            dateString,
                                                                            index,
                                                                            screens.xs,
                                                                            currentPicker,
                                                                        )
                                                                    }
                                                                    onOk={(
                                                                        selectedDate,
                                                                    ) => {
                                                                        if (
                                                                            !selectedDate
                                                                        )
                                                                            return;

                                                                        let currentDate =
                                                                            dayjs();

                                                                        let minutes =
                                                                            currentDate.date() ===
                                                                                selectedDate.date() &&
                                                                            currentDate.hour() ===
                                                                                selectedDate.hour()
                                                                                ? currentDate.minute()
                                                                                : selectedDate.minute();

                                                                        let value =
                                                                            selectedDate.minute(
                                                                                minutes >
                                                                                    0
                                                                                    ? 30
                                                                                    : 0,
                                                                            );
                                                                        let dateString =
                                                                            value.format(
                                                                                'YYYY-MM-DD HH:mm',
                                                                            );

                                                                        return onChangeDate(
                                                                            'start_time',
                                                                            value,
                                                                            dateString,
                                                                            index,
                                                                            screens.xs,
                                                                            currentPicker,
                                                                        );
                                                                    }}
                                                                    suffixIcon={
                                                                        <ClockCircleOutlined />
                                                                    }
                                                                    disabled={
                                                                        dealData.clinic_call
                                                                    }
                                                                    name={
                                                                        'start_time'
                                                                    }
                                                                    disabledDate={(
                                                                        current,
                                                                    ) => {
                                                                        let customDate =
                                                                            moment().format(
                                                                                'YYYY-MM-DD',
                                                                            );
                                                                        return (
                                                                            current &&
                                                                            current <
                                                                                moment(
                                                                                    customDate,
                                                                                    'YYYY-MM-DD',
                                                                                )
                                                                        );
                                                                    }}
                                                                    disabledTime={(
                                                                        current,
                                                                    ) => {
                                                                        let hours =
                                                                            !current?.isSame(
                                                                                moment(),
                                                                            ) &&
                                                                            current?.isBefore(
                                                                                moment(),
                                                                            )
                                                                                ? moment().hour()
                                                                                : 0;

                                                                        let mins =
                                                                            !current?.isSame(
                                                                                moment(),
                                                                            ) &&
                                                                            current?.isBefore(
                                                                                moment(),
                                                                            )
                                                                                ? moment().minute()
                                                                                : 0;
                                                                        return disabledDateTime(
                                                                            hours,
                                                                            mins,
                                                                        );
                                                                    }}
                                                                    showNow={
                                                                        false
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="modalDateContainer">
                                                                <DatePicker
                                                                    className="modalDateLastMin"
                                                                    placeholder={t(
                                                                        'dealStart',
                                                                    )}
                                                                    allowClear={
                                                                        false
                                                                    }
                                                                    showTime={{
                                                                        format: 'HH:mm',
                                                                    }}
                                                                    minuteStep={
                                                                        30
                                                                    }
                                                                    inputReadOnly
                                                                    format="YYYY-MM-DD HH:mm"
                                                                    value={
                                                                        DateObjects[
                                                                            index
                                                                        ] !==
                                                                        initialValue
                                                                            ? dayjs(
                                                                                  DateObjects[
                                                                                      index
                                                                                  ],
                                                                              )
                                                                            : initialValue
                                                                    }
                                                                    popupStyle={{
                                                                        width: screens.xs
                                                                            ? 250
                                                                            : 'auto',
                                                                        height: screens.xs
                                                                            ? 250
                                                                            : 'auto',
                                                                    }}
                                                                    getPopupContainer={(
                                                                        trigger,
                                                                    ) => {
                                                                        return trigger.parentElement;
                                                                    }}
                                                                    onChange={(
                                                                        date,
                                                                        dateString,
                                                                    ) =>
                                                                        onChangeDate(
                                                                            'start_time',
                                                                            date,
                                                                            dateString,
                                                                            index,
                                                                        )
                                                                    }
                                                                    onOk={(
                                                                        selectedDate,
                                                                    ) => {
                                                                        if (
                                                                            !selectedDate
                                                                        )
                                                                            return;

                                                                        let currentDate =
                                                                            dayjs();

                                                                        let minutes =
                                                                            currentDate.date() ===
                                                                                selectedDate.date() &&
                                                                            currentDate.hour() ===
                                                                                selectedDate.hour()
                                                                                ? currentDate.minute()
                                                                                : selectedDate.minute();

                                                                        let value =
                                                                            selectedDate.minute(
                                                                                minutes >
                                                                                    0
                                                                                    ? 30
                                                                                    : 0,
                                                                            );
                                                                        let dateString =
                                                                            value.format(
                                                                                'YYYY-MM-DD HH:mm',
                                                                            );

                                                                        return onChangeDate(
                                                                            'start_time',
                                                                            value,
                                                                            dateString,
                                                                            index,
                                                                        );
                                                                    }}
                                                                    suffixIcon={
                                                                        <ClockCircleOutlined />
                                                                    }
                                                                    disabled={
                                                                        dealData.clinic_call
                                                                    }
                                                                    name={
                                                                        'start_time'
                                                                    }
                                                                    disabledDate={(
                                                                        current,
                                                                    ) => {
                                                                        let customDate =
                                                                            moment().format(
                                                                                'YYYY-MM-DD',
                                                                            );
                                                                        return (
                                                                            current &&
                                                                            current <
                                                                                moment(
                                                                                    customDate,
                                                                                    'YYYY-MM-DD',
                                                                                )
                                                                        );
                                                                    }}
                                                                    disabledTime={(
                                                                        current,
                                                                    ) => {
                                                                        let hours =
                                                                            !current?.isSame(
                                                                                moment(),
                                                                            ) &&
                                                                            current?.isBefore(
                                                                                moment(),
                                                                            )
                                                                                ? moment().hour()
                                                                                : 0;

                                                                        let mins =
                                                                            !current?.isSame(
                                                                                moment(),
                                                                            ) &&
                                                                            current?.isBefore(
                                                                                moment(),
                                                                            )
                                                                                ? moment().minute()
                                                                                : 0;
                                                                        return disabledDateTime(
                                                                            hours,
                                                                            mins,
                                                                        );
                                                                    }}
                                                                    showNow={
                                                                        false
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </Col>
                                                    {/* {index > 0 ||
                                                    dealData.last_min_booking_count >
                                                        1 ? (
                                                        <Col
                                                            xs={1}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <CloseCircleOutlined
                                                                style={
                                                                    removeButtonStyles
                                                                }
                                                                onClick={() =>
                                                                    onRemoveDate(
                                                                        index,
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                    ) : null} */}
                                                </Row>
                                            </Col>
                                        ))}
                                    <Col xs={24} md={12}>
                                        <div>
                                            <ClinicButton
                                                height={45}
                                                title={`${t('createBooking')}`}
                                                loading={isLoading}
                                                fontSize={18}
                                                disabled={
                                                    errorText !== '' ||
                                                    errorTextPrice !== '' ||
                                                    dealData.name === '' ||
                                                    dealData.price === '' ||
                                                    dealData.about === '' ||
                                                    dealData.last_min_booking_count <
                                                        1 ||
                                                    // !hasDate ||
                                                    Object.keys(DateObjects)
                                                        .length !==
                                                        dealData.last_min_booking_count
                                                }
                                                onClick={optimizedFunction}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <div className="">
                                            <ClinicButton
                                                height={45}
                                                title={`${t('previewLastMin')}`}
                                                loading={false}
                                                onClick={() =>
                                                    setIsPreviewModalOpen(
                                                        !isPreviewModalOpen,
                                                    )
                                                }
                                                fontSize={18}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={isPreviewModalOpen}
                    footer={false}
                    className="dealModal"
                    onCancel={() => setIsPreviewModalOpen(!isPreviewModalOpen)}
                    maskClosable={false}
                    style={{
                        top: 5,
                    }}
                >
                    <h1 className="modalDealHeader">{t('perview')}</h1>
                    <div className="contentContainer">
                        <div className="modelContentWrapper">
                            <div
                                className={
                                    i18n.language === 'eng'
                                        ? 'modelContent'
                                        : 'modelContentSwedish'
                                }
                            >
                                <div className="modelBar">
                                    <div className="modelClinicInformation modelClinicInformationBg">
                                        <div className="modelClinicAvatar">
                                            <img
                                                src={
                                                    userData?.profile ??
                                                    Images.userProfile
                                                }
                                                height={53}
                                                width={53}
                                                alt="user-profile"
                                            />
                                        </div>
                                        <div className="modelClinicDetail">
                                            <p className="modelClinicDetailName">
                                                {dealData?.name ||
                                                    t('previewName')}
                                            </p>
                                            <p className="modelClinicDetailPrice">
                                                {dealData?.price} SEK
                                            </p>
                                            <p className="modelClinicDetailStarContainer">
                                                <Rate
                                                    className="modelClinicDetailPreviewStar"
                                                    disabled
                                                    value={
                                                        dashboardData?.clinic
                                                            ?.rating
                                                    }
                                                />
                                            </p>

                                            {/* <p className="modelClinicDetailLocation">
                                            {userData?.company_address}
                                        </p> */}
                                        </div>
                                    </div>
                                    <div className="modelClinicInformation">
                                        <main>
                                            <h3 className="modelClinicInformationHeading">
                                                {t('previewAbout')}
                                            </h3>
                                            <p className="modelClinicInformationParagraphPreview">
                                                {dealData?.about !== ''
                                                    ? addLineBreaks(
                                                          dealData?.about,
                                                      )
                                                    : ''}
                                            </p>
                                        </main>
                                    </div>
                                    <div className="modelClinicInformation">
                                        <main>
                                            <h3 className="modelClinicInformationHeading">
                                                {t('previewTiming')}
                                            </h3>
                                            <div className="modelClinicDealTiming">
                                                {dealData.clinic_call ? (
                                                    t('dealCallClinic')
                                                ) : (
                                                    <>
                                                        <img
                                                            src={clock}
                                                            height={10}
                                                        />{' '}
                                                        {moment().format(
                                                            'YYYY-MM-DD HH:mm',
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </main>
                                    </div>
                                    <div className="modelClinicInformation">
                                        <main>
                                            <h3 className="modelClinicInformationHeading">
                                                {t('previewAddress')}
                                            </h3>
                                            <div className="modelClinicDealTiming">
                                                <img
                                                    src={location}
                                                    height={10}
                                                />{' '}
                                                {userData?.company_address}
                                            </div>
                                        </main>
                                    </div>
                                    <div className="modelClinicInformation">
                                        <main>
                                            <h3 className="modelClinicInformationHeading">
                                                {t('previewLocation')}{' '}
                                            </h3>
                                            <div className="modelClinicDealTiming">
                                                <iframe
                                                    width="200"
                                                    height="100"
                                                    style={{
                                                        border: 0,
                                                        borderRadius: '10px',
                                                    }}
                                                    loading="lazy"
                                                    referrerPolicy="no-referrer-when-downgrade"
                                                    src={
                                                        googleMapUrl +
                                                        `&q=${userData?.company_address}&zoom=9&center=${userData?.clinic_latitude},${userData?.clinic_longitude}`
                                                    }
                                                ></iframe>
                                            </div>
                                        </main>
                                    </div>
                                    <div
                                        style={{
                                            margin: '0.5rem',
                                            marginTop: 0,
                                            marginBottom: 0,
                                        }}
                                    >
                                        <div className="modelClinicInformationButton">
                                            {t('previewBookAppointment')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modalButtonContainer">
                        <ClinicButton
                            height={35}
                            title={t('close')}
                            loading={false}
                            onClick={() => {
                                setIsPreviewModalOpen(!isPreviewModalOpen);
                            }}
                        />
                    </div>
                    {/* <div className="modelContent">
                        <h1 className="modalDealHeader">{t('perview')}</h1>
                        <hr className="modalDealLine" />
                        <div className="perviewModalImage">
                            <div className="perviewModalContent">
                                <div className="perviewModalImageContainerLastmin">
                                    <img
                                        src={
                                            Images.nullImage
                                        }
                                        width={40}
                                        height={40}
                                        className="previewImageStyle"
                                        alt=""
                                    />
                                    <div className="previewHeadingDiv">
                                        <h1 className="previewNameHeading">
                                            {dealData.name !== ''
                                                ? dealData.name
                                                : 'No Name'}
                                        </h1>
                                        <h4 className="previewNameSubheading">
                                            Clinic Location
                                        </h4>
                                    </div>
                                </div>
                                <div className="previewSubTextContainer">
                                    <h2 className="previewNameSubTextAbout">
                                        {t('dealAbout')}
                                    </h2>
                                    <div className="previewAboutText">
                                        {dealData.about !== ''
                                            ? addLineBreaks(dealData.about)
                                            : 'No Text'}
                                    </div>
                                    <h2 className="previewNameSubText">
                                        {t('dealPrice')}
                                    </h2>
                                    <p className="previewDealNumber">
                                        SEK
                                        {dealData.price !== ''
                                            ? `${dealData.price}`
                                            : '0'}
                                    </p>
                                    <h2 className="previewNameSubText">
                                        {t('previewAppointment')}
                                    </h2>
                                    <p className="previewTime">
                                        {dealData.start_time
                                            ? dealData.end_time
                                                ? `${dealData.start_time} ${t(
                                                      'timeTo',
                                                  )} ${dealData.end_time}`
                                                : dealData.start_time
                                            : t('noTime')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="modalButtonContainer">
                            <ClinicButton
                                height={35}
                                title={t('close')}
                                loading={false}
                                onClick={() =>
                                    setIsPreviewModalOpen(
                                        !isPreviewModalOpen,
                                    )
                                }
                            />
                        </div>
                    </div> */}
                </Modal>
            </div>
        </>
    );
};

export default LastMinModal;
