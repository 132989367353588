import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Url } from '../../../utils/apiUrls';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AutoComplete from 'react-google-autocomplete';
import logo from '../../../assets/images/forget.png';
import { ClinicInput } from '../../commonComponents';
import { ClinicButton } from '../../commonComponents';
import { postApiWithoutAuth } from '../../../utils/api';
import './SignupStyle.css';
import { Col, Form, Row, Grid, Checkbox, Modal } from 'antd';
import TermCondition from '../../mainComponents/TermsConditions/TermCondition';
import i18next from 'i18next';
import { useSignupContext } from '../../../context/SignupContext';
import { langObj } from '../../../utils/helper';

const { useBreakpoint } = Grid;

const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,16}$/;
const emailRegex =
    /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
const phoneRegex = /^\+?\d$/;
const linkRegex = /\b(https?:\/\/|www\.)[^\s]+/g;
const letterRegix = /^[a-zA-Z\u00C0-\u00FF\u0100-\u017F\s]+$/;
const numberRegix = /^[0-9\b]+$/;
const onlyAlphaRegex = /^[a-zA-Z ]*$/;

const booleanValue = false;

const SignUp = () => {
    const screen = useBreakpoint();
    const { payload, setPayload } = useSignupContext();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [location, setLocation] = useState('');
    const [userData, setUserData] = useState({
        company_name: '',
        role: 'clinic',
        password: '',
        email: '',
        phone_number: '',
        home_page_link: '',
        city: '',
        postal_code: '',
        company_address: location,
        patients_count: '',
        clinic_longitude: '',
        clinic_latitude: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [errorText2, seterrorText2] = useState('');
    const [errorText3, seterrorText3] = useState('');
    const [errorText4, seterrorText4] = useState('');
    const [errorText5, seterrorText5] = useState('');
    const [errorText6, seterrorText6] = useState('');
    const [errorText7, seterrorText7] = useState('');
    const [errorText8, seterrorText8] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isAgree, setIsAgree] = useState(false);
    const [touched, setTouched] = useState(false);

    const handleOnChangeCity = (event) => {
        const { name, value } = event.target;
        // if (value.match(onlyAlphaRegex)) {
        seterrorText8('');
        setUserData({ ...userData, [name]: value });
        // } else {
        //     seterrorText8(t('nameError'));
        // }
    };

    const handleOnChange = (event) => {
        const { name, value } = event.target;
        setUserData({ ...userData, [name]: value });
        setPayload({ ...payload, [name]: value });
        if (event.target.name === 'email') {
            if (event.target.value.match(emailRegex)) {
                setErrorText('');
            } else {
                setErrorText(t('emailError'));
            }
        } else if (event.target.name === 'phone_number') {
            if (event.target.value.match(numberRegix)) {
                seterrorText3('');
            } else {
                seterrorText3(t('phoneNumberError'));
            }
        } else if (event.target.name === 'company_name') {
            if (event.target.value.match(letterRegix)) {
                seterrorText5('');
            } else {
                seterrorText5(t('nameError'));
            }
        } else if (event.target.name === 'patients_count') {
            if (event.target.value.match(numberRegix)) {
                seterrorText6('');
            } else {
                seterrorText6(t('numberError'));
            }
        } else if (event.target.name === 'home_page_link') {
            if (event.target.value !== '') {
                if (event.target.value.match(linkRegex)) {
                    seterrorText4('');
                } else {
                    seterrorText4(t('hyperLinkError'));
                }
            } else {
                seterrorText4('');
            }
        } else if (event.target.name === 'postal_code') {
            if (event.target.value.match(numberRegix)) {
                seterrorText7('');
            } else {
                seterrorText7(t('numberError'));
            }
        } else if (event.target.name === 'city') {
            if (event.target.value.match(letterRegix)) {
                seterrorText8('');
            } else {
                seterrorText8(t('invalidformat'));
            }
        } else if (event.target.name === 'password') {
            if (event.target.value.match(passwordRegex)) {
                seterrorText2('');
            } else {
                seterrorText2(t('passwordError'));
            }
        }
    };

    const selectPlace = (event) => {
        setLocation(event.target.value);
    };

    const controllBlur = useCallback(() => {
        if (location == '') {
            setUserData({
                ...userData,
                company_address: '',
                clinic_longitude: '',
                clinic_latitude: '',
            });
        }
    }, [location]);

    const postData = async () => {
        setIsLoading(true);
        let language = localStorage.getItem('i18nextLng');
        const response = await postApiWithoutAuth(Url.SIGNUP_UR, {
            ...userData,
            language: langObj[language ?? 'sew'],
        });
        if (response.success) {
            navigate('/verifycode', {
                state: { email: userData.email, previousPath: 'Register' },
            });
            setIsLoading(false);
            enqueueSnackbar(t('verSign'), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'success',
            });
        } else {
            enqueueSnackbar(
                !response.message?.data?.error
                    ? 'Bad Request'
                    : response.message?.data?.error,
                {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top',
                    },
                    variant: 'error',
                },
            );
            setIsLoading(false);
        }
    };

    useEffect(() => {
        controllBlur();
    }, [controllBlur]);

    useEffect(() => {
        let lang = localStorage.getItem('lng');
        if (!lang) return;
        i18n.changeLanguage(lang);
    }, []);

    return (
        <div className="signupScreenBackground">
            <Row
                align="top"
                justify="space-around"
                // gutter={[0, screen.xs ? 0 : 32]}
            >
                <Col xs={24} md={8} lg={12}>
                    <div
                        className="signupImageContainer"
                        style={{ marginBottom: screen.lg ? '1rem' : '2rem' }}
                    >
                        <div className="logoSignup">
                            <div className="logoContainer">
                                <img src={logo} alt="Gnizzel Logo" />
                            </div>
                            <h1 className="signupHeading">Gnizzel</h1>
                        </div>
                    </div>
                </Col>
                <Col
                    xs={24}
                    lg={12}
                    md={16}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: screen.md ? '100vh' : 'unset',
                    }}
                >
                    <div className="secondContainr">
                        <Form
                            layout="vertical"
                            onFinish={postData}
                            autoComplete="off"
                        >
                            <Row
                                gutter={[16, 16]}
                                justify={'center'}
                                className="signupCard"
                            >
                                {/* className="signupCard" */}
                                <Col span={24}>
                                    <h1 className="headingSignup">{`${t(
                                        'SignUppp',
                                    )}`}</h1>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <ClinicInput
                                        type={'text'}
                                        name={'company_name'}
                                        Value={userData.company_name}
                                        onChange={handleOnChange}
                                        height={40}
                                        width={100}
                                        maxLength={25}
                                        placeholder={`${t('clicName')}`}
                                    />
                                    <div className="errorStyleCompany">
                                        {' '}
                                        {errorText5}
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <ClinicInput
                                        type={'text'}
                                        name={'patients_count'}
                                        Value={userData.patients_count}
                                        onChange={handleOnChange}
                                        height={40}
                                        width={100}
                                        placeholder={`${t('noOfPatient')}`}
                                    />
                                    <div className="errorStyleCompany">
                                        {' '}
                                        {errorText6}
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <div className="signupAddressInput">
                                        <AutoComplete
                                            className="adressAutoComplete adressAutoComplete-width clinicsetting-address"
                                            apiKey={
                                                process.env.REACT_APP_GOOGLE_API
                                            }
                                            onPlaceSelected={(e) => {
                                                setUserData((prev) => {
                                                    return {
                                                        ...prev,
                                                        clinic_longitude:
                                                            parseFloat(
                                                                e.geometry.location.lng(),
                                                            ).toFixed(8),
                                                        clinic_latitude:
                                                            parseFloat(
                                                                e.geometry.location.lat(),
                                                            ).toFixed(8),
                                                        company_address:
                                                            e?.formatted_address,
                                                    };
                                                });
                                            }}
                                            defaultValue={location}
                                            onChange={(e) => {
                                                selectPlace(e);
                                            }}
                                            onBlurCapture={() => {
                                                setTouched(true);
                                            }}
                                            onClick={() => {
                                                setTouched(true);
                                            }}
                                            style={{
                                                height: '37px',
                                            }}
                                            options={{
                                                types: [
                                                    'geocode',
                                                    'establishment',
                                                ],
                                            }}
                                            placeholder={`${t('yourAddress')}`}
                                        />
                                    </div>

                                    <div className="errorStyleCompany">
                                        {touched &&
                                            !userData.clinic_latitude &&
                                            !userData.clinic_longitude &&
                                            t('autoCompleteError')}{' '}
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <ClinicInput
                                        type={'text'}
                                        name={'postal_code'}
                                        Value={userData.postal_code}
                                        onChange={handleOnChange}
                                        height={40}
                                        width={100}
                                        placeholder={`${t('postalCode')}`}
                                    />
                                    <div className="errorStyleCompany">
                                        {' '}
                                        {errorText7}
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                    <ClinicInput
                                        type={'text'}
                                        name={'city'}
                                        Value={userData.city}
                                        onChange={handleOnChangeCity}
                                        height={40}
                                        width={100}
                                        placeholder={`${t('city')}`}
                                    />
                                    <div className="errorStyleCompany">
                                        {' '}
                                        {errorText8}
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <ClinicInput
                                        type={'text'}
                                        placeholder={`${t('phoneNumber')}`}
                                        name={'phone_number'}
                                        Value={userData.phone_number}
                                        onChange={handleOnChange}
                                        height={40}
                                    />
                                    <div className="errorStyleCompany">
                                        {' '}
                                        {errorText3}
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <ClinicInput
                                        type={'text'}
                                        name={'email'}
                                        placeholder={`${t('Email')}`}
                                        onChange={handleOnChange}
                                        height={40}
                                    />

                                    <div className="errorStyleCompany">
                                        {errorText}
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <ClinicInput
                                        placeholder={`${t('Password')}`}
                                        type={'password'}
                                        name={'password'}
                                        isRequired={
                                            userData.password === ''
                                                ? !booleanValue
                                                : booleanValue
                                        }
                                        Value={userData.password}
                                        isRequiredType={'password'}
                                        isRequiredTypeMessgae={'password'}
                                        onChange={handleOnChange}
                                        height={40}
                                        width={100}
                                    />
                                    <div className="errorStyleCompany">
                                        {errorText2}
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className="signupCheckbox">
                                        <Checkbox
                                            onChange={(event) => {
                                                setIsAgree(
                                                    event.target.checked,
                                                );
                                            }}
                                            className="modalDealCheck themeCheckboxColor"
                                            checked={isAgree}
                                            name="clinic_call"
                                        />
                                        <div className="signupCheckboxText">
                                            {t('iAgree')}{' '}
                                            <Link
                                                onClick={() => setIsOpen(true)}
                                                className="loginAnchorTag"
                                            >
                                                {` ${t('termsAndCondition')}`}
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <ClinicButton
                                        onClick={postData}
                                        title={`${t('si')}`}
                                        loading={isLoading}
                                        height={40}
                                        disabled={
                                            userData.company_name === '' ||
                                            userData.patients_count === '' ||
                                            userData.postal_code === '' ||
                                            userData.city === '' ||
                                            userData.phone_number === '' ||
                                            userData.email === '' ||
                                            userData.password === '' ||
                                            userData.company_address === '' ||
                                            location === '' ||
                                            errorText !== '' ||
                                            errorText2 !== '' ||
                                            errorText3 !== '' ||
                                            errorText4 !== '' ||
                                            errorText5 !== '' ||
                                            errorText6 !== '' ||
                                            errorText7 !== '' ||
                                            errorText8 !== '' ||
                                            !isAgree
                                        }
                                        buttonType={'formButton'}
                                    />
                                </Col>
                                <Col
                                    span={24}
                                    className="alreadyAccountContainer"
                                >
                                    <p className="alreadyAcoount">
                                        {`${t('alreadyAccount')}
                                    `}
                                        <Link to="/" className="loginAnchorTag">
                                            {`${t('signIn')}`}
                                        </Link>
                                    </p>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
            <Modal
                open={isOpen}
                closable
                centered
                footer={
                    <Row gutter={[8, 8]} justify={'center'}>
                        <Col span={24}>
                            <ClinicButton
                                title={t('acceptContinue')}
                                onClick={() => {
                                    setIsAgree(true);
                                    setIsOpen(false);
                                }}
                            />
                        </Col>
                        <Col span={24}>
                            <ClinicButton
                                backgroundColor={'white'}
                                title={t('deny')}
                                onClick={() => {
                                    setIsAgree(false);
                                    setIsOpen(false);
                                }}
                            />
                        </Col>
                    </Row>
                }
                width={800}
                style={{
                    marginTop: '1em',
                    marginBottom: '1em',
                }}
                onCancel={() => setIsOpen(false)}
            >
                <TermCondition />
            </Modal>
        </div>
    );
};
export default SignUp;
