import React from 'react';
import TermCondition from '../TermsConditions/TermCondition';

const PrivacyPolicy = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '90%' }}>
                <TermCondition />
            </div>
        </div>
    );
};

export default PrivacyPolicy;
