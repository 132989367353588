import React, { useState, useEffect } from "react";
import { Col, Form, Row, Grid } from 'antd';
import { useSnackbar } from 'notistack';
import { ClinicInput } from '../../commonComponents';
import Input from 'antd/es/input/Input';
import { ClinicButton } from '../../commonComponents';
import Navbar from '../../commonComponents/Navbar';
import { postApiWithoutAuth } from '../../../utils/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Url } from '../../../utils/apiUrls';
import { useTranslation } from 'react-i18next';

import './ContactUs.css';
import { getToken } from '../../../utils/localStorage';
import { useLayoutEffect } from 'react';
import { langObj } from '../../../utils/helper';

const emailRegex =
    /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

const { useBreakpoint } = Grid;
const { TextArea } = Input;

const ContactUS = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const view = searchParams.get('view');
    const lang = searchParams.get('language');
    const screen = useBreakpoint();
    const [userData, setUserData] = useState({
        clinic_name: '',
        email: '',
        description: '',
    });
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();

    const handleOnChange = (event) => {
        const { name, value } = event.target;
        setUserData({ ...userData, [name]: value });
        if (name === 'email') {
            if (!emailRegex.test(value)) {
                setErrorText(true);
            } else {
                setErrorText(false);
            }
        }
    };
    const navigation = () => {
        navigate('/');
    };
    const postData = async () => {
        setIsLoading(true);
        let language = localStorage.getItem('i18nextLng');
        let description = userData.description.includes('\n')
            ? userData.description.replaceAll('\n', '<br>')
            : userData.description;

        const response = await postApiWithoutAuth(Url.CONTACT, {
            ...userData,
            description,
            language: langObj[language ?? 'sew'],
            isCustomer: view === 'mobile',
        });

        if (response.success) {
            setIsLoading(false);
            enqueueSnackbar(t('successContactUs'), {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'success',
            });
            setUserData({ clinic_name: '', email: '', description: '' });
        } else {
            setIsLoading(false);
            enqueueSnackbar('Error in submitting response', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                variant: 'error',
            });
        }
    };
    useEffect(() => {
        form.setFieldsValue(userData);
    }, [form, userData]);

    useEffect(() => {
        const changeLanguageWithDelay = async () => {
            if (lang) {
                await new Promise((resolve) => {
                    const timeoutId = setTimeout(() => {
                        i18n.changeLanguage(
                            lang.slice(0, 3) === 'eng' ? 'eng' : 'sew',
                        );
                        resolve();
                    }, 1000);

                    return () => clearTimeout(timeoutId);
                });
            }
        };

        changeLanguageWithDelay();
    }, [lang]);

    return (
        <>
            <Navbar />
            <div
                className={
                    !!getToken() &&
                    (screen.lg
                        ? 'mainContainerContact-lg-view'
                        : 'mainContainerContact')
                }
            >
                <div className="contactStyle">
                    <Row
                        justify="center"
                        className={
                            screen.md
                                ? 'subMainContainer-lg-view'
                                : 'subMainContainer'
                        }
                    >
                        <Col span={24}>
                            <div className="headingsUs">
                                <h1 className="contactUsHeading">
                                    {t('contactUs')}
                                </h1>
                                <h3 className="contactPara">
                                    {' '}
                                    {t('feelFree')}
                                </h3>
                            </div>
                            <div className="hrContainer">
                                <hr className="hrContactUs"></hr>
                            </div>
                        </Col>
                        <Col xs={24}>
                            <Form
                                layout="vertical"
                                onFinish={postData}
                                form={form}
                                autoComplete="off"
                                initialValues={userData}
                            >
                                <Row gutter={[32, 32]}>
                                    {view === 'mobile' ? (
                                        <Col xs={24} md={12}>
                                            <span className="clinicContactTitle">
                                                {t('fullName')}
                                            </span>
                                            <ClinicInput
                                                type={'text'}
                                                name={'clinic_name'}
                                                placeholder={t('yourName')}
                                                onChange={handleOnChange}
                                                height={40}
                                                Value={userData.clinic_name}
                                            />
                                        </Col>
                                    ) : (
                                        <Col xs={24} md={12}>
                                            <span className="clinicContactTitle">
                                                {t('clinicName')}
                                            </span>
                                            {/* className="clinicContact" */}
                                            <ClinicInput
                                                type={'text'}
                                                name={'clinic_name'}
                                                placeholder={t('enterClinic')}
                                                onChange={handleOnChange}
                                                height={40}
                                                Value={userData.clinic_name}
                                            />
                                        </Col>
                                    )}
                                    <Col xs={24} md={12}>
                                        <span className="clinicContactTitle">
                                            {t('Email')}
                                        </span>
                                        <div>
                                            <ClinicInput
                                                type={'text'}
                                                name={'email'}
                                                placeholder={t('enterEmail')}
                                                height={40}
                                                Value={userData.email}
                                                onChange={handleOnChange}
                                            />
                                        </div>
                                        <span className="emailErrorMessage">
                                            {errorText && t('emailError')}
                                        </span>
                                    </Col>
                                    <Col
                                        xs={24}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <span className="clinicContactTitle">
                                            {t('des')}
                                        </span>
                                        <TextArea
                                            className="textArea"
                                            name={'description'}
                                            value={userData.description}
                                            placeholder={t('enterText')}
                                            maxLength={1500}
                                            onChange={handleOnChange}
                                        />
                                    </Col>
                                    <Col
                                        xs={24}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div className="contactButton">
                                            <ClinicButton
                                                height={50}
                                                title={t('submit')}
                                                onClick={postData}
                                                disabled={
                                                    userData.clinic_name ===
                                                        '' ||
                                                    errorText ||
                                                    userData.description === ''
                                                }
                                                loading={isLoading}
                                            />
                                        </div>
                                    </Col>
                                    {view !== 'mobile' && (
                                        <Col
                                            xs={24}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {!getToken() && (
                                                <div
                                                    className="footerArrowContact"
                                                    onClick={navigation}
                                                >
                                                    <h1 className="footerArrowTextContact">
                                                        {t('backLogin')}
                                                    </h1>
                                                </div>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
export default ContactUS;
